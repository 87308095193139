import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import {
  getItemFromAPI,
  listOptionsFromAPI,
  simplelistItemsFromAPI,
  devices,
  locations,
  buildings,
  departments,
  rooms,
  deviceTypes,
} from "../../api/index";
import DeviceForm from "../../forms/deviceForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { deviceId } = useParams();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [locationId, setlocationId] = useState("");
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isNewDevice = parseInt(deviceId) === 0;

  const [currentFormState, setCurrentFormState] = useState({
    currentValues: null,
    isSaving: false,
    isTemplate: false,
    isAddNew: parseInt(window.location.pathname.slice(-1)) === 0,
    isSaveAndAddNew: false,
    selectedItemChanged: false,
    errorExists: false,
    showUnsavedChangesModal: false,
    selectedInstallDate:
      currentDevice && !isNaN(Date.parse(currentDevice?.installDate))
        ? new Date(currentDevice.installDate)
        : new Date(),
  });

  async function loadDeviceTypeOptionsFromAPI(controller) {
    try {
      const response = await listOptionsFromAPI(deviceTypes, controller);
      setDeviceTypeOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadLocationOptionsFromAPI(controller) {
    try {
      const response = await simplelistItemsFromAPI(locations, controller, null);
      setLocationOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadBuildingOptionsFromAPI(selectedLocationId, controller) {
    try {
      const response = await simplelistItemsFromAPI(buildings, controller, selectedLocationId);
      setBuildingOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadDepartmentOptionsFromAPI(selectedBuildingId, controller) {
    try {
      const response = await simplelistItemsFromAPI(departments, controller, selectedBuildingId);
      setDepartmentOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadRoomOptionsFromAPI(selectedDepartmentId, controller) {
    try {
      const response = await simplelistItemsFromAPI(rooms, controller, selectedDepartmentId);
      setRoomOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadDeviceFromAPI(currentDeviceId, controller) {
    try {
      const response = await getItemFromAPI(devices, currentDeviceId, controller);
      setCurrentDevice(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (locationOptions.length === 0) loadLocationOptionsFromAPI(new AbortController());
    if (deviceTypeOptions.length === 0) loadDeviceTypeOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    if (currentDevice) {
      loadBuildingOptionsFromAPI(currentDevice.locationId, new AbortController());
      loadDepartmentOptionsFromAPI(currentDevice.buildingId, new AbortController());
      loadRoomOptionsFromAPI(currentDevice.departmentId, new AbortController());
      loadDeviceTypeOptionsFromAPI(new AbortController());
    }
  }, [currentDevice]);

  useEffect(() => {
    if (locationId) loadBuildingOptionsFromAPI(locationId, new AbortController());
  }, [locationId]);

  useEffect(() => {
    if (buildingId) loadDepartmentOptionsFromAPI(buildingId, new AbortController());
  }, [buildingId]);

  useEffect(() => {
    if (departmentId) loadRoomOptionsFromAPI(departmentId, new AbortController());
  }, [departmentId]);

  useEffect(() => {
    setIsLoading(!isNewDevice);
    isNewDevice ? setCurrentDevice(null) : loadDeviceFromAPI(deviceId, new AbortController());
  }, [deviceId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <DeviceForm
              currentDevice={currentDevice}
              deviceTypeOptions={deviceTypeOptions}
              locationOptions={locationOptions}
              buildingOptions={buildingOptions}
              departmentOptions={departmentOptions}
              roomOptions={roomOptions}
              onLocationChange={(selectedLocationId) => {
                setlocationId(selectedLocationId);
              }}
              onBuildingChange={(selectedBuildingId) => {
                setBuildingId(selectedBuildingId);
              }}
              onDepartmentChange={(selectedDepartmentId) => {
                setDepartmentId(selectedDepartmentId);
              }}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentDevice(formValues);
              }}
              onFormSubmit={(selectedLocationId, selectedBuildingId, selectedDepartmentId, reloadLists) => {
                if (reloadLists) {
                  const controller = new AbortController();
                  loadBuildingOptionsFromAPI(selectedLocationId, controller);
                  loadDepartmentOptionsFromAPI(selectedBuildingId, controller);
                  loadRoomOptionsFromAPI(selectedDepartmentId, controller);
                }
              }}
              currentFormState={currentFormState}
              setCurrentFormState={(newFormState) => {
                setCurrentFormState(newFormState);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
