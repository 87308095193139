import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import Field from "../formikUI/field/Field";
import { postItemToAPI, patchItemToAPI, departments } from "../api/index";
import { routes } from "../nav/nav";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function DepartmentForm({
  currentDepartment,
  locationOptions,
  buildingOptions,
  onLocationChange,
  errorHandler,
  onFormPost,
  onFormSubmit,
}) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, DEPARTMENTS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = departments;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;

  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    isSaving: false,
    selectedItemChanged: false,
    errorExists: false,
    showUnsavedChangesModal: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedLocation, setAssignedLocation] = useState({
    locationId: currentDepartment?.locationId || "",
    locationName: currentDepartment ? currentDepartment?.locationObj?.name || "" : "",
  });

  const [assignedBuilding, setAssignedBuilding] = useState({
    buildingId: currentDepartment ? currentDepartment.buildingId || "" : "",
    buildingName: currentDepartment ? currentDepartment?.buildingObj?.name || "" : "",
  });

  const onDepartmentFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;
    try {
      // update this to have the room values from the db
      const updatedDepartment = {
        ...currentDepartment,
        ...values,
      };
      delete updatedDepartment.siteId;
      if (currentDepartment) response = await patchItemToAPI(departments, updatedDepartment, controller);
      else response = await postItemToAPI(departments, updatedDepartment, controller);
      if (!currentDepartment) {
        setNewRecordId(response.id);
        updatedDepartment._id = response.id;
      }
      if (navigateToList) navigate(`${properties[DEPARTMENTS].path}`);
      else {
        onFormPost(updatedDepartment);
        onFormSubmit(values.locationId, formState.selectedItemChanged);
        newFormState.selectedItemChanged = false;
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentDepartment : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newSnackbarAlerState.alertMessage = getItemErrorMessage(
        "Room",
        currentDepartment?._id ? currentDepartment : null
      );
      newSnackbarAlerState.alertSeverity = "error";
      newFormState.errorExists = true;
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  const locationNames = [];
  if (locationOptions.length > 0) locationOptions?.map((location) => locationNames.push(location.name));

  const buildingNames = [];
  if (buildingOptions.length > 0) buildingOptions?.map((building) => buildingNames.push(building.name));

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [fieldValues.name, assignedLocation.locationName, assignedBuilding.buildingName];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
      locationId:
        !fieldValues.locationId && currentDepartment?.locationId
          ? currentDepartment.locationId
          : assignedLocation.locationId,
      locationName: assignedLocation.locationName,
      buildingId:
        !fieldValues.buildingId && currentDepartment?.buildingId
          ? currentDepartment.buildingId
          : assignedBuilding.buildingId,
      buildingName: assignedBuilding.buildingName,
    };
    if (currentDepartment) updatedFieldValues._id = currentDepartment._id;
    return updatedFieldValues;
  }

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Department", currentDepartment?._id ? currentDepartment : null)
            : getItemSucccessMessage("Department", newRecordId.length === 0 ? currentDepartment : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            open: false,
            alertSeverity: "success",
            alertMessage: "",
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentDepartment?.name || "",
          floorNumber: currentDepartment?.floorNumber || "",
          description: currentDepartment?.description || "",
          inactive: currentDepartment?.inactive || false,
          comment: currentDepartment?.comment || "",
          locationId: currentDepartment?.locationId || assignedLocation.locationId,
          locationName: currentDepartment?.locationObj?.name || "",
          collectorName: currentDepartment?.collectorObj?.name || "",
          buildingId: currentDepartment?.buildingId || assignedBuilding.buildingId,
          buildingName: currentDepartment?.buildingObj?.name || "",
          messageCode: currentDepartment?.messageCode || "",
        }}
        onSubmit={(values, helpers) => {
          onDepartmentFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[DEPARTMENTS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onDepartmentFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar
                titleText={`${!currentDepartment ? "add new" : "view/edit"} department`}
                editMode={currentDepartment !== null}
              />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentDepartment ? "Enter " : ""}Department Name`}
                          placeholder="Department Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="floorNumber"
                          id="floorNumber"
                          label={`${!currentDepartment ? "Enter Dept" : "Department"} Floor Number`}
                          placeholder="Dept Floor Number"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("floorNumber") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="description"
                          id="description"
                          label={`${!currentDepartment ? "Enter Dept" : "Department"} Description`}
                          placeholder="Department Description"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("description") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          name="comment"
                          id="comment"
                          label={`${!currentDepartment ? "Enter" : ""} Department Comments`}
                          placeholder="Department Comments"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="locationName"
                          id="locationName"
                          label={`${!currentDepartment ? "Select a " : ""}Location`}
                          placeholder="Location Names"
                          options={locationNames}
                          value={
                            locationNames.length > 0 && locationNames.find((l) => l === assignedLocation.locationName)
                              ? assignedLocation.locationName
                              : ""
                          }
                          onChange={(e) => {
                            // Redefining the onChange() event negates Formik setting the 'dirty' flag
                            const selectedLocation = locationOptions.find((l) => l.name === e.target?.value);
                            setAssignedLocation({
                              locationId: selectedLocation._id,
                              locationName: selectedLocation.name,
                            });
                            setAssignedBuilding({ buildingId: "", buildingName: "" });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            setFormState(newFormState);
                            onLocationChange(selectedLocation._id);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("locationName") && <RequiredFieldIndicator />}
                      </div>

                      {(currentDepartment || assignedLocation.locationId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="buildingName"
                            id="buildingName"
                            label={`${!currentDepartment ? "Select a" : ""} Building`}
                            placeholder="Building Names"
                            options={buildingNames}
                            value={
                              buildingNames.length > 0 && buildingNames.find((b) => b === assignedBuilding.buildingName)
                                ? assignedBuilding.buildingName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedBuilding = buildingOptions.find((b) => b.name === e.target?.value);
                              setAssignedBuilding({
                                buildingId: selectedBuilding._id,
                                buildingName: selectedBuilding.name,
                              });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("buildingName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {currentDepartment && currentDepartment.collectorObj && (
                        <div className="formik-field-readonly">
                          <Field
                            asComponent="text"
                            name="collectorName"
                            id="collectorName"
                            label="Assigned Collector"
                            placeholder="Collector "
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                      )}

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="messageCode"
                          id="messageCode"
                          onInput={(e) => {
                            if (e.target.value.length > 0) e.target.value = e.target.value.slice(0, 15);
                            e.target.value = e.target.value.toUpperCase();
                          }}
                          placeholder="Device Message (Line 1)"
                          label={`${!currentDepartment ? "Enter " : ""}Device Message (Line 1)`}
                          helperText={`${values.messageCode.length}/15`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("messageCode") && <RequiredFieldIndicator />}
                      </div>

                      {currentDepartment && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  const newFormState = { ...formState };
                                  newFormState.errorExists = false;
                                  setFormState(newFormState);
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentDepartment.inactive ? "Yes" : "No"}
                              </div>
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentDepartment && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentDepartment.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentDepartment.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentDepartment}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.selectedItemChanged) ||
                  !userCanUpdate
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[DEPARTMENTS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

DepartmentForm.propTypes = {
  currentDepartment: PropTypes.object,
  locationOptions: PropTypes.array,
  buildingOptions: PropTypes.array,
  onLocationChange: PropTypes.func,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

export default DepartmentForm;
