import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileSignalOut } from "@fortawesome/pro-solid-svg-icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";

function ValidateMobileNumberDialog({
  dialogState,
  mobileNumberValidation,
  setValidateMobileNumberDialogState,
  onConfirmSendCode,
  onConfirmValidateCode,
}) {
  const [validationCode, setValidationCode] = useState(null);

  let continueAction = "";

  const now = new Date();
  const expirationDate = mobileNumberValidation.expires ? new Date(mobileNumberValidation.expires) : null;
  let dialogContentText =
    expirationDate && expirationDate < now ? "The previously requested validation code has expired. \n" : "";

  if (mobileNumberValidation.mobileNumberValid && !mobileNumberValidation.validated) {
    if (!expirationDate || expirationDate < now) {
      continueAction = "request";
      dialogContentText = `${dialogContentText}A${
        expirationDate && expirationDate < now ? " new" : "n"
      } SMS message will be sent to the mobile number for this User account. Please reply with the 6-digit validation code provided.`;
    } else if (expirationDate && expirationDate > now && mobileNumberValidation.code) {
      continueAction = "validate";
      dialogContentText = `${dialogContentText} An SMS message was sent to the mobile number for this User account. Please enter the 6-digit validation code provided.`;
    }
  }

  return (
    <Dialog
      open={dialogState.open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FontAwesomeIcon icon={faMobileSignalOut} style={{ marginRight: "8px", opacity: "0.8", color: "#ff5e14" }} />
        Validate Mobile Number for {dialogState.userName}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
        {dialogState.isSaving && <Spinner />}
        {mobileNumberValidation.mobileNumberValid &&
          !mobileNumberValidation.validated &&
          expirationDate &&
          expirationDate > now &&
          mobileNumberValidation.code && (
            <input
              style={{ marginTop: "12px" }}
              onChange={(e) => {
                setValidationCode(e.target.value);
              }}
            />
          )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setValidateMobileNumberDialogState({ open: false, userName: "" });
          }}
          disabled={dialogState.isSaving}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            continueAction === "request" ? onConfirmSendCode(true) : onConfirmValidateCode(true, validationCode);
            if (continueAction === "validate") setValidateMobileNumberDialogState({ open: false, userName: "" });
          }}
          disabled={dialogState.isSaving}
          autoFocus
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ValidateMobileNumberDialog.propTypes = {
  dialogState: PropTypes.object,
  mobileNumberValidation: PropTypes.object,
  setValidateMobileNumberDialogState: PropTypes.func,
  onConfirmSendCode: PropTypes.func,
  onConfirmValidateCode: PropTypes.func,
};

export default ValidateMobileNumberDialog;
