import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faMagnifyingGlassChart,
  faMobileSignalOut,
  faCheckSquare,
  faPause,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, users, locations } from "../api/index";
import { routes } from "../nav/nav";
import { forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";
import Modal from "../components/atoms/modal/Modal";
import UserNotificationsForm from "./UserNotificationsForm";
import AvailableLocationsForm from "./AvailableLocationsForm";
import ValidateMobileNumberDialog from "../materialUI/dialog/validateMobileNumberDialog";

function UserForm({ currentUser, roleOptions, errorHandler, onFormPost }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { properties, paths, USERS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate =
    userObj.userRole?.superAdmin || userObj.userRole?.admin || currentUser?._id === userObj?.user._id;
  const { requiredFieldNames } = users;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;
  const [newRecordId, setNewRecordId] = useState("");
  const [validateMobileNumberDialogState, setValidateMobileNumberDialogState] = useState({ open: false, user: null });

  const [formState, setFormState] = useState({
    isSaving: false,
    selectedItemChanged: false,
    errorExists: false,
    validationError: false,
    showUnsavedChangesModal: false,
    showUserNoticicationsModal: false,
    showAvailableLocationsModal: false,
  });

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedRole, setAssignedRole] = useState({
    roleId: currentUser?.role || currentUser?.roleObj?._id || "",
    roleName: currentUser ? currentUser?.roleObj?.name || "" : "",
  });

  const [userLocationsList, setUserLocationsList] = useState([]);
  const [allLocationsList, setAllLocationsList] = useState([]);

  const warningFontStyle = {
    fontSize: "0.75em",
    color: "red",
  };

  async function validateMobileNumber(request, controller) {
    const newFormState = { ...formState };
    const newSnackbarState = { open: true };
    try {
      const response = await users.validateMobileNumber(request, controller.signal);
      if (response) {
        formState.validationError = false;
        onFormPost(response);
        newSnackbarState.alertMessage = "Mobile number validation code sent successfully.";
      }
    } catch (error) {
      newFormState.validationError = true;
      newSnackbarState.alertMessage =
        request.type === "request"
          ? "Error sending mobile number validation code."
          : error.errorMesssage || "Error validating code.";
      errorHandler(error);
    } finally {
      setFormState(newFormState);
      setIsSaving(false);
      setSnackbarAlertState(newSnackbarState);
    }
  }

  function emailAddressValid(email) {
    if (email?.trim().length === 0) return true;
    return email?.trim().match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  }

  function phoneNumberValid(phoneNum) {
    if (!phoneNum) return true;
    if (phoneNum?.trim().length === 0) return true;
    return phoneNum?.trim().match(/^\+[1-9]\d{10,14}$/);
  }

  function contactNumbersValid(phoneNum, mobileNum) {
    return phoneNumberValid(phoneNum) && phoneNumberValid(mobileNum);
  }

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  function setShowUserNoticicationsModal(open) {
    const newFormState = { ...formState };
    newFormState.showUserNoticicationsModal = open;
    setFormState(newFormState);
  }

  function setShowAvailableLocationsModal(open) {
    const newFormState = { ...formState };
    newFormState.showAvailableLocationsModal = open;
    setFormState(newFormState);
  }

  const roleNames = [];
  if (roleOptions?.length > 0) roleOptions.map((role) => roleNames.push(role.name));

  const handlePasswordReset = async () => {
    console.log("Password reset requested");
  };

  const onUserFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;
    const updatedUser = {
      ...currentUser,
      ...values,
      role: assignedRole?.roleId,
      roleId: assignedRole?.roleId,
      roleName: assignedRole?.roleName,
    };
    try {
      delete updatedUser.roleObj;
      if (currentUser) response = await patchItemToAPI(users, updatedUser, controller);
      else response = await postItemToAPI(users, updatedUser, controller);
      if (!currentUser) {
        setNewRecordId(response.id);
        updatedUser._id = response.id;
      }
      if (navigateToList) navigate(`${properties[USERS].path}`);
      else {
        onFormPost(response);
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "User",
          newRecordId.length === 0 ? currentUser : null
        );
        newFormState.selectedItemChanged = false;
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("User", currentUser?._id ? currentUser : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [fieldValues.name, fieldValues.displayName, fieldValues.email, assignedRole.roleName];
  }

  const getValidateButtonIcon = () => {
    if (
      !(
        currentUser?.mobileNumber?.length > 0 &&
        phoneNumberValid(currentUser?.mobileNumber) &&
        currentUser?.mobileNumberValidated
      )
    )
      return faMobileSignalOut;
    if (
      currentUser?.mobileNumber?.length > 0 &&
      phoneNumberValid(currentUser?.mobileNumber) &&
      currentUser?.mobileNumberValidated &&
      !currentUser?.smsRejected
    )
      return faCheck;
    if (
      currentUser?.mobileNumber?.length > 0 &&
      phoneNumberValid(currentUser?.mobileNumber) &&
      currentUser?.mobileNumberValidated &&
      currentUser?.smsRejected
    )
      return faPause;
    return null;
  };

  const getValidateButtonStyle = () => {
    let iconColor = "";
    if (currentUser?.smsRejected) iconColor = "red";
    else if (
      currentUser?.mobileNumber?.length > 0 &&
      phoneNumberValid(currentUser?.mobileNumber) &&
      currentUser?.mobileNumberValidated
    )
      iconColor = "greed";
    return {
      opacity: "0.9",
      color: iconColor,
    };
  };

  async function loadUserCurrentLocationsFromAPI(userId, controller) {
    try {
      const response = await users.get(userId, controller.signal);
      setUserLocationsList(response.allowedLocations);
      // setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadAllLocationsFromAPI(controller) {
    try {
      const response = await locations.simplelist(controller.signal);
      setAllLocationsList(response);
      // setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    if (currentUser && allLocationsList.length === 0) {
      loadUserCurrentLocationsFromAPI(currentUser._id, new AbortController());
      loadAllLocationsFromAPI(new AbortController());
    }
  }, [allLocationsList.length, currentUser]);

  return (
    <>
      <ValidateMobileNumberDialog
        dialogState={{
          open: validateMobileNumberDialogState.open,
          userName: currentUser?.name,
          isSaving: formState.isSaving,
        }}
        setValidateMobileNumberDialogState={(state) => setValidateMobileNumberDialogState(state)}
        mobileNumberValidation={{
          ...currentUser?.mobileNumberValidation,
          validated: currentUser?.mobileNumberValidated,
          mobileNumberValid: phoneNumberValid(currentUser?.mobileNumber),
        }}
        onConfirmSendCode={(sendCode) => {
          if (sendCode) {
            setIsSaving(true);
            validateMobileNumber(
              {
                type: "request",
              },
              new AbortController()
            );
          }
        }}
        onConfirmValidateCode={(validateCode, validationCode) => {
          if (validateCode && validationCode.length === 6 && !isNaN(validationCode)) {
            setIsSaving(true);
            validateMobileNumber(
              {
                type: "validate",
                code: validationCode,
              },
              new AbortController()
            );
          } else {
            const newFormState = { ...formState };
            newFormState.validationError = true;
            setFormState(newFormState);
            setSnackbarAlertState({
              alertMessage: "Validation code must be a 6-digit value.",
              open: true,
            });
          }
        }}
      />
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists || formState.validationError ? "error" : "success"}
        alertMessage={snackbarAlertState.alertMessage}
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentUser?.name || "",
          displayName: currentUser?.displayName || "",
          email: currentUser?.email || "",
          phoneNumber: currentUser?.phoneNumber || "",
          mobileNumber: currentUser?.mobileNumber || "",
          allowedLogin: currentUser?.allowedLogin || "",
          roleId: currentUser?.role || assignedRole.roleId,
          roleName: currentUser?.roleObj?.name || "",
          inactive: currentUser?.inactive || false,
        }}
        onSubmit={(values, helpers) => {
          onUserFormSubmit(values, false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[USERS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                <Button
                  onClick={() => {
                    const newValues = {
                      ...values,
                      _v: 0,
                    };
                    if (currentUser) newValues._id = currentUser._id;
                    setShowUnsavedChangesModal(false);
                    onUserFormSubmit(newValues, true);
                  }}
                  disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                  autoFocus
                >
                  Save & Continue
                </Button>
              </DialogActions>
            </Dialog>

            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar
                titleText={`${!currentUser ? "add new" : "view/edit"} user`}
                editMode={currentUser !== null}
              />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentUser ? "Enter " : ""}User Name`}
                          placeholder="User Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="displayName"
                          id="displayName"
                          label={`${!currentUser ? "Enter " : ""}Display Name`}
                          placeholder="Display Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("displayName") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field" style={{ display: userObj?.userRole?.superAdmin ? "" : "none" }}>
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="roleName"
                          id="roleName"
                          label={`${!currentUser ? "Select a " : ""} User Role`}
                          placeholder="Role Names"
                          options={roleNames}
                          value={
                            roleNames.length > 0 && roleNames.find((r) => r === assignedRole.roleName)
                              ? assignedRole.roleName
                              : ""
                          }
                          onChange={(e) => {
                            // Redefining the onChange() event negates Formik setting the 'dirty' flag
                            const selectedRole = roleOptions.find((r) => r.name === e.target?.value);
                            setAssignedRole({
                              roleId: selectedRole._id,
                              roleName: selectedRole.name,
                            });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            setFormState(newFormState);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("role") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field" style={{ display: userObj?.userRole?.superAdmin ? "" : "none" }}>
                        {userCanUpdate ? (
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">Allow Login?</div>
                            <Field
                              asComponent="checkboxYesNo"
                              name="allowedLogin"
                              id="allowedLogin"
                              onClick={(e) => {
                                const newFormState = { ...formState };
                                newFormState.errorExists = false;
                                setFormState(newFormState);
                              }}
                              InputProps={{
                                readOnly: !userCanUpdate,
                              }}
                            />
                            {requiredFieldNames.includes("allowLogin") && <RequiredFieldIndicator />}
                          </div>
                        ) : (
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">Allow Login?</div>
                            <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14", fontSize: "1.2em" }}
                              />
                              {currentUser?.allowedLogin ? "Yes" : "No"}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div
                        className="formik-field"
                        style={{ paddingBottom: !emailAddressValid(values.email) ? "0px" : "" }}
                      >
                        <Field
                          asComponent="text"
                          name="email"
                          id="email"
                          label={`${!currentUser ? "Enter " : ""}Email`}
                          placeholder="Email"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("email") && <RequiredFieldIndicator />}
                      </div>
                      {!emailAddressValid(values.email) && (
                        <div
                          style={{
                            ...warningFontStyle,
                            paddingLeft: "4px",
                          }}
                        >
                          <b>*</b> Must be a valid email address
                        </div>
                      )}

                      <div
                        className="formik-field"
                        style={{ paddingBottom: !phoneNumberValid(values.phoneNumber) ? "0px" : "" }}
                      >
                        <Field
                          asComponent="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          label={`${!currentUser ? "Enter " : ""}Phone Number`}
                          placeholder="Phone Number"
                          maxLength={10}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("phoneNumber") && <RequiredFieldIndicator />}
                      </div>
                      {!phoneNumberValid(values.phoneNumber) && (
                        <div
                          style={{
                            ...warningFontStyle,
                            paddingLeft: "4px",
                          }}
                        >
                          <b>*</b> Phone Number must be in E164 format: +11234567890
                        </div>
                      )}

                      <div
                        className="formik-field"
                        style={{
                          width: "500px",
                          paddingBottom:
                            !phoneNumberValid(values.mobileNumber) ||
                            currentUser?.smsRejected ||
                            (phoneNumberValid(currentUser?.mobileNumber) &&
                              !currentUser?.mobileNumberValidation?.validated &&
                              currentUser?.mobileNumberValidation?.code &&
                              !validateMobileNumberDialogState.open &&
                              values.mobileNumber.length > 0 &&
                              currentUser.mobileNumber === values.mobileNumber)
                              ? "0px"
                              : "",
                        }}
                      >
                        <Field
                          asComponent="text"
                          name="mobileNumber"
                          id="mobileNumber"
                          label={`${!currentUser ? "Enter " : ""}Mobile Number`}
                          placeholder="Mobile Number"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />

                        {requiredFieldNames.includes("mobileNumber") && <RequiredFieldIndicator />}

                        {currentUser?.mobileNumber?.length > 0 &&
                          values?.mobileNumber?.length === 12 &&
                          phoneNumberValid(currentUser?.mobileNumber) &&
                          phoneNumberValid(values.mobileNumber) &&
                          currentUser?.mobileNumber === values?.mobileNumber && (
                            <Button
                              className="config-settings-btn"
                              onClick={() => {
                                setValidateMobileNumberDialogState({ open: true, user: currentUser?.name });
                              }}
                              type="button"
                              title={userCanUpdate ? "Validate Mobile Number" : ""}
                              disabled={
                                !userCanUpdate ||
                                !currentUser ||
                                (currentUser && currentUser.mobileNumber?.length === 0) ||
                                (currentUser && !phoneNumberValid(currentUser.mobileNumber)) ||
                                currentUser?.mobileNumberValidated
                              }
                              style={{
                                fontSize: ".9em",
                                height: "36px",
                                width: "110px",
                                marginTop: "8px",
                                marginLeft: "8px",
                                border:
                                  currentUser?.mobileNumber?.length > 0 &&
                                  phoneNumberValid(currentUser?.mobileNumber) &&
                                  !currentUser?.mobileNumberValidated
                                    ? "1px solid red"
                                    : "none",
                                color:
                                  (currentUser?.mobileNumber?.length > 0 &&
                                    phoneNumberValid(currentUser?.mobileNumber) &&
                                    !currentUser?.mobileNumberValidated) ||
                                  currentUser?.smsRejected
                                    ? "red"
                                    : "green",
                                display: userCanUpdate ? "" : "none",
                              }}
                            >
                              <FontAwesomeIcon icon={getValidateButtonIcon()} style={getValidateButtonStyle()} />
                              &nbsp;
                              {!currentUser?.mobileNumberValidated ? "Validate" : null}
                              {currentUser?.mobileNumberValidated && !currentUser?.smsRejected ? "Validated" : null}
                              {currentUser?.mobileNumberValidated && currentUser?.smsRejected ? "Stopped" : null}
                            </Button>
                          )}
                      </div>
                      {!phoneNumberValid(values.mobileNumber) && (
                        <div
                          style={{
                            ...warningFontStyle,
                            paddingLeft: "0px",
                          }}
                        >
                          <b>*</b> Mobile Number must be in E164 format: +11234567890
                        </div>
                      )}

                      {phoneNumberValid(currentUser?.mobileNumber) &&
                        !currentUser?.mobileNumberValidation?.validated &&
                        currentUser?.mobileNumberValidation?.code &&
                        !validateMobileNumberDialogState.open &&
                        values.mobileNumber.length > 0 &&
                        currentUser.mobileNumber === values.mobileNumber && (
                          <div
                            style={{
                              ...warningFontStyle,
                              paddingLeft: "0px",
                            }}
                          >
                            <b>*</b> An SMS message was sent to this mobile number. Enter the 6-digit validation code
                            provided
                          </div>
                        )}

                      {phoneNumberValid(values.mobileNumber) &&
                        currentUser?.mobileNumber === values?.mobileNumber &&
                        currentUser?.smsRejected && (
                          <div
                            style={{
                              ...warningFontStyle,
                              paddingLeft: "0px",
                            }}
                          >
                            <b>*</b> Text <b>UNSTOP</b> or <b>START</b> to <b>+18662372981</b> to restart SMS messages
                          </div>
                        )}
                      {/* User Notifications Management */}
                      <div className="formik-user-notifications-table" style={{ marginBottom: "18px" }}>
                        <div className="InvolvedPartiesHeading">
                          <div className="formik-involvedparties-label">User Notifications</div>
                          <Button
                            className="config-settings-btn"
                            onClick={() => {
                              const newFormState = { ...formState };
                              newFormState.showUserNoticicationsModal = true;
                              setFormState(newFormState);
                            }}
                            type="button"
                            title={userCanUpdate ? "Configure Settings" : "View Settings"}
                            disabled={!currentUser}
                            style={{ color: currentUser ? "" : "darkgrey" }}
                          >
                            <FontAwesomeIcon
                              icon={userCanUpdate ? faWrench : faMagnifyingGlassChart}
                              style={{ opacity: "0.9", color: currentUser ? "" : "darkgrey" }}
                            />
                            &nbsp;{userCanUpdate ? "Configure" : "Settings"}
                          </Button>
                        </div>
                        {formState.showUserNoticicationsModal && (
                          <>
                            <Modal
                              onClick={() => {
                                const newFormState = { ...formState };
                                newFormState.showUserNoticicationsModal = false;
                                setFormState(newFormState);
                              }}
                            />
                            <UserNotificationsForm
                              currentUser={currentUser}
                              userCanUpdate={userCanUpdate}
                              mobileNumberIsValid={
                                currentUser?.mobileNumber?.length > 0 &&
                                phoneNumberValid(currentUser?.mobileNumber) !== null
                              }
                              mobileNumberValidated={currentUser?.mobileNumberValidated}
                              setModalIsOpen={(open) => {
                                setShowUserNoticicationsModal(open);
                              }}
                              setSnackbarAlertState={(newState) => setSnackbarAlertState(newState)}
                            />
                          </>
                        )}
                      </div>

                      {/* Available Location Management */}
                      {/* If user you are viewing has admin or superadmin perms, do NOT show menu */}
                      {(!currentUser?.roleObj?.admin || !currentUser?.roleObj?.superAdmin) && (
                        <div className="formik-user-notifications-table" style={{ marginBottom: "18px" }}>
                          <div className="InvolvedPartiesHeading">
                            <div className="formik-involvedparties-label">
                              Location Permissions
                              {userLocationsList.length !== allLocationsList.length && (
                                <span className="location-subtext">Restrictions Applied (Admins bypass filter)</span>
                              )}
                            </div>
                            <Button
                              className="config-settings-btn"
                              onClick={() => {
                                const newFormState = { ...formState };
                                newFormState.showAvailableLocationsModal = true;
                                setFormState(newFormState);
                              }}
                              type="button"
                              title={userCanUpdate ? "Configure Settings" : "View Settings"}
                              disabled={!currentUser}
                              style={{ color: currentUser ? "" : "darkgrey" }}
                            >
                              <FontAwesomeIcon
                                icon={userCanUpdate ? faWrench : faMagnifyingGlassChart}
                                style={{ opacity: "0.9", color: currentUser ? "" : "darkgrey" }}
                              />
                              &nbsp;{userCanUpdate ? "Configure" : "Settings"}
                            </Button>
                          </div>
                          {formState.showAvailableLocationsModal && (
                            <>
                              <Modal
                                onClick={() => {
                                  const newFormState = { ...formState };
                                  newFormState.showAvailableLocationsModal = false;
                                  setFormState(newFormState);
                                }}
                              />
                              <AvailableLocationsForm
                                currentUser={currentUser}
                                userCanUpdate={userCanUpdate}
                                mobileNumberIsValid={
                                  currentUser?.mobileNumber?.length > 0 &&
                                  phoneNumberValid(currentUser?.mobileNumber) !== null
                                }
                                mobileNumberValidated={currentUser?.mobileNumberValidated}
                                setModalIsOpen={(open) => {
                                  setShowAvailableLocationsModal(open);
                                }}
                                setSnackbarAlertState={(newState) => setSnackbarAlertState(newState)}
                                userLocationsList={userLocationsList}
                                setUserLocationsList={(list) => setUserLocationsList(list)}
                                allLocationsList={allLocationsList}
                                setAllLocationsList={(list) => setAllLocationsList(list)}
                              />
                            </>
                          )}

                          {/* <p className="location-subtext-label"> (Admins bypass filter)</p> */}
                        </div>
                      )}
                      {currentUser && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  if (e !== null && !dirty) {
                                    const newFormState = { ...formState };
                                    newFormState.errorExists = false;
                                    setFormState(newFormState);
                                  }
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentUser.inactive ? "Yes" : "No"}
                              </div>{" "}
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                      {/* <div className="reset-password-container">
                        <div className="reset-password">
                          <Button onClick={handlePasswordReset}>
                            <FontAwesomeIcon icon={faWrench} />
                            &nbsp;Change Password
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="formik-field-footer">
                    {currentUser && (
                      <div style={{ marginTop: "36px" }}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED AT: </span>
                          {`${new Date(currentUser.updatedAt).toLocaleDateString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                          })} ${new Date(currentUser.updatedAt).toLocaleTimeString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                            timeZoneName: "short",
                          })}`}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED AT: </span>
                          {`${new Date(currentUser.createdAt).toLocaleDateString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                          })} ${new Date(currentUser.createdAt).toLocaleTimeString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                            timeZoneName: "short",
                          })}`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentUser}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.selectedItemChanged) ||
                  !contactNumbersValid(values.phoneNumber, values.mobileNumber) ||
                  !emailAddressValid(values.email)
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[USERS].path}`);
                }}
                user={userObj.user}
                userRole={userObj.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

UserForm.propTypes = {
  currentUser: PropTypes.object,
  roleOptions: PropTypes.array,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
};

export default UserForm;
