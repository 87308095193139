import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";

function CheckboxYesNoComponent({ className, name }) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (currentField) => {
    setFieldValue(name, !currentField.value);
  };

  return (
    <>
      <FormControlLabel
        className={className}
        checked={Boolean(field.value)}
        control={<Checkbox color="success" />}
        error={meta && meta.touched && meta.error ? true : undefined}
        label="Yes"
        name={field.name}
        onBlur={field.onBlur}
        onChange={() => handleOnChange(field)}
        variant="outlined"
      />
      <FormControlLabel
        className={className}
        checked={!field.value}
        control={<Checkbox color="success" />}
        error={meta && meta.touched && meta.error ? true : undefined}
        label="No"
        name={field.name}
        onBlur={field.onBlur}
        onChange={() => handleOnChange(field)}
        variant="outlined"
      />
    </>
  );
}

CheckboxYesNoComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

CheckboxYesNoComponent.defaultProps = {
  className: undefined,
};

export default CheckboxYesNoComponent;
