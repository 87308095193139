import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench, faCity } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Spinner from "../components/atoms/spinner/Spinner";
import FormikFormTitleBar from "./formikFormTitleBar";
import { users, locations } from "../api";
import { errorHandler } from "../app/errorHandlers";

function AvailableLocationsForm({
  currentUser,
  userCanUpdate,
  mobileNumberIsValid,
  mobileNumberValidated,
  setModalIsOpen,
  setSnackbarAlertState,
  userLocationsList,
  setUserLocationsList,
  allLocationsList,
  setAllLocationsList,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const horizontalBorders = {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  };
  const iconStyle = {
    marginRight: "8px",
    opacity: "0.9",
    borderRadius: "2px",
  };
  const iconCellStyle = { padding: "0px", fontSize: ".80rem" };
  const textCellStyle = { padding: "0px", fontSize: ".85rem" };
  const iconButtonStyleProps = { fontSize: "1.2rem", cursor: "default" };

  async function upsertNewUserLocationsToAPI(controller) {
    const newSnackbarState = { open: true };
    try {
      const success = await users.updateLocations(currentUser._id, userLocationsList, controller.signal);
      if (success) {
        newSnackbarState.alertMessage = "User Locations Settings updated successfully.";
        newSnackbarState.alertSeverity = "success";
      }
    } catch (error) {
      newSnackbarState.alertMessage = "Error updating User Locations Settings.";
      newSnackbarState.alertSeverity = "error";
      errorHandler(error);
    } finally {
      setSnackbarAlertState(newSnackbarState);
      setIsLoading(false);
      setModalIsOpen(false);
    }
  }

  const onLocationCheckboxClick = (location, checkbox) => {
    const newSelectedLocations = [...userLocationsList];

    if (checkbox.checked) {
      newSelectedLocations.push(location._id);
    } else {
      const index = newSelectedLocations.indexOf(location._id);
      if (index > -1) {
        newSelectedLocations.splice(index, 1);
      }
    }
    setUserLocationsList(newSelectedLocations);
    return true;
  };

  const onLocationAddAll = (checkbox) => {
    if (checkbox.checked) {
      setUserLocationsList(allLocationsList.map((l) => l._id));
    } else {
      setUserLocationsList([]);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    upsertNewUserLocationsToAPI(new AbortController());
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="InvolvedPartyForm">
      <FormikFormTitleBar titleText={`Location Permissions [${currentUser.name}]`} editMode={false} icon={faWrench} />

      <div className="formik-field-view">
        <div>
          <TableContainer style={{ maxHeight: "600px" }}>
            {/* stickyHeader attribute affects table styling - unable to reslove
            SEE: https://stackoverflow.com/questions/66031955/how-to-apply-css-on-table-header-of-material-ui-table-stickyheader */}
            <Table aria-label="User Notifications">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#ff5e14",
                    border: "1px solid black",
                    "& th": { color: "#ffffff" },
                  }}
                >
                  <TableCell style={{ paddingLeft: "18px", borderBottom: "1px solid black" }} />
                  <TableCell style={{ paddingLeft: "0px", borderBottom: "1px solid black" }}>
                    <b>Location</b>
                  </TableCell>

                  <TableCell style={{ paddingLeft: "10px", borderBottom: "1px solid black" }}>
                    <b>Allow Access</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  style={{
                    backgroundColor: "#EAECEE",
                    padding: "0px",
                    ...horizontalBorders,
                  }}
                >
                  <TableCell style={{ width: "110px", padding: "0px" }}>
                    <IconButton sx={{ ...iconButtonStyleProps }} style={{ marginLeft: "8px", opacity: "0.9" }}>
                      <FontAwesomeIcon icon={faWrench} />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ ...textCellStyle }} />
                  <TableCell
                    style={{
                      width: "700px",
                      ...iconCellStyle,
                    }}
                  >
                    {userCanUpdate ? (
                      <Checkbox
                        name="all"
                        value="all"
                        onClick={(e) => onLocationAddAll(e.target)}
                        checked={userLocationsList.length === allLocationsList.length}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{
                          ...iconStyle,
                        }}
                      />
                    )}
                    Add All
                  </TableCell>
                </TableRow>
                {allLocationsList.map((l) => (
                  <Fragment key={l._id}>
                    {/* LOCATION row */}
                    <TableRow
                      key={l._id}
                      style={{
                        backgroundColor: "#EAECEE",
                        padding: "0px",
                        ...horizontalBorders,
                      }}
                    >
                      <TableCell style={{ width: "110px", padding: "0px" }}>
                        <IconButton
                          title={l.name}
                          sx={{ ...iconButtonStyleProps }}
                          style={{ marginLeft: "8px", opacity: "0.9" }}
                        >
                          <FontAwesomeIcon icon={faCity} />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ ...textCellStyle }}>{l.name || ""}</TableCell>
                      <TableCell
                        style={{
                          width: "700px",
                          ...iconCellStyle,
                        }}
                      >
                        {userCanUpdate ? (
                          <Checkbox
                            id={l._id}
                            name={l._id}
                            value={l.id}
                            onClick={(e) => onLocationCheckboxClick(l, e.target)}
                            checked={userLocationsList.includes(l._id)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{
                              ...iconStyle,
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="InvolvedPartyForm-btn">
        <Button
          className="cancel-btn"
          variant="outlined"
          onClick={() => {
            setModalIsOpen(false);
          }}
        >
          Cancel
        </Button>
        {userCanUpdate && (
          <Button className="config-settings-btn" onClick={handleSubmit} type="button" title="Apply">
            Apply
          </Button>
        )}
      </div>
    </div>
  );
}

AvailableLocationsForm.propTypes = {
  currentUser: PropTypes.object,
  userCanUpdate: PropTypes.bool,
  mobileNumberIsValid: PropTypes.bool,
  mobileNumberValidated: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  setSnackbarAlertState: PropTypes.func,
  userLocationsList: PropTypes.array,
  setUserLocationsList: PropTypes.func,
  allLocationsList: PropTypes.array,
  setAllLocationsList: PropTypes.func,
};

export default AvailableLocationsForm;
