import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Drawer, MenuItem, Select, Typography } from "@mui/material";
import { setTargetClient, setUser } from "../../../redux/slices/globalSlice";
import { getAllCollectionsOptions, getAllCollectionURL, getUserFromAPI } from "../../../api/index";
import { routes } from "../../../nav/nav";
import { fetchCollectionAll } from "../../../redux/slices/filterOptionsSlice";

const { CLIENTS, USERS, MAINTENANCE, DASHBOARD, paths, properties } = routes;

function ClientPicker({ drawerOpen, toggleDrawerOpen, clientList }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { targetClient: currentClient } = useSelector((state) => state.global);

  const handleClientChange = async (event) => {
    const currentBasePath = paths.find((p) => window.location.pathname.includes(p.pathname));
    const targetClient = clientList.find((client) => client._id === event.target.value);
    let newUserProfile = null;
    if (targetClient) {
      dispatch(
        setTargetClient({
          id: targetClient._id,
          domainName: targetClient.domainName,
          name: targetClient.name,
        })
      );
      newUserProfile = await getUserFromAPI(new AbortController());
      dispatch(setUser(newUserProfile));
    }
    const url = getAllCollectionURL({ endpoint: CLIENTS });
    dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
    if (
      (window.location.pathname.indexOf(properties[CLIENTS].path) > -1 ||
        window.location.pathname.indexOf(properties[USERS].path) > -1 ||
        window.location.pathname.indexOf(properties[MAINTENANCE].path) > -1) &&
      !newUserProfile.roleObj.admin
    )
      navigate(properties[DASHBOARD].path);
    else if (window.location.pathname.localeCompare(currentBasePath.pathname) !== 0) navigate(currentBasePath.pathname);
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawerOpen}>
      <Box sx={{ width: 250, m: 1 }} role="presentation">
        <Typography variant="h6">Select a client</Typography>
        <Select sx={{ width: "100%" }} value={currentClient.id} onChange={handleClientChange}>
          {clientList?.map((client) => (
            <MenuItem key={client._id} value={client._id}>
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Drawer>
  );
}

ClientPicker.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  clientList: PropTypes.array,
};

export default ClientPicker;
