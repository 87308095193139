import { gridClasses } from "@mui/x-data-grid-pro";
// https://mui.com/system/getting-started/the-sx-prop/

export const themeStyles = {
  sx: {
    display: "flex",
    boxSizing: "border-box",
    height: "100%",

    "& .MuiDataGrid-toolbarContainer": {
      padding: "6px",
      justifyContent: "space-between",
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold !important",
      overflow: "visible !important",
      fontSize: "small",
    },

    "& .MuiDataGrid-columnHeader--sorted": {
      backgroundColor: "#f9f9f9",
      color: "#1976d2",
    },

    "& .MuiDataGrid-columnHeader--filtered": {
      backgroundColor: "#f9f9f9",
      color: "#1976d2",
    },

    "& .MuiDataGrid-main": {
      borderTop: "2px solid #ff5e14",
    },

    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#eeeeee",
    },

    [`& .${gridClasses.root} .${gridClasses.cell}`]: {
      py: 1,
    },
  },
};

export const gridContainerStyles = {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  backgroundColor: "#ffffff",
};

export const formContainerStyles = {
  width: "100%",
  backgroundColor: "#ffffff",
  overflow: "hidden",
};
