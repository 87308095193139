import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { deleteAttachment, downloadAttachmentFile, getFileAttachmentURL } from "../../api";
import Spinner from "../../components/atoms/spinner/Spinner";

export default function AttachmentsDialog({
  popAttachments,
  attachments,
  attachmentsDialogState,
  setAttachmentsDialogState,
  onNewFiles,
}) {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileExists, setFileExists] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    filePath: "",
  });

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    const decimalPlaces = 2;
    const size = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${(bytes / 1024 ** size).toFixed(decimalPlaces)} ${units[size]}`;
  };

  const downloadFile = async (attachmentPath, fileName) => {
    const url = await getFileAttachmentURL(attachmentsDialogState.buildingId, attachmentPath);
    downloadAttachmentFile(url, fileName);
  };
  const removeFile = async (attachmentPath) => {
    setIsLoading(true);
    await deleteAttachment(attachmentsDialogState.buildingId, attachmentPath);
    await popAttachments();
    setIsLoading(false);
    setFileExists([]);
  };

  const handleFileChange = async (e) => {
    setIsLoading(true);
    const droppedFiles = Array.from(e.target.files);
    const currfileExists = [];
    const filesToUpload = [];
    droppedFiles.forEach(async (file) => {
      if (!checkIfFileExists(file.name)) {
        filesToUpload.push(file);
      } else {
        currfileExists.push(file.name);
      }
    });
    if (currfileExists) setFileExists(currfileExists);
    if (filesToUpload) await onNewFiles(filesToUpload);
    await popAttachments();
    setIsLoading(false);
  };
  const checkIfFileExists = (fileName) =>
    attachments[attachmentsDialogState.buildingName].some((attachment) => attachment.name === fileName);
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDrop = async (e) => {
    if (e) {
      setIsLoading(true);
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);

      const droppedFiles = Array.from(e.dataTransfer.files);
      const currfileExists = [];
      const filesToUpload = [];
      droppedFiles.forEach(async (file) => {
        if (!checkIfFileExists(file.name)) {
          filesToUpload.push(file);
        } else {
          currfileExists.push(file.name);
        }
      });
      if (currfileExists) setFileExists(currfileExists);
      if (filesToUpload) await onNewFiles(filesToUpload);

      await popAttachments();
      setIsLoading(false);
    }
  };
  return (
    <Dialog
      open={attachmentsDialogState.open}
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { height: "60%" } }}
      onClose={() => setAttachmentsDialogState({ open: false, buildingName: "", attachments: [], buidlingId: "" })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        component="div"
        sx={{ height: "100%" }}
        onDragOver={(e) => handleDrag(e)}
        onDragLeave={() => setDragging(false)}
        onDrop={(e) => handleDrop(e)}
      >
        {dragging ? (
          <div
            style={{
              border: "dashed grey 4px",
              backgroundColor: "rgba(255,255,255,.8)",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: 0,
                left: 0,
                textAlign: "center",
                color: "grey",
                fontSize: 36,
              }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Drag & Drop files here to upload
              </Typography>
            </div>
          </div>
        ) : (
          <Box sx={{ height: "100%", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
            <DialogTitle id="alert-dialog-title">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faFileUpload}
                    style={{ marginRight: "8px", marginTop: "2px", opacity: "0.8", color: "#ff5e14" }}
                  />
                  <Typography variant="h5">Attachments for: {attachmentsDialogState.buildingName}</Typography>
                </Box>
                <IconButton
                  size="large"
                  color="error"
                  sx={{ padding: "0px" }}
                  onClick={() =>
                    setAttachmentsDialogState({ open: false, buildingName: "", attachments: [], buidlingId: "" })
                  }
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              sx={{
                paddingX: "2em",
                paddingBottom: "0",
              }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <TableContainer component={Paper} sx={{ maxHeight: "21em" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell align="right">File Size</TableCell>
                        <TableCell align="right">Date Uploaded</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attachmentsDialogState.buildingName !== "" &&
                        attachments[attachmentsDialogState.buildingName].map((row) => (
                          <TableRow key={row.name}>
                            <TableCell scope="row">{row.name}</TableCell>
                            <TableCell scope="row" align="right">
                              {formatFileSize(row.size)}
                            </TableCell>
                            <TableCell scope="row" align="right">
                              {new Date(row.createdOn).toLocaleDateString()}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton color="success" onClick={() => downloadFile(row.path, row.name)}>
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() =>
                                  setConfirmDelete({
                                    open: true,
                                    fileName: row.name,
                                    filePath: row.path,
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {fileExists.length > 0 && (
                <Typography variant="h6" color="error" align="center" sx={{ marginY: "1em" }}>
                  File Exists Already:{" "}
                  {fileExists.map((file, index) => (
                    <span color="black" key={file}>
                      {file}
                      {index < fileExists.length - 1 && <span>, </span>}
                    </span>
                  ))}
                </Typography>
              )}
            </DialogContent>
            <Typography
              variant="h6"
              color="primary"
              align="center"
              sx={{
                marginY: "1em",
                cursor: "pointer",
              }}
              onClick={() => fileInputRef.current.click()}
            >
              Click here or drag & drop files to upload
              <input ref={fileInputRef} type="file" style={{ display: "none" }} multiple onChange={handleFileChange} />
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog
        sx={{
          "& .MuiDialog-paper": { width: "fit-content", height: "fit-content" },
        }}
        open={confirmDelete.open}
        onClose={() => {
          setConfirmDelete({
            open: false,
            fileName: "",
            filePath: "",
          });
          setFileExists([]);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <FontAwesomeIcon size="lg" icon={faFile} style={{ marginRight: "8px", opacity: "0.8", color: "#ff5e14" }} />
          {confirmDelete.fileName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete: {confirmDelete.fileName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ alignSelf: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{
                marginRight: "4em",
              }}
              onClick={() => {
                setConfirmDelete({
                  open: false,
                  fileName: "",
                  filePath: "",
                });
                setFileExists([]);
              }}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setConfirmDelete({
                  open: false,
                  fileName: "",
                  filePath: "",
                });
                removeFile(confirmDelete.filePath);
              }}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

AttachmentsDialog.propTypes = {
  popAttachments: PropTypes.func,
  attachments: PropTypes.object,
  onNewFiles: PropTypes.func,
  setAttachmentsDialogState: PropTypes.func,
  attachmentsDialogState: PropTypes.object,
  buildingId: PropTypes.string,
};
