import React from "react";

function Spinner() {
  return (
    <div className="loading">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Spinner;
