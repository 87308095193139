export function errorHandler(error, callback = null, param = null) {
  console.error("errorHandler()", {
    error,
    callback,
    param,
  });
  if (callback && typeof callback === "function" && param) callback(param);
}

export function formErrorHandler(error) {
  console.error("formErrorHandler()", { error });
}

export function gridErrorHandler(error, callback = null, param = null) {
  console.error("gridErrorHandler()", {
    error,
    callback,
    param,
  });
  if (callback && typeof callback === "function" && param) callback(param);
}
