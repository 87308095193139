import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField } from "formik";
import PropTypes from "prop-types";

function TextInputWrapper({ name, width, ...props }) {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...props,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return (
    <fieldset
      style={{
        width: "500px",
        border: "1px solid #c5c5c5",
        borderRadius: "4px",
        fontSize: "0.70rem",
        paddingLeft: "8px",
        paddingTop: "10px",
        paddingBottom: "14px",
      }}
    >
      <legend style={{ color: "#808080" }}>&nbsp;&nbsp;{props.label}&nbsp;&nbsp;</legend>
      <DatePicker
        selected={props.selectedDate ? props.selectedDate : new Date()}
        dateFormat="MMMM d, yyyy"
        className="form-control"
        portalId="root"
        onChange={(selectedDate) => {
          props?.onChange(selectedDate);
        }}
      />
    </fieldset>
  );
}

TextInputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  label: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};
TextInputWrapper.defaultProps = {
  width: "500px",
};

export default TextInputWrapper;
