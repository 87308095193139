import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import Field from "../formikUI/field/Field";
import { postItemToAPI, patchItemToAPI, rooms } from "../api/index";
import { routes } from "../nav/nav";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function RoomForm({
  currentRoom,
  locationOptions,
  buildingOptions,
  departmentOptions,
  onLocationChange,
  onBuildingChange,
  onDepartmentChange,
  errorHandler,
  onFormPost,
  onFormSubmit,
  currentFormState,
  setCurrentFormState,
}) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, ROOMS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = rooms;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;
  const [newRecordId, setNewRecordId] = useState("");
  const [lastRecordId, setLastRecordId] = useState("");
  const [exitTemplateMode, setExitTemplateMode] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formState, setFormState] = useState(currentFormState);

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setIsSaveAndAddNew(isSaveAndAddNew) {
    const newFormState = { ...formState };
    newFormState.isSaveAndAddNew = isSaveAndAddNew;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedLocation, setAssignedLocation] = useState({
    locationId: currentRoom?.locationId || "",
    locationName: currentRoom ? currentRoom?.locationObj?.name || "" : "",
  });

  const [assignedBuilding, setAssignedBuilding] = useState({
    buildingId: currentRoom ? currentRoom.buildingId || "" : "",
    buildingName: currentRoom ? currentRoom?.buildingObj?.name || "" : "",
  });

  const [assignedDepartment, setAssignedDepartment] = useState({
    departmentId: currentRoom ? currentRoom.departmentId || "" : "",
    departmentName: currentRoom ? currentRoom?.departmentObj?.name || "" : "",
  });

  const onRoomFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    let response = null;
    const controller = new AbortController();
    try {
      // update this to have the room values from the db
      const updatedRoom = {
        ...currentRoom,
        ...values,
      };
      if (currentRoom?._id && !formState.isTemplate) {
        response = await patchItemToAPI(rooms, updatedRoom, controller);
        newFormState.isTemplate = values.isTemplate;
        if (values.isTemplate) delete newFormState.currentValues._id;
      } else {
        setLastRecordId(currentRoom?._id);
        delete updatedRoom._id;
        response = await postItemToAPI(rooms, updatedRoom, controller);
        delete values.isTemplate;
      }

      if (!currentRoom || formState.isTemplate) {
        setNewRecordId(response.id);
        updatedRoom._id = response.id;
      }
      if (navigateToList) navigate(`${properties[ROOMS].path}`);
      else {
        onFormPost(updatedRoom);
        onFormSubmit(values.locationId, values.buildingId, formState.selectedItemChanged);
        newFormState.selectedItemChanged = false;
        newFormState.errorExists = false;
        newFormState.showUnsavedChangesModal = false;
        setFormIsDirty(false);
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentRoom : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Room", currentRoom?._id ? currentRoom : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  const locationNames = [];
  if (locationOptions?.length > 0) locationOptions?.map((location) => locationNames.push(location.name));

  const buildingNames = [];
  if (buildingOptions?.length > 0) buildingOptions?.map((building) => buildingNames.push(building.name));

  const departmentNames = [];
  if (departmentOptions?.length > 0) departmentOptions?.map((dept) => departmentNames.push(dept.name));

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [
      fieldValues?.name,
      fieldValues?.messageCode,
      assignedLocation.locationName,
      assignedBuilding.buildingName,
      assignedBuilding.departmentName,
    ];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
      locationId:
        !fieldValues.locationId && currentRoom?.locationId ? currentRoom.locationId : assignedLocation.locationId,
      locationName: assignedLocation.locationName,
      buildingId:
        !fieldValues.buildingId && currentRoom?.buildingId ? currentRoom.buildingId : assignedBuilding.buildingId,
      buildingName: assignedBuilding.buildingName,
      departmentId:
        !fieldValues.departmentId && currentRoom?.departmentId
          ? currentRoom.departmentId
          : assignedDepartment.departmentId,
      departmentName: assignedDepartment.departmentName,
    };
    if (currentRoom) updatedFieldValues._id = currentRoom._id;
    return updatedFieldValues;
  }

  let titleBarText = "";
  if (!currentRoom?._id) titleBarText = `add new room`;
  else if (userCanUpdate) titleBarText = `view/edit room`;
  else titleBarText = `view room`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={snackbarAlertState.alertSeverity}
        alertMessage={snackbarAlertState.alertMessage}
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          if (
            currentFormState.isAddNew ||
            (lastRecordId && newRecordId && lastRecordId !== newRecordId && exitTemplateMode)
          )
            navigate(`${properties[ROOMS].path}/${newRecordId}`);
          else {
            setIsSaving(false);
            setNewRecordId("");
            setExitTemplateMode(false);
          }
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentRoom?.name || "",
          roomNumber: currentRoom?.roomNumber || "",
          description: currentRoom?.description || "",
          inactive: currentRoom?.inactive || false,
          comment: currentRoom?.comment || "",
          locationId: currentRoom?.locationId || assignedLocation.locationId,
          locationName: currentRoom?.locationObj?.name || "",
          buildingId: currentRoom?.buildingId || assignedBuilding.buildingId,
          buildingName: currentRoom?.buildingObj?.name || "",
          departmentId: currentRoom?.departmentId || assignedDepartment.departmentId,
          departmentName: currentRoom?.departmentObj?.name || "",
          messageCode: currentRoom?.messageCode || "",
        }}
        onSubmit={(values, helpers) => {
          if (!(!formState.currentValues || !formIsDirty || (!formIsDirty && !formState.selectedItemChanged))) {
            setIsSaving(true);
            onRoomFormSubmit(getUpdatedFieldValues(values), false);
          }
          if (!formState.errorExists) {
            if (values.isTemplate) {
              values.name = "";
              values.roomNumber = "";
              values.description = "";
              values.comment = "";
              values.messageCode = "";
              delete values._id;
            }
            helpers.resetForm({
              values,
            });
          }
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[ROOMS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onRoomFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                newFormState.currentValues = values;
                setFormState(newFormState);
                setFormIsDirty(true);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentRoom !== null && !formState.isTemplate} />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentRoom ? "Enter " : ""}Room Name`}
                          placeholder="Room Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="roomNumber"
                          id="roomNumber"
                          label={`${!currentRoom ? "Enter" : ""} Room Number`}
                          placeholder="Room Number"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("roomNumber") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="description"
                          id="description"
                          label={`${!currentRoom ? "Enter" : ""} Room Description`}
                          placeholder="Room Description"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("description") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          name="comment"
                          id="comment"
                          label={`${!currentRoom || values.comment.trim().length === 0 ? "Enter" : ""} Room Comments`}
                          placeholder="Room Comments"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="locationName"
                          id="locationName"
                          label={`${!currentRoom ? "Select a " : ""}Location`}
                          placeholder="Locations"
                          options={locationNames}
                          value={
                            locationNames.length > 0 && locationNames.find((l) => l === assignedLocation.locationName)
                              ? assignedLocation.locationName
                              : ""
                          }
                          onChange={(e) => {
                            const selectedLocation = locationOptions.find((l) => l.name === e.target?.value);
                            setAssignedLocation({
                              locationId: selectedLocation._id,
                              locationName: selectedLocation.name,
                            });
                            setAssignedBuilding({ buildingId: "", buildingName: "" });
                            setAssignedDepartment({ departmentId: "", departmentName: "" });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            newFormState.currentValues = values;
                            setFormState(newFormState);
                            setFormIsDirty(true);
                            onLocationChange(selectedLocation._id);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("locationName") && <RequiredFieldIndicator />}
                      </div>

                      {(currentRoom || assignedLocation.locationId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="buildingName"
                            id="buildingName"
                            label={`${!currentRoom ? "Select a" : ""} Building`}
                            placeholder="Buildings"
                            options={buildingNames}
                            value={
                              buildingNames.length > 0 && buildingNames.find((b) => b === assignedBuilding.buildingName)
                                ? assignedBuilding.buildingName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedBuilding = buildingOptions.find((b) => b.name === e.target?.value);
                              setAssignedBuilding({
                                buildingId: selectedBuilding._id,
                                buildingName: selectedBuilding.name,
                              });
                              setAssignedDepartment({ departmentId: "", departmentName: "" });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setFormIsDirty(true);
                              onBuildingChange(selectedBuilding._id);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("buildingName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {(currentRoom || assignedBuilding.buildingId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="departmentName"
                            id="departmentName"
                            label={`${!currentRoom ? "Select a" : ""} Department`}
                            placeholder="Departments"
                            options={departmentNames}
                            value={
                              departmentNames.length > 0 &&
                              departmentNames.find((b) => b === assignedDepartment.departmentName)
                                ? assignedDepartment.departmentName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedDepartment = departmentOptions.find((b) => b.name === e.target?.value);
                              setAssignedDepartment({
                                departmentId: selectedDepartment._id,
                                departmentName: selectedDepartment.name,
                              });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setFormIsDirty(true);
                              onDepartmentChange(selectedDepartment._id);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("departmentName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="messageCode"
                          id="messageCode"
                          onInput={(e) => {
                            if (e.target.value.length > 0) e.target.value = e.target.value.slice(0, 16);
                            e.target.value = e.target.value.toUpperCase();
                          }}
                          placeholder="Device Message (Line 2)"
                          label={`${!currentRoom ? "Enter " : ""}Device Message (Line 2)`}
                          helperText={`${values.messageCode.length}/16`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("messageCode") && <RequiredFieldIndicator />}
                      </div>

                      {currentRoom && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  if (e !== null && !dirty) {
                                    const newFormState = { ...formState };
                                    newFormState.errorExists = false;
                                    setFormState(newFormState);
                                  }
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentRoom.inactive ? "Yes" : "No"}
                              </div>{" "}
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentRoom && !values.isTemplate && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentRoom.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentRoom.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentRoom}
                isTemplate={formState.isTemplate}
                formValues={values}
                buttonDisabled={
                  !formState.currentValues ||
                  !(
                    formState.currentValues && requiredFieldsPopulated(getRequiredFieldValues(formState.currentValues))
                  ) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  !formIsDirty ||
                  (!formIsDirty && !formState.selectedItemChanged) ||
                  !userCanUpdate
                }
                formHasChanges={
                  !(!formState.currentValues || !formIsDirty || (!formIsDirty && !formState.selectedItemChanged))
                }
                requiredFieldsPopulated={requiredFieldsPopulated(getRequiredFieldValues(values))}
                showButtonMenu
                disableButtonMenu={formState.isSaving}
                onSave={(newValues) => {
                  setIsSaveAndAddNew(false);
                  if (!formState.currentValues || !formIsDirty || (!formIsDirty && !formState.selectedItemChanged)) {
                    setSnackbarAlertState({
                      alertMessage: "No changes detected.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else if (!requiredFieldsPopulated(getRequiredFieldValues(values))) {
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    setIsSaving(true);
                    setExitTemplateMode(true);
                    delete newValues.isTemplate;
                    onRoomFormSubmit(getUpdatedFieldValues(newValues), false);
                  }
                }}
                onSaveAndAddNew={(newValues) => {
                  if (!requiredFieldsPopulated(getRequiredFieldValues(newValues))) {
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    const newFormState = { ...formState };
                    formState.isAddNew = false;
                    formState.isSaveAndAddNew = true;
                    setFormState(newFormState);
                    setExitTemplateMode(false);
                    newValues.isTemplate = true;
                    setCurrentFormState(formState);
                    if (formRef.current) formRef.current.handleSubmit();
                  }
                }}
                onSaveAndClose={(formHasChanges, newValues) => {
                  if (formHasChanges && !requiredFieldsPopulated(getRequiredFieldValues(values))) {
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    setIsSaving(true);
                    if (formHasChanges) onRoomFormSubmit(getUpdatedFieldValues(newValues), true);
                    else navigate(`${properties[ROOMS].path}`);
                  }
                }}
                pathIndex={currentPath.index}
                formDidChange={formIsDirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  formIsDirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[ROOMS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

RoomForm.propTypes = {
  currentRoom: PropTypes.object,
  locationOptions: PropTypes.array,
  buildingOptions: PropTypes.array,
  departmentOptions: PropTypes.array,
  onLocationChange: PropTypes.func,
  onBuildingChange: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
  onFormSubmit: PropTypes.func,
  currentFormState: PropTypes.object,
  setCurrentFormState: PropTypes.func,
};

export default RoomForm;
