import React from "react";
import { useMsal } from "@azure/msal-react";
import { Box, Button } from "@mui/material";
import Main from "../../components/organisms/main/Main";
import { loginRequest } from "../../auth/config";
import { errorHandler } from "../../app/errorHandlers";

function Home() {
  const { instance } = useMsal();

  const handleLogin = async (currentInstance, loginReq) => {
    try {
      await currentInstance.loginRedirect(loginReq);
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  };

  return (
    <Main id="home">
      <Box sx={{ textAlign: "center" }}>
        <h1>Pinpoint Management Portal</h1>
        <Button id="loginbtn" variant="contained" onClick={() => handleLogin(instance, loginRequest)}>
          Login
        </Button>
      </Box>
    </Main>
  );
}

export default Home;
