import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import FormikSplitSubmitButton from "./formikSplitSubmitButton";
import NavigationLink from "../components/atoms/navigationLink/NavigationLink";
import { routes } from "../nav/nav";

function FormikFormFooterBar({
  currentItem,
  formValues,
  buttonDisabled,
  disableButtonMenu,
  formHasChanges,
  requiredFieldsPopulated,
  showButtonMenu = false,
  onSave = null,
  onSaveAndAddNew = null,
  onSaveAndClose = null,
  pathIndex,
  onFormClose = null,
  formDidChange,
  formErrorExists,
  user = null,
  userRole,
}) {
  const { properties, USERS } = routes;

  const userCanUpdate =
    userRole?.superAdmin || userRole?.admin || (pathIndex === USERS && currentItem?._id === user?._id);

  return (
    <div className="btn-group">
      <div className="btn-group-left">
        <RequiredFieldIndicator />
        <span
          style={{
            fontStyle: "italic",
            fontSize: "0.9rem",
            opacity: "0.9",
          }}
        >
          &nbsp;Required fields
        </span>
      </div>
      <div className="btn-group-middle" />
      <div className="btn-group-right">
        <Box display="flex" justifyContent="center" gap={2}>
          {pathIndex !== null && (
            <NavigationLink
              path={(!formDidChange && !formErrorExists) || !userCanUpdate ? `${properties[pathIndex]?.path}` : ""}
            >
              <Button className="cancel-btn" variant="outlined" onClick={(e) => onFormClose(e)}>
                Cancel
              </Button>
            </NavigationLink>
          )}
          {pathIndex === null && onFormClose !== undefined && (
            <NavigationLink path="">
              <Button className="cancel-btn" variant="outlined" onClick={(e) => onFormClose(e)}>
                Cancel
              </Button>
            </NavigationLink>
          )}
          {!showButtonMenu && userCanUpdate && (
            <Button
              className="save-btn"
              disabled={buttonDisabled}
              onClick={() => {
                if (onSave && formValues) onSave(formValues);
              }}
            >
              {currentItem === null ? "submit" : "save changes"}
            </Button>
          )}
          {showButtonMenu && userCanUpdate && (
            <FormikSplitSubmitButton
              formValues={formValues}
              formHasChanges={formHasChanges}
              requiredFieldsPopulated={requiredFieldsPopulated}
              onSave={onSave}
              onSaveAndAddNew={onSaveAndAddNew}
              onSaveAndClose={onSaveAndClose}
              disableButtonMenu={disableButtonMenu}
            />
          )}
        </Box>
      </div>
    </div>
  );
}

FormikFormFooterBar.propTypes = {
  currentItem: PropTypes.object,
  formValues: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  disableButtonMenu: PropTypes.bool,
  formHasChanges: PropTypes.bool,
  requiredFieldsPopulated: PropTypes.bool,
  showButtonMenu: PropTypes.bool,
  onSave: PropTypes.func,
  onSaveAndAddNew: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  pathIndex: PropTypes.number,
  onFormClose: PropTypes.func,
  formDidChange: PropTypes.bool,
  formErrorExists: PropTypes.bool,
  user: PropTypes.object,
  userRole: PropTypes.object,
};

export default FormikFormFooterBar;
