import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { Avatar, Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faUser,
  faWifi,
} from "@fortawesome/pro-regular-svg-icons";
import useToggle from "../../../hooks/useToggle";
import ClientPicker from "./ClientPicker";
import { listAllFromAPI, clients } from "../../../api/index";
import { errorHandler } from "../../../app/errorHandlers";

export default function UserMenu({ handleLogout }) {
  const { user, userRole } = useSelector((state) => state.global);
  const { instance } = useMsal();
  const [userInitials, setUserInitials] = useState("");
  const [clientPickerOpen, toggleClientPickerOpen] = useToggle(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [clientList, setClientList] = useState([]);

  async function loadClientOptionsFromAPI() {
    try {
      const response = await listAllFromAPI(clients, new AbortController());
      setClientList(response?.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    if (userRole.superAdmin && clientList?.length === 0) loadClientOptionsFromAPI();
    if (user.name) {
      setUserInitials(`${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}`);
    }
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box id="userMenu" onClick={handleClick}>
        <Avatar variant="rounded">{userInitials}</Avatar>
        <FontAwesomeIcon icon={faAngleDown} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="userMenu-popover"
        disableAutoFocusItem
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemText>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {user.name}
            </Typography>
            <Typography variant="body2" component="div">
              {user.email}
            </Typography>
            <Typography component="div" style={{ fontSize: ".7em", marginTop: "6px", fontWeight: "bold" }}>
              {userRole?.name}
            </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        {userRole.superAdmin && (
          <MenuItem onClick={toggleClientPickerOpen} disabled={!clientList} style={{ color: !clientList ? "red" : "" }}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ color: !clientList ? "red" : "" }} />
            </ListItemIcon>
            Switch Client
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate(`/users/${user._id}`)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} />
          </ListItemIcon>
          User Profile
        </MenuItem>
        <MenuItem onClick={() => handleLogout(instance)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => window.open("https://www.pinpointinc.com/domain-checker")}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faWifi} />
          </ListItemIcon>
          Domain Reachability Checker
        </MenuItem>
      </Menu>
      {userRole.superAdmin && (
        <ClientPicker drawerOpen={clientPickerOpen} toggleDrawerOpen={toggleClientPickerOpen} clientList={clientList} />
      )}
    </>
  );
}

UserMenu.propTypes = {
  handleLogout: PropTypes.func,
};

UserMenu.defaultProps = {};
