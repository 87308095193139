import { format, formatInTimeZone } from "date-fns-tz";

export const datetime = {
  getDateFromTimestamp(timestamp) {
    return timestamp.substring(0, 10);
  },

  getISODateString(dateString) {
    if (!isNaN(new Date(dateString).getTime())) return new Date(dateString.replace("-", "/")).toISOString();
    return dateString;
  },

  getISODateStringFromDate(date) {
    let result = "";
    if (!isNaN(date)) result = date.toISOString();
    return result;
  },

  formatDateFromTimestamp(timestamp) {
    return format(new Date(timestamp), "MM/dd/yyyy");
  },

  formatTimeFromTimestamp(timestamp) {
    return formatInTimeZone(timestamp, "America/New_York", "HH:mm:ss");
  },

  formatTimestamp(timestamp) {
    if (isNaN(Date.parse(timestamp)) === false)
      return formatInTimeZone(timestamp, "America/New_York", "MM/dd/yyyy HH:mm:ss");
    return formatInTimeZone(new Date(), "America/New_York", "MM/dd/yyyy HH:mm:ss");
  },
};

export const strings = {
  getDeleteConfirmMessage(itemName) {
    return `Are you sure you want to permanently delete this ${itemName} record?`;
  },

  getDeleteSuccessMessage(item) {
    const { descriptor } = item;
    return `${descriptor.charAt(0).toUpperCase() + descriptor.slice(1)} record deleted.`;
  },

  getDeleteErrorMessage(item) {
    return `Error deleting ${item.descriptor} record.`;
  },
};

export const forms = {
  getItemErrorMessage(itemName, currentItem) {
    return `Error ${currentItem ? "updating" : "creating"} ${itemName} record.`;
  },

  getItemSucccessMessage(itemName, currentItem) {
    return `${itemName} record ${currentItem ? "updated" : "created"} successfully.`;
  },

  requiredFieldsPopulated(requiredFieldValues) {
    let result = true;
    if (requiredFieldValues && requiredFieldValues.length > 0)
      requiredFieldValues.map((fieldValue) => {
        if (fieldValue !== null && fieldValue !== undefined && fieldValue.trim().length === 0) {
          result = false;
        }
        return result;
      });
    return result;
  },

  getDialogSubMessage(requiredFieldsPopulated, formErrorExists) {
    if (!requiredFieldsPopulated) return "All required fields must be populated.";
    if (formErrorExists) return "There are currently invalid data values.";
    return "";
  },
};
