import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

function FormikFormIncidentAlertBar({ currentAlert }) {
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const lastUpdateAt = new Date(currentAlert?.updatedAt);

  return (
    <div id="alert-text-bar">
      <div id="alert-bar-icon">
        <FontAwesomeIcon icon={faTriangleExclamation} style={{ opacity: "0.9" }} />
      </div>
      <div id="form-title-text">
        date/time:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>
          {`${lastUpdateAt.toLocaleDateString("en-US", { timeZone: tzid })} ${lastUpdateAt.toLocaleTimeString("en-US", {
            hourCycle: "h23",
            timeZone: tzid,
            timeZoneName: "short",
          })}`}
        </span>
      </div>

      <div id="form-title-text">
        Location:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>{currentAlert?.locationName}</span>
      </div>

      <div id="form-title-text">
        Building:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>{currentAlert?.buildingName}</span>
      </div>

      <div id="form-title-text">
        dept:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>{currentAlert?.departmentName}</span>
      </div>

      <div id="form-title-text">
        Room:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>{currentAlert?.roomName}</span>
      </div>

      <div id="form-title-text">
        device address:&nbsp;&nbsp;
        <span style={{ fontWeight: "normal" }}>{currentAlert?.deviceAddress}</span>
      </div>
    </div>
  );
}

FormikFormIncidentAlertBar.propTypes = {
  currentAlert: PropTypes.object,
};

export default FormikFormIncidentAlertBar;
