import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { maintenance, systemAlertTypes, fetchExportData } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  loadFilterOptionsFromAPI,
  dateOperators,
  renderSingleSelectField,
  getExportURL,
  setColumnOrder,
  lookupFieldsMap,
  applyOptions,
} from "../../api/datagridFunctions";
import MaintenanceDataGrid from "../../components/organisms/datagrid/maintenanceDataGrid";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import HttpError from "../httpError";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";

const { sortModel: defaultSortModel, MAINTENANCE } = defaultSortModels;

function List({ errorHandler }) {
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[MAINTENANCE]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(0);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };
  const defaultOptions = useSelector((state) => state.filterOptions.defaultOptions);
  const allCollectionOptions = useSelector((state) => state.filterOptions.allCollectionOptions);
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);
  const [buildingNameFilterOptions, setBuildingNameFilterOptions] = useState([]);
  const [departmentNameFilterOptions, setDepartmentNameFilterOptions] = useState([]);
  const [roomNameFilterOptions, setRoomNameFilterOptions] = useState([]);
  const [deviceNameFilterOptions, setDeviceNameFilterOptions] = useState([]);
  const [deviceAddressFilterOptions, setDeviceAddressFilterOptions] = useState([]);
  const [alertTypeFilterOptions, setAlertTypeFilterOptions] = useState([]);
  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "timestamp", index: 0 },
      { field: "alertTypeName", index: 1 },
      { field: "roomName", index: 2 },
      { field: "departmentName", index: 3 },
      { field: "buildingName", index: 4 },
      { field: "locationName", index: 5 },
      { field: "deviceName", index: 6 },
      { field: "deviceAddress", index: 7 },
    ],
    columnWidths: [
      { field: "timestamp", width: 170 },
      { field: "alertTypeName", width: 200 },
      { field: "roomName", width: 200 },
      { field: "departmentName", width: 200 },
      { field: "buildingName", width: 200 },
      { field: "locationName", width: 200 },
      { field: "deviceName", width: 200 },
      { field: "deviceAddress", width: 140 },
    ],
    columnVisibility: {
      timestamp: true,
      alertTypeName: true,
      roomName: true,
      departmentName: true,
      buildingName: true,
      messageCode: true,
      locationName: true,
      deviceName: true,
      deviceAddress: true,
    },
  });

  const gridColumnDefs = [
    {
      field: "timestamp",
      type: "date",
      headerName: "Date/Time",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "timestamp")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", { timeZone: tzid })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },
    renderSingleSelectField(
      "alertTypeName",
      "Alert Type",
      alertTypeFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "alertTypeName")[0].width
    ),
    renderSingleSelectField(
      "roomName",
      "Room",
      roomNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "roomName")[0].width
    ),
    renderSingleSelectField(
      "departmentName",
      "Department",
      departmentNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "departmentName")[0].width
    ),
    renderSingleSelectField(
      "buildingName",
      "Building",
      buildingNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "buildingName")[0].width
    ),
    renderSingleSelectField(
      "locationName",
      "Location",
      locationNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "locationName")[0].width
    ),
    renderSingleSelectField(
      "deviceName",
      "Device",
      deviceNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceName")[0].width
    ),
    renderSingleSelectField(
      "deviceAddress",
      "Device Address",
      deviceAddressFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceAddress")[0].width
    ),
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });
  useEffect(() => {
    // not device Address because it does not exist on the table device does so use deviceId
    const allowedDrillFilterFields = ["locationId", "buildingId", "departmentId", "roomId", "deviceAddressId"];
    const optionMap = {
      building: [buildingNameFilterOptions, setBuildingNameFilterOptions],
      department: [departmentNameFilterOptions, setDepartmentNameFilterOptions],
      room: [roomNameFilterOptions, setRoomNameFilterOptions],
      deviceAddress: [deviceAddressFilterOptions, setDeviceAddressFilterOptions],
    };
    // filter field is name instead of deviceName
    const pageName = "deviceAddress";
    if (allCollectionOptions) {
      applyOptions(
        allowedDrillFilterFields,
        selectedFilters,
        allCollectionOptions,
        defaultOptions,
        optionMap,
        pageName
      );
    }
  }, [selectedFilters]);

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: maintenance,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currFilterModel = localStorage.getItem("currentFilterModel") || defaultFilterModel;
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[MAINTENANCE];
    setCurrentFilterModel(currFilterModel);
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (defaultOptions) {
      setLocationNameFilterOptions(defaultOptions.location);
      setBuildingNameFilterOptions(defaultOptions.building);
      setDepartmentNameFilterOptions(defaultOptions.department);
      setRoomNameFilterOptions(defaultOptions.roomName);
      setDeviceAddressFilterOptions(defaultOptions.deviceAddress);
      setDeviceNameFilterOptions(defaultOptions.device);
      if (alertTypeFilterOptions.length === 0) loadFilterOptionsFromAPI(systemAlertTypes, setAlertTypeFilterOptions);
    }
  }, [defaultOptions]);
  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <MaintenanceDataGrid
            columnDefs={orderedColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedFilters={setSelectedFilters}
            handleExport={() => {
              fetchExportData(
                getExportURL({ currentFilterModel, currentSortModel, endpoint: MAINTENANCE }),
                maintenance,
                setSnackbarAlertState
              );
            }}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Location", "Building", "Department", "Room", "Alert Type", "Device Address", "Date/Time"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
