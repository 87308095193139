import React from "react";
import { formErrorHandler, gridErrorHandler } from "./errorHandlers";
import Home from "../pages/root/Home";
import Dashboard from "../pages/dashboard/Dashboard";
import AlertsList from "../pages/alerts/List";
import IncidentReportsList from "../pages/incidentReports/List";
import MaintenanceAertsList from "../pages/maintenanceAlerts/List";
import ClientsList from "../pages/client/List";
import UsersList from "../pages/users/List";
import DisplaysList from "../pages/displays/List";
import LocationsList from "../pages/locations/List";
import BuildingsList from "../pages/buildings/List";
import DepartmentsList from "../pages/departments/List";
import RoomsList from "../pages/rooms/List";
import CollectorsList from "../pages/collectors/List";
import DevicesList from "../pages/devices/List";
import ViewAlertById from "../pages/alerts/View";
import ViewIncidentById from "../pages/incidentReports/View";
import ViewClientById from "../pages/client/View";
import ViewUserById from "../pages/users/View";
import ViewLocationById from "../pages/locations/View";
import ViewBuildingById from "../pages/buildings/View";
import ViewDeptById from "../pages/departments/View";
import ViewRoomById from "../pages/rooms/View";
import ViewCollectorById from "../pages/collectors/View";
import ViewDeviceById from "../pages/devices/View";
import ViewDisplayById from "../pages/displays/View";
import NotFound from "../pages/NotFound";

export const routes = [
  {
    name: "home",
    path: "/",
    element: <Home />,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    name: "alerts",
    path: "/alerts",
    element: <AlertsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "viewAlertById",
    path: "/alerts/:alertId",
    element: <ViewAlertById errorHandler={formErrorHandler} />,
  },
  {
    name: "ViewIncidentById",
    path: "/incident-reports/:incidentId",
    element: <ViewIncidentById errorHandler={formErrorHandler} />,
  },
  {
    name: "incidentReports",
    path: "/incident-reports",
    element: <IncidentReportsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "maintenance",
    path: "/maintenance",
    element: <MaintenanceAertsList errorHandler={gridErrorHandler} />,
    companyAdminOnly: true,
  },
  {
    name: "clients",
    path: "/clients",
    element: <ClientsList errorHandler={gridErrorHandler} />,
    globalAdminOnly: true,
  },
  {
    name: "viewClientById",
    path: "/clients/:clientId",
    element: <ViewClientById errorHandler={formErrorHandler} />,
    globalAdminOnly: true,
  },
  {
    name: "users",
    path: "/users",
    element: <UsersList errorHandler={gridErrorHandler} />,
    companyAdminOnly: true,
  },
  {
    name: "viewUserById",
    path: "/users/:userId",
    element: <ViewUserById errorHandler={formErrorHandler} />,
    companyAdminOnly: true,
  },

  {
    name: "displays",
    path: "/displays",
    element: <DisplaysList errorHandler={gridErrorHandler} />,
    globalAdminOnly: true,
  },
  {
    name: "viewDisplayById",
    path: "/displays/:displayId",
    element: <ViewDisplayById errorHandler={formErrorHandler} />,
    companyAdminOnly: true,
  },

  {
    name: "locations",
    path: "/locations",
    element: <LocationsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewLocationById",
    path: "/locations/:locationId",
    element: <ViewLocationById errorHandler={formErrorHandler} />,
  },
  {
    name: "buildings",
    path: "/buildings",
    element: <BuildingsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewBuildingById",
    path: "/buildings/:buildingId",
    element: <ViewBuildingById errorHandler={formErrorHandler} />,
  },
  {
    name: "departments",
    path: "/departments",
    element: <DepartmentsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewDeptById",
    path: "/departments/:departmentId",
    element: <ViewDeptById errorHandler={formErrorHandler} />,
  },
  {
    name: "rooms",
    path: "/rooms",
    element: <RoomsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewRoomById",
    path: "/rooms/:roomId",
    element: <ViewRoomById errorHandler={formErrorHandler} />,
  },
  {
    name: "collectors",
    path: "/collectors",
    element: <CollectorsList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewCollectorById",
    path: "/collectors/:collectorId",
    element: <ViewCollectorById errorHandler={formErrorHandler} />,
  },
  {
    name: "devices",
    path: "/devices",
    element: <DevicesList errorHandler={gridErrorHandler} />,
  },
  {
    name: "ViewDeviceById",
    path: "/devices/:deviceId",
    element: <ViewDeviceById errorHandler={formErrorHandler} />,
  },
  {
    name: "notFound",
    path: "*",
    element: <NotFound />,
  },
];
