import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targetClient: {
    id: null,
    domainName: null,
    name: null,
  },
  user: {},
  userRole: {
    superAdmin: false,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    clearUser: () => initialState,
    setTargetClient: (state, action) => {
      state.targetClient = action.payload;
    },
    setUser: (state, action) => {
      if (action.payload) {
        state.targetClient = {
          id: action.payload.clientObj.id,
          domainName: action.payload.clientObj.domainName,
          name: action.payload.clientObj.name,
        };
        state.user = action.payload;
        state.userRole = {
          ...action.payload.roleObj,
        };
      }
    },
  },
});

export const { clearUser, setTargetClient, setUser } = globalSlice.actions;

export default globalSlice.reducer;
