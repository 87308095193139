import PropTypes from "prop-types";
import { loginRequest, msalInstance, protectedResources } from "../auth/config";
import { store } from "../redux/store";
import { endpoints } from "./types/apiEndpointsEnum";
import { errorHandler } from "../app/errorHandlers";
import { NO_CONTENT, httpErrorCodes } from "../pages/httpError";
import { datetime } from "../utils/utils";
import { fetchCollectionAll } from "../redux/slices/filterOptionsSlice";

const {
  properties,
  ALERTS,
  INCIDENTS,
  CLIENTS,
  USERS,
  LOCATIONS,
  BUILDINGS,
  DEPARTMENTS,
  ROOMS,
  COLLECTORS,
  DEVICES,
  DEVICETYPES,
  ALERTTYPES,
  MAINTENANCE,
  USERNOTIFICATIONS,
  ROLES,
} = endpoints;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";
const API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL || "http://localhost:5000";

/**
 * Gets token prior to an HTTP request.
 * @returns
 * token from MSAL for HTTP requests.
 */
async function getMSALToken() {
  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];
  const tokenRequest = {
    scopes: protectedResources.apiHello.scopes,
    account,
    loginRequest,
  };
  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.idToken;
  } catch (error) {
    try {
      await msalInstance.loginRedirect(tokenRequest);
    } catch (err) {
      errorHandler(err);
    }
  }
  return null;
}

/**
 * Set the headers prior to an HTTP request.
 * @returns
 * appended headers including bearer token and tenant.
 */
async function setHeaders(contentType = null, setContentHeader = true) {
  let contentTypeValue = "application/json";
  if (contentType) contentTypeValue = contentType;
  const headers = new Headers();
  const token = await getMSALToken();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  if (setContentHeader) headers.append("Content-Type", contentTypeValue);
  const targetDomain = store.getState().global.targetClient.domainName;
  if (targetDomain) headers.append("target-domain", targetDomain);
  return headers;
}

async function createFetchOptions({ method, body, signal, contentType = null }) {
  return {
    method,
    headers: await setHeaders(contentType),
    body: body ? JSON.stringify(body) : undefined,
    signal,
  };
}

export function createURL({ api, property, endpoint, queryParams = {} }) {
  let url = `${API_BASE_URL}${properties[property]?.path}`;
  if (api?.localeCompare("admin") === 0) {
    url = `${API_ADMIN_URL}${properties[property]?.path}`;
  }

  if (endpoint) {
    url += `/${endpoint}`;
  }

  Object.entries(queryParams).forEach(([key, value], index) => {
    if (key && value !== null) {
      url += `${index === 0 ? "?" : "&"}${key}=${value}`;
    }
  });

  return url;
}

/**
 * Fetches provided url with provided options.
 * @param url
 * the url for the request.
 * @param options
 * any options for fetch.
 * @param onCancel
 * default value returned if fetch is cancelled.
 * @returns
 * A promise that resolves to 'json' data or an error.
 * If the response is not in the 200 - 399 range the promise is rejected.
 */
export async function fetchJson(url, options, onCancel) {
  try {
    const fetchOptions = await createFetchOptions(options);
    const response = await fetch(url, fetchOptions);
    if (!response.ok) {
      const text = await response.text();
      if (httpErrorCodes.includes(response.status))
        // eslint-disable-next-line
        throw {
          statusCode: response.status,
          errorMesssage: text || `${response.status} - ${response.statusText}`,
          url,
        };
      else throw new Error(text || `${response.status} - ${response.statusText}`);
    }
    return response.status !== NO_CONTENT ? await response.json() : Promise.resolve(onCancel);
  } catch (error) {
    if (error.name !== "AbortError") {
      throw error;
    }
    return Promise.resolve(onCancel);
  }
}

export async function fetchExportData(url, item = null, setSnackbarAlertState = null) {
  const controller = new AbortController();
  const fetchOptions = await createFetchOptions({ method: "GET", signal: controller.signal });
  const fileName = `pinpoint-export-${item?.descriptor}_${datetime
    .formatTimestamp(new Date())
    .replace(/\//g, "-")
    .replace(/:/g, "-")
    .replace(/ /g, "_")}.csv`;
  try {
    await fetch(url, fetchOptions)
      .then((response) => response.blob())
      .then((blob) => {
        if (blob && blob?.type && blob.type !== "text/csv") {
          // eslint-disable-next-line
          throw {
            statusCode: 500,
            errorMesssage: `Internal Server Error - there was an error while processing your request`,
          };
        }
        const urlObj = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlObj;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  } catch (error) {
    if (setSnackbarAlertState) {
      setSnackbarAlertState({
        open: true,
        alertSeverity: "error",
        alertMessage: error.errorMesssage,
      });
    }
  }
}

export async function getAllCollectionsOptions(url) {
  const controller = new AbortController();
  const json = await fetchJson(url, { method: "GET", signal: controller.signal });
  return json;
}

export async function downloadAttachmentFile(url, fileName, setSnackbarAlertState = null) {
  const controller = new AbortController();
  const fetchOptions = await createFetchOptions({ method: "GET", signal: controller.signal });
  try {
    await fetch(url, fetchOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const urlObj = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlObj;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  } catch (error) {
    console.error(error);
  }
}

export async function getAttachments(url) {
  const controller = new AbortController();
  const json = await fetchJson(url, { method: "GET", signal: controller.signal });
  return json;
}

export async function deleteAttachment(buildingId, attachmentPath) {
  const url = createURL({
    property: BUILDINGS,
    endpoint: `${buildingId}/file/remove`,
    queryParams: { filepath: attachmentPath },
  });
  const controller = new AbortController();
  const json = await fetchJson(url, { method: "DELETE", signal: controller.signal });
  return json;
}

export async function getFileAttachmentURL(buildingId, attachmentPath) {
  const url = createURL({
    property: BUILDINGS,
    endpoint: `${buildingId}/file/get`,
    queryParams: { filepath: attachmentPath },
  });
  return url;
}

export async function postAttachmentData(url, attachmentFile, filename, buildingName, setSnackbarAlertState = null) {
  const controller = new AbortController();
  await fetch(url, {
    method: "POST",
    headers: await setHeaders("", false),
    body: attachmentFile,
    signal: controller.signal,
  })
    .then((response) => {
      setSnackbarAlertState({
        open: true,
        alertSeverity: response.status === 200 ? "success" : "error",
        alertMessage: `${filename} uploaded successfully for ${buildingName}.`,
      });
      response.json();
    })
    .catch((error) => {
      errorHandler(error);
      setSnackbarAlertState({
        open: true,
        alertSeverity: "error",
        alertMessage: `Error uploading ${filename}.`,
      });
    });
}

export async function listItemsFromAPI(
  endpoint,
  currentFilterModel,
  currentSortModel,
  currentPageSize,
  offset,
  controller
) {
  // Handle custom filter menu for alerts
  const filterModel = {};
  filterModel.lookupField = [];
  filterModel.lookupValue = [];
  for (let i = 0; i < currentFilterModel.items.length; i += 1) {
    filterModel.lookupField.push(currentFilterModel.items[i].lookupField || currentFilterModel.items[i].field);
    filterModel.lookupValue.push(currentFilterModel.items[i].lookupValue || currentFilterModel.items[i].value);
  }

  try {
    const response = await endpoint.list(
      filterModel.lookupField ? filterModel.lookupField : filterModel.field,
      filterModel.lookupValue ? filterModel.lookupValue : filterModel.value,
      currentSortModel.sortColumn,
      currentSortModel.sortDirection,
      currentPageSize,
      offset,
      controller?.signal
    );
    return response;
  } finally {
    controller?.abort();
  }
}

export async function listAllFromAPI(item, controller) {
  try {
    const response = await item.listAll(controller?.signal);
    return response;
  } catch (error) {
    errorHandler(error);
    return null;
  } finally {
    controller?.abort();
  }
}

export async function listOptionsFromAPI(item, controller) {
  try {
    const response = await item.list(controller?.signal);
    return response;
  } finally {
    controller?.abort();
  }
}

export async function simplelistItemsFromAPI(item, controller, param = null) {
  let response = null;
  try {
    if (![alertTypes, systemAlertTypes, deviceTypes].includes(item)) {
      response =
        param || param?.length === 0
          ? await item.simplelist(param, controller?.signal)
          : await item.simplelist(controller?.signal);
    } else response = await item.list(controller?.signal);
    return response;
  } finally {
    controller?.abort();
  }
}

export async function getItemFromAPI(item, itemId, controller) {
  try {
    const response = await item.get(itemId, controller?.signal);
    return response;
  } finally {
    controller?.abort();
  }
}

export function getAllCollectionURL({ endpoint }) {
  const url = createURL({
    property: endpoint,
    endpoint: "allcollection",
    queryParams: {},
  });
  return url;
}
getAllCollectionURL.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

export async function postItemToAPI(item, newItem, controller) {
  try {
    const response = await item.post(newItem, controller?.signal);
    return response;
  } finally {
    const url = getAllCollectionURL({ endpoint: CLIENTS });
    store.dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
    controller?.abort();
  }
}

export async function patchItemToAPI(item, updatedItem, controller) {
  try {
    const response = await item.patch(updatedItem, controller?.signal);
    return response;
  } finally {
    const url = getAllCollectionURL({ endpoint: CLIENTS });
    store.dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
    controller?.abort();
  }
}

export async function deleteItemFromAPI(item, id, controller) {
  try {
    const response = await item.delete(id, controller?.signal);
    return response;
  } finally {
    controller?.abort();
  }
}

export async function getUserFromAPI(controller) {
  try {
    const response = await user.get(controller?.signal);
    return response;
  } finally {
    controller?.abort();
  }
}

export async function getDashboardFromAPI(item, controller, params) {
  try {
    const response = await item.dashboard(controller?.signal, params);
    return response;
  } finally {
    controller?.abort();
  }
}

const user = {
  async get(signal) {
    const url = `${API_BASE_URL}/user/me`;
    return fetchJson(url, { method: "GET", signal });
  },
};

// Alert calls
export const alerts = {
  descriptor: "alerts",
  lookupFieldNames: ["locationName", "buildingName", "departmentName", "roomName", "deviceName", "alertTypeName"],
  dateFieldNames: ["timestamp"],

  async get(alertId, signal) {
    const url = createURL({ property: ALERTS, endpoint: alertId });
    return fetchJson(url, { method: "GET", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: ALERTS,
      endpoint: "list",
      queryParams,
    });
    return fetchJson(url, { method: "GET", signal });
  },

  async dashboard(signal, queryParams) {
    const url = createURL({ property: ALERTS, endpoint: "dashboard", queryParams });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Incident calls
export const incidents = {
  descriptor: "incident reports",
  requiredFieldNames: [],
  dateFieldNames: ["createdAt", "updatedAt"],

  async post(newIncidentReport, signal) {
    const url = createURL({ property: INCIDENTS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newIncidentReport, signal });
  },

  async patch(updatedIncidentReport, signal) {
    const url = createURL({ property: INCIDENTS, endpoint: updatedIncidentReport._id });
    return fetchJson(url, { method: "PATCH", body: updatedIncidentReport, signal });
  },

  async get(incidentId, signal) {
    const url = createURL({ property: INCIDENTS, endpoint: incidentId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(incidentId, signal) {
    const url = createURL({ property: INCIDENTS, endpoint: incidentId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }
    const url = createURL({
      property: INCIDENTS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },
};

// Maintenance calls
export const maintenance = {
  descriptor: "maintenance",
  lookupFieldNames: [
    "locationName",
    "buildingName",
    "departmentName",
    "roomName",
    "deviceName",
    "deviceAddress",
    "alertTypeName",
  ],
  dateFieldNames: ["timestamp"],

  async get(maintenanceId, signal) {
    const url = createURL({ property: MAINTENANCE, endpoint: maintenanceId });
    return fetchJson(url, { method: "GET", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: MAINTENANCE,
      endpoint: "listsystem",
      queryParams,
    });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Client calls
export const clients = {
  descriptor: "clients",
  requiredFieldNames: ["name", "domainName", "street1", "city", "state", "zipcode", "country"],

  async post(newClient, signal) {
    const url = createURL({ api: "admin", property: CLIENTS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newClient, signal });
  },

  async patch(updatedClient, signal) {
    const url = createURL({ api: "admin", property: CLIENTS, endpoint: updatedClient._id });
    return fetchJson(url, { method: "PATCH", body: updatedClient, signal });
  },

  async get(clientId, signal) {
    const url = createURL({ api: "admin", property: CLIENTS, endpoint: clientId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(clientId, signal) {
    const url = createURL({ property: CLIENTS, endpoint: clientId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      api: "admin",
      property: CLIENTS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },

  async listAll(signal) {
    const url = createURL({ api: "admin", property: CLIENTS, endpoint: "listall" });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Users calls
export const users = {
  descriptor: "users",
  dateFieldNames: ["createdAt", "updatedAt"],
  requiredFieldNames: ["name", "displayName", "email", "role"],

  async get(userId, signal) {
    const url = createURL({ property: USERS, endpoint: userId });
    return fetchJson(url, { method: "GET", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: USERS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },

  async post(newUser, signal) {
    const url = createURL({ property: USERS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newUser, signal });
  },

  async patch(updatedUser, signal) {
    const url = createURL({ property: USERS, endpoint: updatedUser._id });
    return fetchJson(url, { method: "PATCH", body: updatedUser, signal });
  },

  async addLocation(userId, body, signal) {
    const url = createURL({ property: USERS, endpoint: `${userId}/add-location`, queryParams: userId });
    return fetchJson(url, { method: "PATCH", body: { allowedLocations: body }, signal });
  },

  async removeLocation(userId, body, signal) {
    const url = createURL({ property: USERS, endpoint: `${userId}/remove-location`, queryParams: userId });
    return fetchJson(url, { method: "PATCH", body: { allowedLocations: body }, signal });
  },

  async updateLocations(userId, body, signal) {
    const url = createURL({ property: USERS, endpoint: `${userId}/update-locations` });
    return fetchJson(url, { method: "PATCH", body: { allowedLocations: body }, signal });
  },

  async delete(userId, signal) {
    const url = createURL({ property: USERS, endpoint: userId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async validateMobileNumber(request, signal) {
    const url = createURL({ property: USERS, endpoint: "validate-mobilenumber" });
    return fetchJson(url, { method: "POST", body: request, signal });
  },
};

// Location calls
export const locations = {
  descriptor: "locations",
  requiredFieldNames: ["name", "address", "type"],

  async post(newLocation, signal) {
    const url = createURL({ property: LOCATIONS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newLocation, signal });
  },

  async patch(updatedLocation, signal) {
    const url = createURL({ property: LOCATIONS, endpoint: updatedLocation._id });
    return fetchJson(url, { method: "PATCH", body: updatedLocation, signal });
  },

  async get(locationId, signal) {
    const url = createURL({ property: LOCATIONS, endpoint: locationId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(locationId, signal) {
    const url = createURL({ property: LOCATIONS, endpoint: locationId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: LOCATIONS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(signal) {
    const url = createURL({ property: LOCATIONS, endpoint: "simplelist" });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Building calls
export const buildings = {
  descriptor: "buildings",
  requiredFieldNames: ["name", "address", "systemType", "locationName"],
  lookupFieldNames: ["locationName", "collectorName"],

  async post(newBuilding, signal) {
    const url = createURL({ property: BUILDINGS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newBuilding, signal });
  },

  async patch(updatedBuilding, signal) {
    const url = createURL({ property: BUILDINGS, endpoint: updatedBuilding._id });
    return fetchJson(url, { method: "PATCH", body: updatedBuilding, signal });
  },

  async get(buildingId, signal) {
    const url = createURL({ property: BUILDINGS, endpoint: buildingId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(buildingId, signal) {
    const url = createURL({ property: BUILDINGS, endpoint: buildingId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: BUILDINGS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(locationId, signal) {
    const url =
      locationId?.length === 0
        ? createURL({
            property: BUILDINGS,
            endpoint: "simplelist",
          })
        : createURL({
            property: BUILDINGS,
            endpoint: "simplelist",
            queryParams: { locationId },
          });
    return fetchJson(url, { method: "GET", signal });
  },

  async fileImport(buildingId, importFile) {
    await fetch(createURL({ property: BUILDINGS, endpoint: `${buildingId}/file/import` }), {
      method: "POST",
      headers: await setHeaders("", false),
      body: importFile,
      signal: new AbortController().signal,
    })
      .then((response) => response.json())
      .catch((error) => {
        errorHandler(error);
      });
  },
};

// Department calls
export const departments = {
  descriptor: "departments",
  requiredFieldNames: ["name", "locationName", "buildingName", "messageCode"],
  lookupFieldNames: ["locationName", "buildingName", "collectorName"],

  async post(newDepartment, signal) {
    const url = createURL({ property: DEPARTMENTS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newDepartment, signal });
  },

  async patch(updatedDepartment, signal) {
    const url = createURL({ property: DEPARTMENTS, endpoint: updatedDepartment._id });
    return fetchJson(url, { method: "PATCH", body: updatedDepartment, signal });
  },

  async get(departmentId, signal) {
    const url = createURL({ property: DEPARTMENTS, endpoint: departmentId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(departmentId, signal) {
    const url = createURL({ property: DEPARTMENTS, endpoint: departmentId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: DEPARTMENTS,
      endpoint: "list",
      queryParams,
    });
    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(buildingId, signal) {
    const url =
      buildingId?.length === 0
        ? createURL({
            property: DEPARTMENTS,
            endpoint: "simplelist",
          })
        : createURL({
            property: DEPARTMENTS,
            endpoint: "simplelist",
            queryParams: { buildingId },
          });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Room calls
export const rooms = {
  descriptor: "rooms",
  requiredFieldNames: ["name", "locationName", "buildingName", "departmentName", "messageCode"],
  lookupFieldNames: ["locationName", "buildingName", "departmentName"],

  async post(newRoom, signal) {
    const url = createURL({ property: ROOMS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newRoom, signal });
  },

  async patch(updatedRoom, signal) {
    const url = createURL({ property: ROOMS, endpoint: updatedRoom._id });
    return fetchJson(url, { method: "PATCH", body: updatedRoom, signal });
  },

  async get(roomId, signal) {
    const url = createURL({ property: ROOMS, endpoint: roomId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(roomId, signal) {
    const url = createURL({ property: ROOMS, endpoint: roomId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: ROOMS,
      endpoint: "list",
      queryParams,
    });
    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(departmentId, signal) {
    const url =
      departmentId?.length === 0
        ? createURL({
            property: ROOMS,
            endpoint: "simplelist",
          })
        : createURL({
            property: ROOMS,
            endpoint: "simplelist",
            queryParams: { departmentId },
          });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Collector calls
export const collectors = {
  descriptor: "collectors",
  requiredFieldNames: ["name", "detail", "spec", "deployDate", "macAddress"],
  lookupFieldNames: ["locationName", "buildingName", "departmentName"],
  dateFieldNames: ["deployDate"],

  async post(newCollector, signal) {
    const url = createURL({ property: COLLECTORS, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newCollector, signal });
  },

  async patch(updatedCollector, signal) {
    const url = createURL({ property: COLLECTORS, endpoint: updatedCollector._id });
    return fetchJson(url, { method: "PATCH", body: updatedCollector, signal });
  },

  async get(collectorId, signal) {
    const url = createURL({ property: COLLECTORS, endpoint: collectorId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(collectorId, signal) {
    const url = createURL({ property: COLLECTORS, endpoint: collectorId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: COLLECTORS,
      endpoint: "list",
      queryParams,
    });

    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(departmentId, signal) {
    const url =
      departmentId?.length === 0
        ? createURL({
            property: COLLECTORS,
            endpoint: "simplelist",
          })
        : createURL({
            property: COLLECTORS,
            endpoint: "simplelist",
            queryParams: { departmentId },
          });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Device calls
export const devices = {
  descriptor: "devices",
  requiredFieldNames: [
    "deviceAddress",
    "installDate",
    "locationName",
    "buildingName",
    "departmentName",
    "roomName",
    "deviceTypeName",
  ],
  lookupFieldNames: ["locationName", "buildingName", "departmentName", "roomName", "deviceTypeName"],
  dateFieldNames: ["installDate"],

  async post(newDevice, signal) {
    const url = createURL({ property: DEVICES, endpoint: "create" });
    return fetchJson(url, { method: "POST", body: newDevice, signal });
  },

  async patch(updatedDevice, signal) {
    const url = createURL({ property: DEVICES, endpoint: updatedDevice._id });
    return fetchJson(url, { method: "PATCH", body: updatedDevice, signal });
  },

  async get(deviceId, signal) {
    const url = createURL({ property: DEVICES, endpoint: deviceId });
    return fetchJson(url, { method: "GET", signal });
  },

  async delete(deviceId, signal) {
    const url = createURL({ property: DEVICES, endpoint: deviceId });
    return fetchJson(url, { method: "DELETE", signal });
  },

  async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
    const queryParams = {
      sortBy,
      sortDir,
      skip,
      limit,
    };
    // Handles multiple filters for the same column
    for (let i = 0; i < filterColumn.length; i += 1) {
      if (queryParams[filterColumn[i]]) {
        queryParams[filterColumn[i]] += `,${filterValue[i]}`;
      } else {
        queryParams[filterColumn[i]] = filterValue[i];
      }
    }

    const url = createURL({
      property: DEVICES,
      endpoint: "list",
      queryParams,
    });
    return fetchJson(url, { method: "GET", signal });
  },

  async simplelist(signal) {
    const url = createURL({ property: DEVICES, endpoint: "simplelist" });
    return fetchJson(url, { method: "GET", signal });
  },

  async dashboard(signal) {
    const url = createURL({ property: DEVICES, endpoint: "dashboard" });
    return fetchJson(url, { method: "GET", signal });
  },
};

// DeviceType calls
export const deviceTypes = {
  descriptor: "device types",

  async list(signal) {
    const url = createURL({ property: DEVICETYPES, endpoint: "list" });
    return fetchJson(url, { method: "GET", signal });
  },
};

// AlertType calls
export const alertTypes = {
  descriptor: "alert types",

  async list(signal) {
    const url = createURL({ property: ALERTTYPES, endpoint: "list", queryParams: { type: "people alert" } });
    return fetchJson(url, { method: "GET", signal });
  },
};

// SystemAlertType calls
export const systemAlertTypes = {
  descriptor: "alert types",

  async list(signal) {
    const url = createURL({ property: ALERTTYPES, endpoint: "list", queryParams: { type: "system alert" } });
    return fetchJson(url, { method: "GET", signal });
  },
};

// UserNotifications
export const userNotifications = {
  descriptor: "user notifications",

  async list(userId, target, signal) {
    const url = createURL({ property: USERNOTIFICATIONS, endpoint: "list", queryParams: { userId, target } });
    return fetchJson(url, { method: "GET", signal });
  },

  async upsert(userId, data, signal) {
    const url = createURL({ property: USERNOTIFICATIONS, endpoint: "upsert", queryParams: { userId } });
    return fetchJson(url, { method: "POST", body: data, signal });
  },
};

// Roles
export const roles = {
  descriptor: "user roles",

  async list(signal) {
    const url = createURL({ property: ROLES, endpoint: "list" });
    return fetchJson(url, { method: "GET", signal });
  },
};

// Display calls
export const displays = {
  descriptor: "displays",
  requiredFieldNames: ["type", "name", "macAddress"],
  lookupFieldNames: [],
  dateFieldNames: [],

  // async post(newDevice, signal) {
  //   const url = createURL({ property: DISPLAYS, endpoint: "create" });
  //   return fetchJson(url, { method: "POST", body: newDisplay, signal });
  // },

  // async patch(updatedDevice, signal) {
  //   const url = createURL({ property: DISPLAYS, endpoint: updatedDevice._id });
  //   return fetchJson(url, { method: "PATCH", body: updatedDisplay, signal });
  // },

  // async get(deviceId, signal) {
  //   const url = createURL({ property: DISPLAYS, endpoint: displayId });
  //   return fetchJson(url, { method: "GET", signal });
  // },

  // async delete(deviceId, signal) {
  //   const url = createURL({ property: DISPLAYS, endpoint: displayId });
  //   return fetchJson(url, { method: "DELETE", signal });
  // },

  // async list(filterColumn, filterValue, sortBy, sortDir, limit, skip, signal) {
  //   const queryParams = {
  //     sortBy,
  //     sortDir,
  //     skip,
  //     limit,
  //   };
  //   // Handles multiple filters for the same column
  //   for (let i = 0; i < filterColumn.length; i += 1) {
  //     if (queryParams[filterColumn[i]]) {
  //       queryParams[filterColumn[i]] += `,${filterValue[i]}`;
  //     } else {
  //       queryParams[filterColumn[i]] = filterValue[i];
  //     }
  //   }

  //   const url = createURL({
  //     property: DISPLAYS,
  //     endpoint: "list",
  //     queryParams,
  //   });
  //   return fetchJson(url, { method: "GET", signal });
  // },

  // async simplelist(signal) {
  //   const url = createURL({ property: DISPLAYS, endpoint: "simplelist" });
  //   return fetchJson(url, { method: "GET", signal });
  // },
};
