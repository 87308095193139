import React from "react";

function RequiredFieldIndicator() {
  return (
    <div
      style={{
        float: "left",
        fontWeight: "bolder",
        color: "red",
        fontSize: "1rem",
        paddingLeft: "4px",
        opacity: "0.8",
      }}
    >
      *
    </div>
  );
}

export default RequiredFieldIndicator;
