import React, { useEffect, useState } from "react";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { subDays, subMonths } from "date-fns";
import useToggle from "../../hooks/useToggle";
import Main from "../../components/organisms/main/Main";
import BarChart from "../../components/organisms/dashboardCharts/BarChart";

import { getDashboardFromAPI, alerts, devices } from "../../api/index";
import StatBox from "../../components/organisms/dashboardCharts/StatBox";

function Dashboard() {
  const { userRole } = useSelector((state) => state.global);

  const [barChartData, setBarChartData] = useState([]);
  const [barChartLoading, setBarChartLoading] = useState(true);
  const [barChartRangeChecked, toggleBarChartRangeChecked] = useToggle(false);
  const [alertsToday, setAlertsToday] = useState("0");
  const [alertsTodayLoading, setAlertsTodayLoading] = useState(true);
  const [alertsYesterday, setAlertsYesterday] = useState("0");
  const [alertsYesterdayLoading, setAlertsYesterdayLoading] = useState(true);
  const [alertsLast7Days, setAlertsLast7Days] = useState("0");
  const [alertsLast7DaysLoading, setAlertsLast7DaysLoading] = useState(true);
  const [noPollingCount, setNoPollingCount] = useState("0");
  const [noPollingCountLoading, setNoPollingCountLoading] = useState(true);
  const [offlineCount, setOfflineCount] = useState("0");
  const [offlineCountLoading, setOfflineCountLoading] = useState(true);
  const [hasIssueCount, setHasIssueCount] = useState("0");
  const [hasIssueCountLoading, setHasIssueCountLoading] = useState(true);
  const [last7DaysMessage, setLast7DaysMessage] = useState("Between 12:00 AM today and 11:59 PM 7 days ago.");

  // Name of datagram in mongoDB
  const alertDatagrams = ["Panic Alarm", "Assistance Datagram"];
  useEffect(() => {
    setBarChartLoading(true);
    async function loadChartFromAPI() {
      const controller = new AbortController();
      try {
        const today = new Date();
        today.setDate(1);
        const chartResponse = await getDashboardFromAPI(alerts, controller, {
          type: "month",
          alertTypeIds: alertDatagrams.toString(),
          dateRange: `${subMonths(today, barChartRangeChecked ? 12 : 3).toISOString()}~${today.toISOString()}`,
        });
        setBarChartData(chartResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setBarChartLoading(false);
      }
    }

    loadChartFromAPI();
  }, [barChartRangeChecked]);

  useEffect(() => {
    setAlertsTodayLoading(true);
    setAlertsYesterdayLoading(true);
    setAlertsLast7DaysLoading(true);

    async function loadAlertsTodayFromAPI() {
      const controller = new AbortController();
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const alertsTodayResponse = await getDashboardFromAPI(alerts, controller, {
          type: "day",
          dateRange: `${today.toISOString()}~${new Date().toISOString()}`,
        });
        setAlertsToday((alertsTodayResponse[0]?.total || 0).toString());
      } catch (error) {
        console.error(error);
      } finally {
        setAlertsTodayLoading(false);
      }
    }

    async function loadAlertsYesterdayFromAPI() {
      const controller = new AbortController();
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const alertsYesterdayResponse = await getDashboardFromAPI(alerts, controller, {
          type: "day",
          dateRange: `${subDays(today, 1).toISOString()}~${new Date().toISOString()}`,
        });
        setAlertsYesterday((alertsYesterdayResponse[0].total || 0).toString());
      } catch (error) {
        console.error(error);
      } finally {
        setAlertsYesterdayLoading(false);
      }
    }

    async function loadAlertsLast7DaysFromAPI() {
      const controller = new AbortController();
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let alertsLast7DaysTotal = 0;
        const alertsLast7DaysResponse = await getDashboardFromAPI(alerts, controller, {
          type: "day",
          dateRange: `${subDays(today, 7).toISOString()}~${new Date().toISOString()}`,
        });
        alertsLast7DaysResponse.forEach((item) => {
          alertsLast7DaysTotal += item.total;
        });
        setAlertsLast7Days(alertsLast7DaysTotal.toString());
      } catch (error) {
        console.error(error);
      } finally {
        setAlertsLast7DaysLoading(false);
      }
    }

    loadAlertsTodayFromAPI();
    loadAlertsYesterdayFromAPI();
    loadAlertsLast7DaysFromAPI();
  }, []);

  useEffect(() => {
    setNoPollingCountLoading(true);
    setOfflineCountLoading(true);
    setHasIssueCountLoading(true);

    async function loadMaintenanceFromAPI() {
      const controller = new AbortController();
      try {
        const maintenanceAlertsResponse = await getDashboardFromAPI(devices, controller);
        setNoPollingCount((maintenanceAlertsResponse.noPollingDevices || 0).toString());
        setOfflineCount((maintenanceAlertsResponse.offlineDevices || 0).toString());
        setHasIssueCount((maintenanceAlertsResponse.hasIssueDevices || 0).toString());
      } catch (error) {
        console.error(error);
      } finally {
        setNoPollingCountLoading(false);
        setOfflineCountLoading(false);
        setHasIssueCountLoading(false);
      }
    }

    loadMaintenanceFromAPI();
  }, []);

  useEffect(() => {
    const today = new Date();

    setLast7DaysMessage(
      `Between ${subDays(today, 7).toLocaleDateString()} 12:00am and ${today.toLocaleDateString()} 11:59pm.`
    );
  }, []);

  return (
    <Main>
      <Box display="flex" flexDirection="column" height={340} marginBottom={6}>
        <BarChart
          data={barChartData}
          isLoading={barChartLoading}
          alertDatagrams={alertDatagrams}
          header={
            <Box display="flex" justifyContent="space-between" mx={2}>
              <Typography fontWeight="500" fontSize="1.5rem">
                Alerts Over Time
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>4 Months</Typography>
                <Switch checked={barChartRangeChecked} onChange={toggleBarChartRangeChecked} />
                <Typography>12 Months</Typography>
              </Stack>
            </Box>
          }
        />
      </Box>

      <Box>
        <Box mx="auto">
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="2px" borderRadius="10px" overflow="hidden">
            <StatBox
              label="Alerts today"
              dataValue={alertsToday}
              isLoading={alertsTodayLoading}
              pageLink="/alerts?timestamp=today"
              info="Between 12:00 AM and 11:59 PM today."
            />
            <StatBox
              label="Alerts yesterday"
              dataValue={alertsYesterday}
              isLoading={alertsYesterdayLoading}
              pageLink="/alerts?timestamp=yesterday"
              info="Between 12:00 AM and 11:59 PM yesterday."
            />
            <StatBox
              label="Alerts last 7 days"
              dataValue={alertsLast7Days}
              isLoading={alertsLast7DaysLoading}
              pageLink="/alerts?timestamp=pastweek"
              info={last7DaysMessage}
            />
          </Box>
        </Box>
      </Box>

      {userRole.superAdmin && (
        <>
          <Box mt={5}>
            <Typography fontWeight="500" fontSize="1.5rem" mb={1}>
              Device Health
            </Typography>
          </Box>
          <Box>
            <Box mx="auto">
              <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="2px" borderRadius="10px" overflow="hidden">
                <StatBox
                  label="No polling in 24 hours"
                  dataValue={noPollingCount}
                  isLoading={noPollingCountLoading}
                  info="A polling response has not received from the device in the past 24 hours."
                />
                <StatBox
                  label="Offline devices"
                  dataValue={offlineCount}
                  isLoading={offlineCountLoading}
                  info="A maintenance alert was received stating the device is offline."
                />
                <StatBox
                  label="Other issue detected"
                  dataValue={hasIssueCount}
                  isLoading={hasIssueCountLoading}
                  info="A maintenance alert other than an offline alert was received."
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Main>
  );
}

export default Dashboard;
