import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { subDays, subHours, subMonths } from "date-fns";
import { Box, Typography } from "@mui/material";

export function getDate(label) {
  switch (label) {
    case "Last Hour": {
      const today = new Date();
      const lastHour = subHours(today, 1);
      return `${lastHour.toISOString()}~${today.toISOString()}`;
    }
    case "Last 6 Hours": {
      const today = new Date();
      const lastSixHours = subHours(today, 6);
      return `${lastSixHours.toISOString()}~${today.toISOString()}`;
    }
    case "Last 12 Hours": {
      const today = new Date();
      const lastTweleveHours = subHours(today, 12);
      return `${lastTweleveHours.toISOString()}~${today.toISOString()}`;
    }
    case "Today": {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today.toISOString();
    }
    case "Yesterday": {
      const today = new Date();
      const yesterday = subDays(today, 1);
      yesterday.setHours(0, 0, 0, 0);
      return yesterday.toISOString();
    }
    case "Past Week": {
      const today = new Date();
      const pastWeek = subDays(today, 7);
      pastWeek.setHours(0, 0, 0, 0);
      return `${pastWeek.toISOString()}~${today.toISOString()}`;
    }
    case "Past Month": {
      const today = new Date();
      const pastMonth = subMonths(today, 1);
      pastMonth.setHours(0, 0, 0, 0);
      return `${pastMonth.toISOString()}~${today.toISOString()}`;
    }
    case "Past 3 Months": {
      const today = new Date();
      const past3Months = subMonths(today, 3);
      past3Months.setHours(0, 0, 0, 0);
      return `${past3Months.toISOString()}~${today.toISOString()}`;
    }
    case "Past 6 Months": {
      const today = new Date();
      const past6Months = subMonths(today, 6);
      past6Months.setHours(0, 0, 0, 0);
      return `${past6Months.toISOString()}~${today.toISOString()}`;
    }
    default:
      return "";
  }
}
export const dateOptions = [
  { label: "" },
  // Hours group
  { label: "Last Hour", group: "Hours" },
  { label: "Last 6 Hours", group: "Hours" },
  { label: "Last 12 Hours", group: "Hours" },
  // Days group
  {
    label: "Today",
    // value: "Todays date",
    group: "Days",
  },
  { label: "Yesterday", group: "Days" },
  { label: "Past Week", group: "Days" },
  { label: "Past Month", group: "Months" },
  { label: "Past 3 Months", group: "Months" },
  { label: "Past 6 Months", group: "Months" },
  // Custom group
  { label: "Custom Date + Time Range", group: "Pick a set range" },
];

export function DateRangeHint({ dateRange }) {
  const [isNotValid, setIsNotValid] = useState(true);
  useEffect(() => {
    if (new Date(dateRange[0]) > new Date(dateRange[1]) && dateRange[0] !== "" && dateRange[1] !== "") {
      setIsNotValid(false);
    } else setIsNotValid(true);
  }, [dateRange]);

  if (dateRange.length === 0) return null;
  return (
    <Box width="400px">
      {isNotValid ? null : (
        <Typography variant="caption" color="error" sx={{ fontSize: "16px" }}>
          Error: The start date should be earlier than the end date. Please make sure the start date comes before the
          end date to proceed.
        </Typography>
      )}
    </Box>
  );
}

DateRangeHint.propTypes = {
  dateRange: PropTypes.array || PropTypes.string,
};
