import {
  faBuildingColumns,
  faChartSimple,
  faCity,
  faClipboardList,
  faCloudArrowUp,
  faHospital,
  faHospitalUser,
  faHouseMedical,
  faHouseSignal,
  faTriangleExclamation,
  faWrench,
  faUsers,
  faScreencast,
} from "@fortawesome/pro-light-svg-icons";

export const routes = {
  DASHBOARD: 0,
  ALERTS: 1,
  INCIDENTS: 2,
  CLIENTS: 3,
  LOCATIONS: 4,
  BUILDINGS: 5,
  DEPARTMENTS: 6,
  ROOMS: 7,
  DEVICES: 8,
  COLLECTORS: 9,
  USERS: 10,
  MAINTENANCE: 11,
  DISPLAYS: 12,

  properties: {
    0: { path: "/dashboard" },
    1: { path: "/alerts" },
    2: { path: "/incident-reports" },
    3: { path: "/clients" },
    4: { path: "/locations" },
    5: { path: "/buildings" },
    6: { path: "/departments" },
    7: { path: "/rooms" },
    8: { path: "/devices" },
    9: { path: "/collectors" },
    10: { path: "/users" },
    11: { path: "/maintenance" },
    12: { path: "/displays" },
  },

  paths: [
    { index: 0, pathname: "/dashboard" },
    { index: 1, pathname: "/alerts" },
    { index: 2, pathname: "/incident-reports" },
    { index: 3, pathname: "/clients", showAddNew: true },
    { index: 4, pathname: "/locations", showAddNew: true },
    { index: 5, pathname: "/buildings", showAddNew: true },
    { index: 6, pathname: "/departments", showAddNew: true },
    { index: 7, pathname: "/rooms", showAddNew: true },
    { index: 8, pathname: "/devices", showAddNew: true },
    { index: 9, pathname: "/collectors", showAddNew: true },
    { index: 10, pathname: "/users", showAddNew: true },
    { index: 11, pathname: "/maintenance" },
    { index: 12, pathname: "/displays", showAddNew: true },
  ],
};

export const buttons = [
  {
    index: 0,
    path: routes.properties[routes.DASHBOARD].path,
    icon: faChartSimple,
    label: "Dashboard",
  },
  {
    index: 1,
    path: routes.properties[routes.ALERTS].path,
    icon: faTriangleExclamation,
    label: "Alerts",
  },
  {
    index: 2,
    path: routes.properties[routes.INCIDENTS].path,
    icon: faClipboardList,
    label: "Incidents",
  },
  {
    index: 11,
    path: routes.properties[routes.MAINTENANCE].path,
    icon: faWrench,
    label: "Maintenance",
    globalAdminOnly: true,
    companyAdminOnly: true,
  },
  {
    index: "d1",
    divider: true,
  },
  {
    index: 3,
    path: routes.properties[routes.CLIENTS].path,
    icon: faBuildingColumns,
    label: "Clients",
    globalAdminOnly: true,
  },
  {
    index: 10,
    path: routes.properties[routes.USERS].path,
    icon: faUsers,
    label: "Users",
    companyAdminOnly: true,
  },
  {
    index: 12,
    path: routes.properties[routes.DISPLAYS].path,
    icon: faScreencast,
    label: "Displays",
    globalAdminOnly: true,
  },
  {
    index: 4,
    path: routes.properties[routes.LOCATIONS].path,
    icon: faCity,
    label: "Locations",
  },
  {
    index: 5,
    path: routes.properties[routes.BUILDINGS].path,
    icon: faHospital,
    label: "Buildings",
  },
  {
    index: 6,
    path: routes.properties[routes.DEPARTMENTS].path,
    icon: faHospitalUser,
    label: "Departments",
  },
  {
    index: 7,
    path: routes.properties[routes.ROOMS].path,
    icon: faHouseMedical,
    label: "Rooms",
  },
  {
    index: 8,
    path: routes.properties[routes.DEVICES].path,
    icon: faHouseSignal,
    label: "Devices",
  },
  {
    index: 9,
    path: routes.properties[routes.COLLECTORS].path,
    icon: faCloudArrowUp,
    label: "Collectors",
  },
];
