import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns-tz";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { collectors, locations, departments, buildings } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  loadFilterOptionsFromAPI,
  stringOperators,
  dateOperators,
  renderActionMenuCell,
  renderSingleSelectField,
  setColumnOrder,
  lookupFieldsMap,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import CollectorsDataGrid from "../../components/organisms/datagrid/collectorsDataGrid";
import HttpError from "../httpError";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, COLLECTORS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[COLLECTORS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [collectorNameFilterOptions, setCollectorNameFilterOptions] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);
  const [buildingNameFilterOptions, setBuildingNameFilterOptions] = useState([]);
  const [departmentNameFilterOptions, setDepartmentNameFilterOptions] = useState([]);

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "actionMenu", index: 0 },
      { field: "_id", index: 1 },
      { field: "name", index: 2 },
      { field: "departmentName", index: 3 },
      { field: "buildingName", index: 4 },
      { field: "locationName", index: 5 },
      { field: "make", index: 6 },
      { field: "model", index: 7 },
      { field: "serialNumber", index: 8 },
      { field: "macAddress", index: 9 },
      { field: "deployDate", index: 10 },
      { field: "spec", index: 11 },
      { field: "detail", index: 12 },
      { field: "comment", index: 13 },
    ],
    columnWidths: [
      { field: "_id", width: 200 },
      { field: "name", width: 200 },
      { field: "departmentName", width: 200 },
      { field: "buildingName", width: 200 },
      { field: "locationName", width: 200 },
      { field: "make", width: 180 },
      { field: "model", width: 180 },
      { field: "serialNumber", width: 150 },
      { field: "macAddress", width: 120 },
      { field: "deployDate", width: 100 },
      { field: "spec", width: 200 },
      { field: "detail", width: 200 },
      { field: "comment", width: 300 },
    ],
    columnVisibility: {
      _id: false,
      name: true,
      departmentName: true,
      buildingName: true,
      locationName: true,
      make: true,
      model: true,
      serialNumber: true,
      macAddress: true,
      deployDate: true,
      spec: false,
      detail: false,
      comment: false,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(COLLECTORS, userObj?.userRole),
    {
      field: "_id",
      type: "string",
      headerName: "Collector ID",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "_id")[0].width,
      filterOperators: stringOperators,
    },
    renderSingleSelectField(
      "name",
      "Collector",
      collectorNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "name")[0].width
    ),
    renderSingleSelectField(
      "departmentName",
      "Department",
      departmentNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "departmentName")[0].width
    ),
    renderSingleSelectField(
      "buildingName",
      "Building",
      buildingNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "buildingName")[0].width
    ),
    renderSingleSelectField(
      "locationName",
      "Location",
      locationNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "locationName")[0].width
    ),
    {
      field: "make",
      type: "string",
      headerName: "Make ",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "make")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "model",
      type: "string",
      headerName: "Model ",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "model")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "serialNumber",
      type: "string",
      headerName: "Serial Number",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: datagridState.columnWidths.filter((c) => c.field === "serialNumber")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "macAddress",
      type: "string",
      headerName: "MAC Address",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "macAddress")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "deployDate",
      type: "date",
      headerName: "Deploy Date",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "deployDate")[0].width,
      valueFormatter: (param) => format(new Date(param.value), "MM/dd/yyyy"),
      filterOperators: dateOperators,
    },
    {
      field: "spec",
      type: "string",
      headerName: "Collector Specifications",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "spec")[0].width,
      filterable: false,
    },
    {
      field: "detail",
      type: "string",
      headerName: "Collector Details",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "detail")[0].width,
      filterable: false,
    },
    {
      field: "comment",
      type: "string",
      headerName: "Comments",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      filterable: false,
      width: datagridState.columnWidths.filter((c) => c.field === "comment")[0].width,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: collectors,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currFilterModel = localStorage.getItem("currentFilterModel") || defaultFilterModel;
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[COLLECTORS];
    setCurrentFilterModel(currFilterModel);
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    if (collectorNameFilterOptions.length === 0)
      loadFilterOptionsFromAPI(collectors, setCollectorNameFilterOptions, "name");
    if (locationNameFilterOptions.length === 0) loadFilterOptionsFromAPI(locations, setLocationNameFilterOptions);
    if (buildingNameFilterOptions.length === 0) loadFilterOptionsFromAPI(buildings, setBuildingNameFilterOptions);
    if (departmentNameFilterOptions.length === 0) loadFilterOptionsFromAPI(departments, setDepartmentNameFilterOptions);
    setSelectedFilters(defaultSelectedFilters);
  }, []);

  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <CollectorsDataGrid
          columnDefs={orderedColumnDefs}
          rows={itemsToRender}
          rowCount={totalItems}
          paginationModel={currentPaginationModel}
          onPaginationModelChange={(newPaginationModel) => {
            setPreviousPaginationModel(currentPaginationModel);
            setCurrentPaginationModel(newPaginationModel);
          }}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          defaultSelectedFilters={defaultSelectedFilters}
          filterModel={currentFilterModel}
          onSortModelChange={(sortModel) => {
            if (sortModel.length > 0) {
              const newSortModel = { ...currentSortModel };
              if (sortModel[0].field !== currentSortModel.sortColumn) {
                if (!currentSortModel.sortColumn) {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = "asc";
                  setCurrentSortModel(newSortModel);
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  setCurrentSortModel(newSortModel);
                }
              } else {
                newSortModel.sortColumn = sortModel[0].field;
                newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                setCurrentSortModel(newSortModel);
              }
              setResetSortModel(true);
            }
          }}
          currentFilterModel={currentFilterModel}
          handleRefresh={refreshTable}
          handleClearFilters={() =>
            clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
          }
          setSelectedFilters={setSelectedFilters}
          handleExport={null}
          onColumnResize={(colDef) => {
            const newDatagridState = { ...datagridState };
            const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
            newColumnWidth.width = colDef.width;
            setDatagridState(newDatagridState);
          }}
          columnVisibilityModel={datagridState.columnVisibility}
          onColumnVisibilityChange={(newModel) => {
            const newDatagridState = { ...datagridState };
            delete newDatagridState.columnVisibility;
            newDatagridState.columnVisibility = newModel;
            setDatagridState(newDatagridState);
          }}
          onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
        />
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Location", "Building", "Department", "Serial Number", "MAC Address", "Deploy Date"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
