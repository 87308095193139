import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, clients } from "../../api/index";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";
import ClientForm from "../../forms/clientForm";

function View({ errorHandler }) {
  const { clientId } = useParams();
  const [currentClient, setCurrentClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isNewClient = parseInt(clientId) === 0;

  async function loadClientFromAPI(currentClientId, controller) {
    try {
      const response = await getItemFromAPI(clients, currentClientId, controller);
      setCurrentClient(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(!isNewClient);
    isNewClient ? setCurrentClient(null) : loadClientFromAPI(clientId, new AbortController());
  }, [clientId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <ClientForm
              currentClient={currentClient}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentClient(formValues);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
