import React from "react";
import PropTypes from "prop-types";

function Image({ alt, className, id, src }) {
  return <img alt={alt} className={className} id={id} src={src} />;
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.string.isRequired,
};

Image.defaultProps = {
  className: undefined,
  id: undefined,
};

export default Image;
