import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, buildings } from "../api/index";
import { buildingForm } from "./formOptions";
import { routes } from "../nav/nav";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function BuildingForm({ currentBuilding, locationOptions, errorHandler, onFormPost, onFormSubmit }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, BUILDINGS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = buildings;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;

  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    isSaving: false,
    selectedItemChanged: false,
    errorExists: false,
    showUnsavedChangesModal: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedLocation, setAssignedLocation] = useState({
    locationId: currentBuilding?.locationId || "",
    locationName: currentBuilding ? currentBuilding?.locationObj?.name || "" : "",
  });

  const locationNames = [];
  if (locationOptions.length > 0) locationOptions?.map((location) => locationNames.push(location.name));

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [fieldValues.name, fieldValues.address, fieldValues.systemType, assignedLocation.locationName];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
      locationId:
        !fieldValues.locationId && currentBuilding?.locationId
          ? currentBuilding.locationId
          : assignedLocation.locationId,
      locationName: assignedLocation.locationName,
    };
    if (currentBuilding) updatedFieldValues._id = currentBuilding._id;
    return updatedFieldValues;
  }

  const onBuildingFormSubmit = async (values, navigateToList) => {
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;

    try {
      // update this to have the building values from the db
      const updatedBuilding = {
        ...currentBuilding,
        ...values,
      };
      if (currentBuilding) response = await patchItemToAPI(buildings, updatedBuilding, controller);
      else response = await postItemToAPI(buildings, updatedBuilding, controller);
      if (!currentBuilding) {
        setNewRecordId(response.id);
        updatedBuilding._id = response.id;
      }
      if (navigateToList) navigate(`${properties[BUILDINGS].path}`);
      else {
        onFormPost(updatedBuilding);
        onFormSubmit(values.buildingId, formState.selectedItemChanged);
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentBuilding : null
        );
        newSnackbarAlerState.alertSeverity = "success";
        const newFormState = { ...formState };
        newFormState.selectedItemChanged = false;
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        setFormState(newFormState);
      }
    } catch (error) {
      const newFormState = { ...formState };
      newFormState.errorExists = true;
      setFormState(newFormState);
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Room", currentBuilding?._id ? currentBuilding : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      const newFormState = { ...formState };
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  let titleBarText = "";
  if (!currentBuilding?._id) titleBarText = `add new building`;
  else if (userCanUpdate) titleBarText = `view/edit building`;
  else titleBarText = `view building`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Building", currentBuilding?._id ? currentBuilding : null)
            : getItemSucccessMessage("Building", newRecordId.length === 0 ? currentBuilding : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentBuilding?.name || "",
          address: currentBuilding?.address || "",
          description: currentBuilding?.description || "",
          inactive: currentBuilding?.inactive || false,
          comment: currentBuilding?.comment || "",
          locationId: currentBuilding?.locationId || assignedLocation.locationId,
          locationName: currentBuilding?.locationObj?.name || "",
          collectorName: currentBuilding?.collectorObj?.name || "",
          systemType: currentBuilding?.systemType || "",
          siteId: currentBuilding?.siteId || "",
        }}
        onSubmit={(values, helpers) => {
          onBuildingFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[BUILDINGS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onBuildingFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentBuilding !== null} />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentBuilding ? "Enter " : ""}Building Name`}
                          placeholder="Building Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="description"
                          id="description"
                          label={`${!currentBuilding ? "Enter " : ""}Building Description`}
                          placeholder="Building Description"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("description") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          name="comment"
                          id="comment"
                          label={`${!currentBuilding ? "Enter " : ""}Building Comments`}
                          placeholder="Building Comments"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="systemType"
                          id="systemType"
                          label={`${!currentBuilding ? "Select " : ""}System Type`}
                          placeholder="System Types"
                          options={buildingForm.systemTypeOptions}
                          onClick={(e) => {
                            const newFormState = { ...formState };
                            newFormState.errorExists = false;
                            setFormState(newFormState);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("systemType") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          name="address"
                          id="address"
                          label={`${!currentBuilding ? "Enter " : ""}Building Address`}
                          placeholder="Building Address"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("address") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="locationName"
                          id="locationName"
                          label={`${!currentBuilding ? "Select a " : ""}Location`}
                          placeholder="Location Names"
                          options={locationNames}
                          value={
                            locationNames.length > 0 && locationNames.find((l) => l === assignedLocation.locationName)
                              ? assignedLocation.locationName
                              : ""
                          }
                          onChange={(e) => {
                            // Redefining the onChange() event negates Formik setting the 'dirty' flag
                            const selectedLocation = locationOptions.find((l) => l.name === e.target?.value);
                            setAssignedLocation({
                              locationId: selectedLocation._id,
                              locationName: selectedLocation.name,
                            });
                            const newFormState = { ...formState };
                            console.log("onChange", { newFormState });
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            setFormState(newFormState);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("locationName") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="siteId"
                          id="siteId"
                          label={`${
                            !currentBuilding || values.siteId?.trim().length === 0 ? "Enter Building" : "Building"
                          } Site ID`}
                          placeholder="Building Site ID"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("siteId") && <RequiredFieldIndicator />}
                      </div>

                      {currentBuilding && currentBuilding.collectorObj && (
                        <div className="formik-field-readonly">
                          <Field
                            asComponent="text"
                            name="collectorName"
                            id="collectorName"
                            label="Assigned Collector"
                            placeholder="Collector "
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                      )}

                      {currentBuilding && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  const newFormState = { ...formState };
                                  newFormState.errorExists = false;
                                  setFormState(newFormState);
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentBuilding.inactive ? "Yes" : "No"}
                              </div>
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentBuilding && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentBuilding.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentBuilding.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentBuilding}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.selectedItemChanged) ||
                  !userCanUpdate
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[BUILDINGS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

BuildingForm.propTypes = {
  currentBuilding: PropTypes.object,
  locationOptions: PropTypes.array,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

export default BuildingForm;
