import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ALERT_MESSAGE_DURATION_TIME = 2000;
const ALERT_MESSAGE_ANCHOR_ORIGIN = { vertical: "bottom", horizontal: "center" };

function SnackbarAlert({ snackbarOpen, alertSeverity, alertMessage, onSnackbarClose, verticalPos }) {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={ALERT_MESSAGE_DURATION_TIME}
      anchorOrigin={{
        vertical: verticalPos || ALERT_MESSAGE_ANCHOR_ORIGIN.vertical,
        horizontal: ALERT_MESSAGE_ANCHOR_ORIGIN.horizontal,
      }}
      onClose={onSnackbarClose}
    >
      <Alert onClose={onSnackbarClose} severity={alertSeverity} sx={{ width: "100%" }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

SnackbarAlert.propTypes = {
  snackbarOpen: PropTypes.bool,
  alertSeverity: PropTypes.string,
  alertMessage: PropTypes.string,
  onSnackbarClose: PropTypes.func,
  verticalPos: PropTypes.string,
};

export default SnackbarAlert;
