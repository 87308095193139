import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import Main from "../components/organisms/main/Main";

export const NO_CONTENT = 204;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const REQUEST_TIMEOUT = 408;
export const INTERNAL_SERVER_ERROR = 500;
export const SERVICE_UNAVAILABLE = 503;

export const httpErrorCodes = [
  BAD_REQUEST,
  UNAUTHORIZED,
  FORBIDDEN,
  NOT_FOUND,
  REQUEST_TIMEOUT,
  INTERNAL_SERVER_ERROR,
  SERVICE_UNAVAILABLE,
];

const errorMessages = [
  { status: BAD_REQUEST, message: "Bad Request" },
  { status: UNAUTHORIZED, message: "Unauthorized" },
  { status: FORBIDDEN, message: "Forbidden" },
  { status: NOT_FOUND, message: "Not Found" },
  { status: REQUEST_TIMEOUT, message: "Request Timeout" },
  { status: SERVICE_UNAVAILABLE, message: "Service Unavailable" },
];

function HttpError({ httpError }) {
  return (
    <Main>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" style={{ color: "red" }}>
          {httpError.statusCode}
        </Typography>
        <Typography variant="h2">
          {errorMessages.filter((e) => e.status === httpError.statusCode)[0]?.message}
        </Typography>
        <Box sx={{ mt: 0 }}>
          <Typography variant="body2" style={{ color: "darkgrey" }}>
            {httpError.url}
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1">Please use the links to the left to go to a different page.</Typography>
        </Box>
      </Box>
    </Main>
  );
}

HttpError.propTypes = {
  httpError: PropTypes.object,
};

export default HttpError;
