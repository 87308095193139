import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, simplelistItemsFromAPI, departments, locations, buildings } from "../../api/index";
import DepartmentForm from "../../forms/departmentForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { departmentId } = useParams();
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [locationId, setlocationId] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isNewDept = parseInt(departmentId) === 0;

  async function loadLocationOptionsFromAPI(controller) {
    try {
      const response = await simplelistItemsFromAPI(locations, controller, null);
      setLocationOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadBuildingOptionsFromAPI(selectedLocationId, controller) {
    try {
      const response = await simplelistItemsFromAPI(buildings, controller, selectedLocationId);
      setBuildingOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadDepartmentFromAPI(currentDepartmentId, controller) {
    try {
      const response = await getItemFromAPI(departments, currentDepartmentId, controller);
      setCurrentDepartment(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (locationOptions.length === 0) loadLocationOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    if (currentDepartment) loadBuildingOptionsFromAPI(currentDepartment.locationId, new AbortController());
  }, [currentDepartment]);

  useEffect(() => {
    if (locationId) loadBuildingOptionsFromAPI(locationId, new AbortController());
  }, [locationId]);

  useEffect(() => {
    setIsLoading(!isNewDept);
    isNewDept ? setCurrentDepartment(null) : loadDepartmentFromAPI(departmentId, new AbortController());
  }, [departmentId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <DepartmentForm
              currentDepartment={currentDepartment}
              locationOptions={locationOptions}
              buildingOptions={buildingOptions}
              onLocationChange={(selectedLocationId) => {
                setlocationId(selectedLocationId);
              }}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentDepartment(formValues);
              }}
              onFormSubmit={(selectedLocationId, reloadList) => {
                if (reloadList) loadBuildingOptionsFromAPI(selectedLocationId, new AbortController());
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
