import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { Badge } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

export default function GridAttachmentsMenu({ rowId, itemName, attachments, setAttachmentsDialogState = null }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="attachment-menu"
        aria-controls={open ? "attachment menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(null);
          setAttachmentsDialogState({
            open: true,
            buildingName: itemName || "",
            attachments: attachments[itemName] || [],
            buildingId: rowId,
          });
        }}
        title="Attachments"
        style={{ color: "#1976d2" }}
      >
        {attachments[itemName]?.length > 0 ? (
          <Badge badgeContent={attachments[itemName].length} color="error">
            <FolderIcon />
          </Badge>
        ) : (
          <FolderOpenIcon />
        )}
      </IconButton>
    </div>
  );
}

GridAttachmentsMenu.propTypes = {
  rowId: PropTypes.string,
  itemName: PropTypes.string,
  attachments: PropTypes.object,
  setAttachmentsDialogState: PropTypes.func,
};
