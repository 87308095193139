import React from "react";
import PropTypes from "prop-types";

function Modal({ className, onClick }) {
  return (
    <div
      className={className}
      id="modal"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
      aria-label="modal"
    />
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Modal.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default Modal;
