import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, simplelistItemsFromAPI, collectors, locations, buildings, departments } from "../../api/index";
import CollectorForm from "../../forms/collectorForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { collectorId } = useParams();
  const [currentCollector, setCurrentCollector] = useState(null);
  const [newCollectorId, setNewCollectorId] = useState("");
  const [locationId, setlocationId] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isNewCollector = parseInt(collectorId) === 0;

  async function loadLocationOptionsFromAPI(controller) {
    try {
      const response = await simplelistItemsFromAPI(locations, controller, null);
      setLocationOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadBuildingOptionsFromAPI(selectedLocationId, controller) {
    try {
      if (locationId.length === 0) setlocationId(selectedLocationId);
      if (locationId === selectedLocationId) {
        const response = await simplelistItemsFromAPI(buildings, controller, selectedLocationId);
        if (newCollectorId)
          setBuildingOptions(response.filter((b) => b.collectorId === newCollectorId || b.collectorId === null));
        else if (!currentCollector) setBuildingOptions(response.filter((b) => b.collectorId === null));
        else
          setBuildingOptions(response.filter((b) => b.collectorId === currentCollector._id || b.collectorId === null));
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadDepartmentOptionsFromAPI(selectedBuildingId, controller) {
    if (buildingId?.length === 0) setBuildingId(selectedBuildingId);
    try {
      const response = await simplelistItemsFromAPI(departments, controller, selectedBuildingId);
      setDepartmentOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadCollectorFromAPI(currentCollectorId, controller) {
    try {
      const response = await getItemFromAPI(collectors, currentCollectorId, controller);
      setCurrentCollector(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (locationOptions.length === 0) loadLocationOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    if (currentCollector) {
      loadBuildingOptionsFromAPI(currentCollector.buildingObj?.locationObj?._id, new AbortController());
      loadDepartmentOptionsFromAPI(currentCollector.buildingId, new AbortController());
    }
  }, [currentCollector]);

  useEffect(() => {
    if (locationId) loadBuildingOptionsFromAPI(locationId, new AbortController());
  }, [locationId]);

  useEffect(() => {
    if (buildingId) loadDepartmentOptionsFromAPI(buildingId, new AbortController());
  }, [buildingId]);

  useEffect(() => {
    setIsLoading(!isNewCollector);
    isNewCollector ? setCurrentCollector(null) : loadCollectorFromAPI(collectorId, new AbortController());
  }, [collectorId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <CollectorForm
              currentCollector={currentCollector}
              locationOptions={locationOptions}
              buildingOptions={buildingOptions}
              departmentOptions={departmentOptions}
              onLocationChange={(selectedLocationId) => {
                setlocationId(selectedLocationId);
              }}
              onBuildingChange={(selectedBuildingId) => {
                setBuildingId(selectedBuildingId);
              }}
              onDepartmentChange={(selectedDepartmentId) => {
                setDepartmentId(selectedDepartmentId);
              }}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentCollector(formValues);
              }}
              onNewCollector={(id) => {
                setNewCollectorId(id);
              }}
              onFormSubmit={(selectedLocationId, selectedBuildingId, reloadLists) => {
                if (reloadLists) {
                  const controller = new AbortController();
                  loadBuildingOptionsFromAPI(selectedLocationId, controller);
                  loadDepartmentOptionsFromAPI(selectedBuildingId, controller);
                }
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
