import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns-tz";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { datetime, forms } from "../utils/utils";
import Field from "../formikUI/field/Field";
import { postItemToAPI, patchItemToAPI, devices } from "../api/index";
import { routes } from "../nav/nav";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function DeviceForm({
  currentDevice,
  deviceTypeOptions,
  locationOptions,
  buildingOptions,
  departmentOptions,
  roomOptions,
  onLocationChange,
  onBuildingChange,
  onDepartmentChange,
  errorHandler,
  onFormPost,
  onFormSubmit,
  currentFormState,
  setCurrentFormState,
}) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, DEVICES } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = devices;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;

  const [newRecordId, setNewRecordId] = useState("");
  const [lastRecordId, setLastRecordId] = useState("");
  const [exitTemplateMode, setExitTemplateMode] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [formState, setFormState] = useState(currentFormState);

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setIsSaveAndAddNew(isSaveAndAddNew) {
    const newFormState = { ...formState };
    newFormState.isSaveAndAddNew = isSaveAndAddNew;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedLocation, setAssignedLocation] = useState({
    locationId: currentDevice?.locationId || "",
    locationName: currentDevice ? currentDevice?.locationName || "" : "",
  });

  const [assignedBuilding, setAssignedBuilding] = useState({
    buildingId: currentDevice?.buildingId || "",
    buildingName: currentDevice ? currentDevice?.buildingName || "" : "",
  });

  const [assignedDepartment, setAssignedDepartment] = useState({
    departmentId: currentDevice?.departmentId || "",
    departmentName: currentDevice ? currentDevice?.departmentName || "" : "",
  });

  const [assignedRoom, setAssignedRoom] = useState({
    roomId: currentDevice?.roomId || "",
    roomName: currentDevice ? currentDevice?.roomName || "" : "",
  });

  const [assignedDeviceType, setAssignedDeviceType] = useState({
    deviceTypeId: currentDevice ? currentDevice?.deviceTypeId || "" : "",
    deviceTypeName: currentDevice ? currentDevice?.deviceTypeName || "" : "",
  });

  const onDeviceFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    let response = null;
    const controller = new AbortController();
    try {
      // update this to have the device values from the db
      const updatedDevice = {
        ...currentDevice,
        ...values,
      };

      if (currentDevice?._id && !formState.isTemplate) {
        response = await patchItemToAPI(devices, updatedDevice, controller);
        newFormState.isTemplate = values.isTemplate;
        if (values.isTemplate) delete newFormState.currentValues._id;
      } else {
        setLastRecordId(currentDevice?._id);
        delete updatedDevice._id;
        response = await postItemToAPI(devices, updatedDevice, controller);
        delete values.isTemplate;
      }

      if (!currentDevice || formState.isTemplate) {
        setNewRecordId(response.id);
        updatedDevice._id = response.id;
      }
      if (navigateToList) navigate(`${properties[DEVICES].path}`);
      else {
        onFormPost(updatedDevice);
        onFormSubmit(values.locationId, values.buildingId, values.departmentId, formState.selectedItemChanged);
        newFormState.selectedItemChanged = false;
        newFormState.errorExists = false;
        newFormState.showUnsavedChangesModal = false;
        setFormIsDirty(false);
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Device",
          newRecordId.length === 0 ? currentDevice : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newFormState.isAddNew = false;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Device", currentDevice?._id ? currentDevice : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  const locationNames = [];
  if (locationOptions?.length > 0) locationOptions?.map((location) => locationNames.push(location.name));

  const buildingNames = [];
  if (buildingOptions?.length > 0) buildingOptions?.map((building) => buildingNames.push(building.name));

  const departmentNames = [];
  if (departmentOptions?.length > 0) departmentOptions?.map((dept) => departmentNames.push(dept.name));

  const roomNames = [];
  if (roomOptions?.length > 0) roomOptions?.map((room) => roomNames.push(room.name));

  const deviceTypeNames = [];
  if (deviceTypeOptions?.length > 0) deviceTypeOptions?.map((type) => deviceTypeNames.push(type.name));

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [
      fieldValues?.deviceAddress,
      datetime.getISODateStringFromDate(formState.selectedInstallDate),
      assignedLocation.locationName,
      assignedBuilding.buildingName,
      assignedDepartment.departmentName,
      assignedRoom.roomName,
      assignedDeviceType.deviceTypeName,
    ];
  }

  let titleBarText = "";
  if (formState.isSaveAndAddNew || !currentDevice?._id) titleBarText = `add new device`;
  else if (userCanUpdate) titleBarText = `view/edit device`;
  else titleBarText = `view device`;

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
      locationId:
        !fieldValues.locationId && currentDevice?.locationId ? currentDevice.locationId : assignedLocation.locationId,
      locationName: assignedLocation.locationName,
      buildingId:
        !fieldValues.buildingId && currentDevice?.buildingId ? currentDevice.buildingId : assignedBuilding.buildingId,
      buildingName: assignedBuilding.buildingName,
      departmentId:
        !fieldValues.departmentId && currentDevice?.departmentId
          ? currentDevice.departmentId
          : assignedDepartment.departmentId,
      departmentName: assignedDepartment.departmentName,
      roomId: !fieldValues.roomId && currentDevice?.roomId ? currentDevice.roomId : assignedRoom.roomId,
      roomName: assignedRoom.roomName,
      deviceTypeId:
        !fieldValues.deviceTypeId && currentDevice?.deviceTypeId
          ? currentDevice.deviceTypeId
          : assignedDeviceType.deviceTypeId,
      deviceTypeName: assignedDeviceType.deviceTypeName,
      installDate: datetime.getISODateStringFromDate(formState.selectedInstallDate),
    };
    if (currentDevice) updatedFieldValues._id = currentDevice._id;
    return updatedFieldValues;
  }

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={snackbarAlertState.alertSeverity}
        alertMessage={snackbarAlertState.alertMessage}
        onSnackbarClose={() => {
          if (
            currentFormState.isAddNew ||
            (lastRecordId && newRecordId && lastRecordId !== newRecordId && exitTemplateMode)
          )
            navigate(`${properties[DEVICES].path}/${newRecordId}`);
          else {
            setIsSaving(false);
            setNewRecordId("");
            setExitTemplateMode(false);
            setSnackbarAlertState({
              alertMessage: "",
              alertSeverity: "success",
              open: false,
            });
          }
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          deviceAddress: currentDevice?.deviceAddress || "",
          serialNumber: currentDevice?.serialNumber || "",
          partNumber: currentDevice?.partNumber || "",
          inactive: currentDevice?.inactive || false,
          comment: currentDevice?.comment || "",
          locationId: currentDevice?.locationId || assignedLocation.locationId,
          locationName: currentDevice?.locationName || "",
          buildingId: currentDevice?.buildingId || assignedBuilding.buildingId,
          buildingName: currentDevice?.buildingName || "",
          departmentId: currentDevice?.departmentId || assignedDepartment.departmentId,
          departmentName: currentDevice?.departmentName || "",
          roomId: currentDevice?.roomId || assignedRoom.roomId,
          roomName: currentDevice?.roomName || "",
          deviceTypeId: currentDevice?.deviceTypeId || assignedDeviceType.deviceTypeId,
          deviceTypeName: currentDevice?.deviceTypeName || "",
        }}
        onSubmit={(values, helpers) => {
          if (!(!formState.currentValues || !formIsDirty || (!formIsDirty && !forms.selectedItemChanged))) {
            setIsSaving(true);
            onDeviceFormSubmit(getUpdatedFieldValues(values), false);
          }
          if (!formState.errorExists) {
            if (values.isTemplate) {
              values.deviceAddress = "";
              values.serialNumber = "";
              values.comment = "";
              delete values._id;
            }
            helpers.resetForm({
              values,
            });
          }
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[DEVICES].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onDeviceFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                newFormState.currentValues = values;
                setFormState(newFormState);
                setFormIsDirty(true);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentDevice !== null && !formState.isTemplate} />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="deviceAddress"
                          id="deviceAddress"
                          label={`${!currentDevice ? "Enter " : ""}Device Address`}
                          placeholder="Device Address"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("deviceAddress") && <RequiredFieldIndicator />}
                      </div>
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          placeholder="Device Types"
                          label={`${!currentDevice ? "Select " : ""}Device Type`}
                          name="deviceTypeName"
                          id="deviceTypeName"
                          options={deviceTypeNames}
                          value={
                            deviceTypeOptions.length > 0 &&
                            deviceTypeNames.find((r) => r === assignedDeviceType.deviceTypeName)
                              ? assignedDeviceType.deviceTypeName
                              : ""
                          }
                          onChange={(e) => {
                            const selectedDeviceType = deviceTypeOptions.find((d) => d.name === e.target?.value);
                            setAssignedDeviceType({
                              deviceTypeId: selectedDeviceType._id,
                              deviceTypeName: selectedDeviceType.name,
                            });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            newFormState.currentValues = values;
                            setFormState(newFormState);
                            setFormIsDirty(true);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("deviceTypeName") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        {userCanUpdate ? (
                          <Field
                            asComponent="dateField"
                            name="installDate"
                            id="installDate"
                            label="Install Date"
                            selectedDate={formState.selectedInstallDate}
                            onChange={(selectedDate) => {
                              const newFormState = { ...formState };
                              newFormState.selectedInstallDate = selectedDate;
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setCurrentFormState(newFormState);
                              setFormIsDirty(true);
                            }}
                          />
                        ) : (
                          <Field
                            asComponent="text"
                            name="installDate"
                            id="installDate"
                            placeholder="installDate"
                            value={currentDevice ? format(new Date(currentDevice?.installDate), "MMMM d, yyyy") : ""}
                            label="Install Date"
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                        )}
                        {requiredFieldNames.includes("installDate") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="partNumber"
                          id="partNumber"
                          placeholder="Part Number"
                          label={`${!currentDevice ? "Enter " : ""}Part Number`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("partNumber") && <RequiredFieldIndicator />}
                      </div>
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="serialNumber"
                          id="serialNumber"
                          placeholder="Serial Number"
                          label={`${!currentDevice ? "Enter " : ""}Serial Number`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("serialNumber") && <RequiredFieldIndicator />}
                      </div>
                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          name="comment"
                          id="comment"
                          placeholder="Device Comments"
                          label={`${!currentDevice ? "Enter " : ""}Device Comments`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="locationName"
                          id="locationName"
                          label={`${!currentDevice ? "Select a" : ""} Location`}
                          placeholder="Locations"
                          options={locationNames}
                          value={
                            locationNames.length > 0 && locationNames.find((l) => l === assignedLocation.locationName)
                              ? assignedLocation.locationName
                              : ""
                          }
                          onChange={(e) => {
                            const selectedLocation = locationOptions.find((l) => l.name === e.target?.value);
                            setAssignedLocation({
                              locationId: selectedLocation._id,
                              locationName: selectedLocation.name,
                            });
                            onLocationChange(selectedLocation._id);
                            setAssignedBuilding({ buildingId: "", buildingName: "" });
                            setAssignedDepartment({ departmentId: "", departmentName: "" });
                            setAssignedRoom({ roomId: "", roomName: "" });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            newFormState.currentValues = values;
                            setFormState(newFormState);
                            setFormIsDirty(true);
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("locationName") && <RequiredFieldIndicator />}
                      </div>

                      {(currentDevice || assignedLocation.locationId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="buildingName"
                            id="buildingName"
                            label={`${!currentDevice ? "Select a " : ""}Building`}
                            placeholder="Buildings"
                            options={buildingNames}
                            value={
                              buildingNames.length > 0 && buildingNames.find((b) => b === assignedBuilding.buildingName)
                                ? assignedBuilding.buildingName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedBuilding = buildingOptions.find((b) => b.name === e.target?.value);
                              setAssignedBuilding({
                                buildingId: selectedBuilding._id,
                                buildingName: selectedBuilding.name,
                              });
                              onBuildingChange(selectedBuilding._id);
                              setAssignedDepartment({ departmentId: "", departmentName: "" });
                              setAssignedRoom({ roomId: "", roomName: "" });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setFormIsDirty(true);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("buildingName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {(currentDevice || assignedBuilding.buildingId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="departmentName"
                            id="departmentName"
                            label={`${!currentDevice ? "Select a " : ""}Department`}
                            placeholder="Departments"
                            options={departmentNames}
                            value={
                              departmentNames.length > 0 &&
                              departmentNames.find((b) => b === assignedDepartment.departmentName)
                                ? assignedDepartment.departmentName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedDepartment = departmentOptions.find((b) => b.name === e.target?.value);
                              setAssignedDepartment({
                                departmentId: selectedDepartment._id,
                                departmentName: selectedDepartment.name,
                              });
                              onDepartmentChange(selectedDepartment._id);
                              setAssignedRoom({ roomId: "", roomName: "" });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setFormIsDirty(true);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("departmentName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {(currentDevice || assignedDepartment.departmentId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="roomName"
                            id="roomName"
                            label={`${!currentDevice ? "Select a " : ""}Room`}
                            placeholder="Rooms"
                            options={roomNames}
                            value={
                              roomNames.length > 0 && roomNames.find((r) => r === assignedRoom.roomName)
                                ? assignedRoom.roomName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedRoom = roomOptions.find((r) => r.name === e.target?.value);
                              setAssignedRoom({ roomId: selectedRoom._id, roomName: selectedRoom.name });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              newFormState.currentValues = values;
                              setFormState(newFormState);
                              setFormIsDirty(true);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("roomName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {currentDevice && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  if (e !== null && !dirty) {
                                    const newFormState = { ...formState };
                                    newFormState.errorExists = false;
                                    setFormState(newFormState);
                                  }
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentDevice.inactive ? "Yes" : "No"}
                              </div>
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentDevice && !values.isTemplate && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentDevice.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentDevice.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentDevice}
                isTemplate={formState.isTemplate}
                formValues={values}
                buttonDisabled={
                  !formState.currentValues ||
                  !(
                    formState.currentValues && requiredFieldsPopulated(getRequiredFieldValues(formState.currentValues))
                  ) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  !formIsDirty ||
                  (!formIsDirty && !formState.selectedItemChanged) ||
                  !userCanUpdate
                }
                formHasChanges={
                  !(!formState.currentValues || !formIsDirty || (!formIsDirty && !formState.selectedItemChanged))
                }
                requiredFieldsPopulated={requiredFieldsPopulated(getRequiredFieldValues(values))}
                showButtonMenu
                disableButtonMenu={formState.isSaving}
                onSave={(newValues) => {
                  if (
                    !formState.currentValues ||
                    !formIsDirty ||
                    (!formIsDirty && !formState.selectedItemChanged.selectedItemChanged)
                  ) {
                    setSnackbarAlertState({
                      alertMessage: "No changes detected.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else if (!requiredFieldsPopulated(getRequiredFieldValues(values))) {
                    const newFormState = { ...formState };
                    newFormState.isAddNew = false;
                    setFormState(newFormState);
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    setIsSaveAndAddNew(false);
                    setIsSaving(true);
                    setExitTemplateMode(true);
                    delete newValues.isTemplate;
                    onDeviceFormSubmit(getUpdatedFieldValues(newValues), false);
                  }
                }}
                onSaveAndAddNew={(newValues) => {
                  if (!requiredFieldsPopulated(getRequiredFieldValues(values))) {
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    const newFormState = { ...formState };
                    formState.isAddNew = false;
                    formState.isSaveAndAddNew = true;
                    setFormState(newFormState);
                    setExitTemplateMode(false);
                    newValues.isTemplate = true;
                    setCurrentFormState(formState);
                    if (formRef.current) formRef.current.handleSubmit();
                  }
                }}
                onSaveAndClose={(formHasChanges, newValues) => {
                  if (formHasChanges && !requiredFieldsPopulated(getRequiredFieldValues(values))) {
                    setSnackbarAlertState({
                      alertMessage: "All requred fields must be populated.",
                      alertSeverity: "warning",
                      open: true,
                    });
                  } else {
                    setIsSaving(true);
                    if (formHasChanges) onDeviceFormSubmit(getUpdatedFieldValues(newValues), true);
                    else navigate(`${properties[DEVICES].path}`);
                  }
                }}
                pathIndex={currentPath.index}
                formDidChange={formIsDirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  formIsDirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[DEVICES].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

DeviceForm.propTypes = {
  currentDevice: PropTypes.object,
  locationOptions: PropTypes.array,
  buildingOptions: PropTypes.array,
  departmentOptions: PropTypes.array,
  deviceTypeOptions: PropTypes.array,
  roomOptions: PropTypes.array,
  onLocationChange: PropTypes.func,
  onBuildingChange: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
  onFormSubmit: PropTypes.func,
  currentFormState: PropTypes.object,
  setCurrentFormState: PropTypes.func,
};

export default DeviceForm;
