import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const fetchCollectionAll = createAsyncThunk("fetchCollectionAll", async ({ getAllCollectionsOptions, url }) => {
  const allCollectionsOptions = await getAllCollectionsOptions(url);
  const defaultOptions = {};
  let recentOption = null;
  const idLookup = {};
  for (const option in allCollectionsOptions) {
    if (Object.prototype.hasOwnProperty.call(allCollectionsOptions, option)) {
      defaultOptions[`${option}Name`] = [];
      defaultOptions.deviceAddress = [];

      const prevOption = recentOption;
      defaultOptions[option] = allCollectionsOptions[option].map((item) => {
        idLookup[item._id] = item.name;
        const res = { label: item.name, value: item._id };
        const resName = { label: item.name, value: item.name };
        if (prevOption) {
          res.group = idLookup[item[`${prevOption}Id`]];
          resName.group = idLookup[item[`${prevOption}Id`]];
          if (option === "device") {
            defaultOptions.deviceAddress.push({
              label: item.deviceAddress,
              value: item.deviceAddress,
              group: idLookup[item[`${prevOption}Id`]],
            });
          }
        }

        if (option !== "device") defaultOptions[`${option}Name`].push(resName);
        return res;
      });
      recentOption = option;
    }
  }
  return { allCollectionsOptions, defaultOptions, idLookup };
});
const filterOptionsSlice = createSlice({
  name: "filterOptions",
  initialState: {
    initialState: {
      isLoading: false,
      allCollectionOptions: null,
      defaultOptions: null,
      idLookup: null,
      isError: false,
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionAll.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCollectionAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allCollectionOptions = action.payload.allCollectionsOptions;
      state.defaultOptions = action.payload.defaultOptions;
      state.idLookup = action.payload.idLookup;
    });
    builder.addCase(fetchCollectionAll.rejected, (state, action) => {
      console.error("Error", action);
      state.isLoading = false;
      state.isError = true;
    });
  },

  reducers: {
    updateFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
  },
});

export const { updateFilterOptions } = filterOptionsSlice.actions;
export default filterOptionsSlice.reducer;
