export const endpoints = {
  ALERTS: 1,
  INCIDENTS: 2,
  CLIENTS: 3,
  LOCATIONS: 4,
  BUILDINGS: 5,
  DEPARTMENTS: 6,
  ROOMS: 7,
  DEVICES: 8,
  COLLECTORS: 9,
  USERS: 10,
  MAINTENANCE: 11,
  DEVICETYPES: 12,
  ALERTTYPES: 13,
  FILEIMPORT: 14,
  USERNOTIFICATIONS: 15,
  ROLES: 16,

  properties: {
    1: { path: "/alert" },
    2: { path: "/incidentreport" },
    3: { path: "/client" },
    4: { path: "/location" },
    5: { path: "/building" },
    6: { path: "/department" },
    7: { path: "/room" },
    8: { path: "/device" },
    9: { path: "/collector" },
    10: { path: "/user" },
    11: { path: "/alert" },
    12: { path: "/devicetype" },
    13: { path: "/alerttype" },
    14: { path: "/fileimport" },
    15: { path: "/notificationsubscription" },
    16: { path: "/role" },
  },
};
