import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faAdd } from "@fortawesome/pro-solid-svg-icons";

function FormikFormTitleBar({ titleText, editMode, icon = null }) {
  let titleIcon = icon;
  if (!titleIcon) titleIcon = editMode ? faEdit : faAdd;

  return (
    <div id="form-title-bar">
      <div id="form-title-text">
        <FontAwesomeIcon icon={titleIcon} style={{ marginRight: "12px", opacity: "0.9" }} />
        {titleText}
      </div>
    </div>
  );
}

FormikFormTitleBar.propTypes = {
  icon: PropTypes.object,
  titleText: PropTypes.string,
  editMode: PropTypes.bool,
};

export default FormikFormTitleBar;
