import React from "react";
import PropTypes from "prop-types";
import {
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  GridPagination,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";

const getPageCount = (rowCount, pageSize) => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }

  return 0;
};

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
  const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);
  // console.log("🚀 ~ file: gridPaginationDef.js:9 ~ Pagination ~ pageCount:", pageCount);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      size="small"
      shape="rounded"
      sx={{
        "& .MuiPaginationItem-root": {
          lineHeight: "normal",
        },
      }}
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

function gridPaginationDef(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default gridPaginationDef;
