import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/pro-solid-svg-icons";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { strings } from "../../utils/utils";
import {
  buildings,
  collectors,
  fetchExportData,
  postAttachmentData,
  getAttachments,
  buildings as buildingsItem,
} from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  deleteRow,
  loadFilterOptionsFromAPI,
  stringOperators,
  booleanOperators,
  renderActionMenuCell,
  renderBooleanCell,
  renderSingleSelectField,
  getExportURL,
  setColumnOrder,
  lookupFieldsMap,
  uploadAttachmentURL,
  applyOptions,
  renderAttachmentsMenuCell,
  getAttachmentsURL,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import BuildingsDataGrid from "../../components/organisms/datagrid/buildingsDataGrid";
import HttpError from "../httpError";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";
import ImportMsgFileDialog from "../../materialUI/dialog/importMsgFileDialog";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";
import AttachmentsDialog from "../../materialUI/dialog/AttachmentsDialog";

const { sortModel: defaultSortModel, BUILDINGS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[BUILDINGS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [snackbarAlertState, setSnackbarAlertState] = React.useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    let defaultFilters = {};
    if (gridColumnDefs) {
      defaultFilters = gridColumnDefs.reduce((acc, curr) => {
        let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
        if (lookupField.length !== 0) {
          lookupField = lookupField[0].lookupField;
        } else lookupField = curr.field;

        if (curr.type === "date") {
          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          acc[lookupField] = {
            dateRange: ["", endDate.toISOString()],
            date: { label: "" },
          };
        } else acc[lookupField] = [];

        return acc;
      }, {});
    }
    return defaultFilters;
  };
  const defaultOptions = useSelector((state) => state.filterOptions.defaultOptions);
  const allCollectionOptions = useSelector((state) => state.filterOptions.allCollectionOptions);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);
  const [buildingNameFilterOptions, setBuildingNameFilterOptions] = useState([]);
  const [collectorNameFilterOptions, setCollectorNameFilterOptions] = useState([]);
  const [importMsgFileDialagState, setImportMsgFileDialogState] = useState({ open: false, buildingName: "" });
  const [attachmentsDialogState, setAttachmentsDialogState] = useState({
    open: false,
    buildingName: "",
    attachments: [],
    buildingId: "",
  });
  const [attachments, setAttachments] = useState(null);
  // used for attachments
  const [buildingList, setBuildingList] = useState([]);
  const [gridColumnDefs, setGridColumnDefs] = useState(null);

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "name", index: 0 },
      { field: "locationName", index: 1 },
      { field: "address", index: 2 },
      { field: "siteId", index: 3 },
      { field: "systemType", index: 4 },
      { field: "collectorName", index: 5 },
      { field: "description", index: 6 },
      { field: "comment", index: 7 },
    ],
    columnWidths: [
      { field: "name", width: 250 },
      { field: "locationName", width: 230 },
      { field: "address", width: 400 },
      { field: "siteId", width: 100 },
      { field: "systemType", width: 110 },
      { field: "collectorName", width: 200 },
      { field: "description", width: 290 },
      { field: "comment", width: 400 },
    ],
    columnVisibility: {
      name: true,
      locationName: true,
      address: true,
      siteId: false,
      systemType: false,
      collectorName: true,
      description: false,
      comment: false,
    },
  });

  const orderedColumnDefs = [];
  useEffect(() => {
    const allowedDrillFilterFields = ["locationId", "buildingId"];
    const optionMap = {
      building: [buildingNameFilterOptions, setBuildingNameFilterOptions],
    };
    // filter field is name instead of buildingId
    const pageName = "building";
    if (allCollectionOptions) {
      applyOptions(
        allowedDrillFilterFields,
        selectedFilters,
        allCollectionOptions,
        defaultOptions,
        optionMap,
        pageName
      );
    }
  }, [selectedFilters]);

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: buildings,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[BUILDINGS];
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
  }, []);

  const popAttachments = async () => {
    const response = {};

    try {
      const requests = buildingList.map(async (building) => {
        const url = getAttachmentsURL({ endpoint: BUILDINGS }, building.value);
        return getAttachments(url);
      });
      const values = await Promise.all(requests);
      const resultMap = {};
      buildingList.forEach((key, index) => {
        resultMap[key.label] = values[index];
      });
      setAttachments(resultMap);
    } catch (error) {
      console.error("popAttachments() requests failed:  ", { error });
    }
    return response;
  };
  useEffect(() => {
    if (attachments) {
      const gridColDefs = [
        renderActionMenuCell(
          BUILDINGS,
          userObj?.userRole,
          setShowDeleteConfirm,
          setImportMsgFileDialogState,
          setAttachmentsDialogState
        ),
        userObj?.userRole.superAdmin ? renderAttachmentsMenuCell(attachments, setAttachmentsDialogState) : null,
        renderSingleSelectField(
          "name",
          "Building",
          buildingNameFilterOptions,
          datagridState.columnWidths.filter((c) => c.field === "name")[0].width
        ),
        renderSingleSelectField(
          "locationName",
          "Location",
          locationNameFilterOptions,
          datagridState.columnWidths.filter((c) => c.field === "locationName")[0].width
        ),
        {
          field: "address",
          type: "string",
          headerName: "Address",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-cell",
          width: datagridState.columnWidths.filter((c) => c.field === "address")[0].width,
          filterOperators: stringOperators,
        },
        {
          field: "siteId",
          type: "string",
          headerName: "Site ID",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-cell",
          width: datagridState.columnWidths.filter((c) => c.field === "siteId")[0].width,
          filterOperators: stringOperators,
        },
        {
          field: "systemType",
          type: "string",
          headerName: "System Type",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-plaintext-cell",
          width: datagridState.columnWidths.filter((c) => c.field === "systemType")[0].width,
          filterOperators: stringOperators,
        },
        renderSingleSelectField(
          "collectorName",
          "Assigned Collector",
          collectorNameFilterOptions,
          datagridState.columnWidths.filter((c) => c.field === "collectorName")[0].width
        ),
        {
          field: "description",
          type: "string",
          headerName: "Description",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-plaintext-cell",
          width: datagridState.columnWidths.filter((c) => c.field === "description")[0].width,
          filterable: false,
          hideable: true,
        },
        {
          field: "comment",
          type: "string",
          headerName: "Comments",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-plaintext-cell",
          filterable: false,
          width: datagridState.columnWidths.filter((c) => c.field === "comment")[0].width,
          hideable: true,
        },
        {
          field: "inactive",
          type: "boolean",
          headerName: "Inactive",
          headerClassName: "data-grid-column-header",
          cellClassName: "data-grid-cell",
          headerAlign: "center",
          align: "center",
          width: 100,
          renderCell: (cellValues) => renderBooleanCell(cellValues.row.inactive),
          filterOperators: booleanOperators,
          resizable: false,
        },
      ];
      // conditional attachments column return null
      const filteredGridColDefs = gridColDefs.filter((element) => element !== null);
      setGridColumnDefs(filteredGridColDefs);
    }
  }, [attachments]);
  useEffect(() => {
    if (gridColumnDefs) {
      datagridState.columnOrder.map((c, i) => {
        const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
        const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
        if (nextField) orderedColumnDefs.push(nextField);
        return true;
      });
      setSelectedFilters(defaultSelectedFilters());
    }
  }, [gridColumnDefs]);
  useEffect(() => {
    if (defaultOptions) {
      setLocationNameFilterOptions(defaultOptions.location);
      setBuildingNameFilterOptions(defaultOptions.buildingName);
    }
    if (collectorNameFilterOptions.length === 0) loadFilterOptionsFromAPI(collectors, setCollectorNameFilterOptions);
    if (buildingList.length === 0) loadFilterOptionsFromAPI(buildingsItem, setBuildingList);
  }, [defaultOptions]);
  useEffect(() => {
    if (buildingList.length > 0) {
      popAttachments();
    } else setAttachments({});
  }, [buildingList]);
  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading || !gridColumnDefs ? (
        <Spinner />
      ) : (
        <>
          <AttachmentsDialog
            popAttachments={popAttachments}
            attachments={attachments}
            attachmentsDialogState={attachmentsDialogState}
            setAttachmentsDialogState={setAttachmentsDialogState}
            onNewFiles={async (files) => {
              if (files.length > 0) {
                try {
                  const uploadURL = uploadAttachmentURL({ endpoint: BUILDINGS }, attachmentsDialogState.buildingId);
                  for await (const file of files) {
                    const formData = new FormData();
                    formData.append("uploadFile", file);
                    await postAttachmentData(
                      uploadURL,
                      formData,
                      file.name,
                      attachmentsDialogState.buildingName,
                      setSnackbarAlertState
                    );
                  }
                } catch (error) {
                  console.error("Attachments Dialog", { error });
                }
              }
            }}
          />
          <ImportMsgFileDialog
            buildingId={selectedRow?._id}
            dialogState={importMsgFileDialagState}
            setImportMsgFileDialogState={(state) => setImportMsgFileDialogState(state)}
            onSelectImportFile={async (importForm) => {
              if (importForm) {
                const newSnackbarAlertState = { open: true };
                try {
                  buildings.fileImport(selectedRow?._id, new FormData(importForm));
                  newSnackbarAlertState.alertSeverity = "success";
                  newSnackbarAlertState.alertMessage = `${importForm?.importFile.files[0].name} uploaded successfully for ${selectedRow.name}.`;
                } catch (error) {
                  newSnackbarAlertState.alertSeverity = "error";
                  newSnackbarAlertState.alertMessage = `Error uploading ${importForm?.importFile.files[0].name}.`;
                } finally {
                  setSnackbarAlertState(newSnackbarAlertState);
                }
              }
            }}
          />
          <Dialog
            open={showDeleteConfirm}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <FontAwesomeIcon icon={faHospital} style={{ marginRight: "8px", opacity: "0.8", color: "#ff5e14" }} />
              {selectedRow?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {strings.getDeleteConfirmMessage("Building")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  setIsLoading(true);
                  deleteRow(buildings, selectedRow._id, setIsLoading, refreshTable, setSnackbarAlertState);
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <BuildingsDataGrid
            columnDefs={gridColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            setSelectedFilters={setSelectedFilters}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedRow={(row) => setSelectedRow(row)}
            handleExport={() => {
              fetchExportData(
                getExportURL({ currentFilterModel, currentSortModel, endpoint: BUILDINGS }),
                buildings,
                setSnackbarAlertState
              );
            }}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      {gridColumnDefs && (
        <GridToolbarCustomFilterPanel
          validCols={["Location", "Building", "Address", "Inactive"]}
          columnDefs={gridColumnDefs}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          defaultSelectedFilters={defaultSelectedFilters}
          setCurrentFilterModel={setCurrentFilterModel}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
