import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { msalInstance } from "./auth/config";
import { store, persistor } from "./redux/store";

import "./css/styles.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Router>
    </PersistGate>
  </Provider>
);
