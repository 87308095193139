import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function NavigationLink({ children, path }) {
  return (
    <NavLink className={(navData) => (navData.isActive ? "active" : "")} to={path}>
      {children}
    </NavLink>
  );
}

NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default NavigationLink;
