import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { store } from "../redux/store";
import { clearUser, setUser } from "../redux/slices/globalSlice";
import { getUserFromAPI } from "../api/index";

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SIGNIN",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://pinpointcloud.b2clogin.com/pinpointcloud.onmicrosoft.com/B2C_1_SIGNIN",
    },
  },
  authorityDomain: "pinpointcloud.b2clogin.com",
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: "9ee49bea-cf99-4f70-bb91-13bc75467b5e",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
    store.dispatch(setUser(await getUserFromAPI(new AbortController())));
  }
});

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    msalInstance.setActiveAccount(null);
    store.dispatch(clearUser());
  }
});

// msalInstance.addEventCallback((event) => {
//   console.log(event);
// });

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiHello: {
    endpoint:
      "https://pinpointcloud-backend.azurewebsites.net/api/v1/packetinfo/listquery?filterBy=validtype&filterVal=polling%20request%2Cintelligent%20reset&sortBy=packet_timestamp&sortVal=desc&pageNum=1",
    scopes: ["https://pinpointcloud.onmicrosoft.com/helloapi/demo.read"], // e.g. api://xxxxxx/access_as_user
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.apiHello.scopes],
};

export const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "pinpointcloud.b2clogin.com",
};
