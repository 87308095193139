import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarExclamation,
  faTriangleExclamation,
  faWifi,
  faWifiExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@mui/material";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { devices, deviceTypes, fetchExportData } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  loadFilterOptionsFromAPI,
  stringOperators,
  booleanOperators,
  dateOperators,
  renderActionMenuCell,
  renderBooleanCell,
  renderSingleSelectField,
  getExportURL,
  setColumnOrder,
  lookupFieldsMap,
  applyOptions,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import DevicesDataGrid from "../../components/organisms/datagrid/devicesDataGrid";
import HttpError from "../httpError";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, DEVICES } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[DEVICES]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [roomNameFilterOptions, setRoomNameFilterOptions] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };
  const defaultOptions = useSelector((state) => state.filterOptions.defaultOptions);
  const allCollectionOptions = useSelector((state) => state.filterOptions.allCollectionOptions);
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);
  const [buildingNameFilterOptions, setBuildingNameFilterOptions] = useState([]);
  const [departmentNameFilterOptions, setDepartmentNameFilterOptions] = useState([]);
  const [deviceAddressFilterOptions, setDeviceAddressFilterOptions] = useState([]);
  const [deviceTypeFilterOptions, setDeviceTypeFilterOptions] = useState([]);
  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "actionMenu", index: 0 },
      { field: "deviceStatus", index: 1 },
      { field: "deviceAddress", index: 2 },
      { field: "deviceTypeName", index: 3 },
      { field: "roomName", index: 4 },
      { field: "departmentName", index: 5 },
      { field: "buildingName", index: 6 },
      { field: "locationName", index: 7 },
      { field: "messageLine1", index: 8 },
      { field: "messageLine2", index: 9 },
      { field: "deviceName", index: 10 },
      { field: "partNumber", index: 11 },
      { field: "serialNumber", index: 12 },
      { field: "installDate", index: 13 },
    ],
    columnWidths: [
      { field: "actionMenu", width: 70 },
      { field: "deviceStatus", width: 80 },
      { field: "deviceAddress", width: 140 },
      { field: "deviceTypeName", width: 160 },
      { field: "roomName", width: 200 },
      { field: "departmentName", width: 200 },
      { field: "buildingName", width: 200 },
      { field: "locationName", width: 200 },
      { field: "messageLine1", width: 150 },
      { field: "messageLine2", width: 150 },
      { field: "partNumber", width: 200 },
      { field: "serialNumber", width: 200 },
      { field: "installDate", width: 100 },
    ],
    columnVisibility: {
      deviceAddress: false,
      deviceTypeName: true,
      roomName: true,
      departmentName: true,
      buildingName: true,
      locationName: true,
      messageLine1: true,
      messageLine2: true,
      deviceName: true,
      partNumber: true,
      serialNumber: false,
      installDate: false,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(DEVICES, userObj?.userRole),
    {
      field: "deviceStatus",
      type: "string",
      headerName: "Status",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        const { isOffline, issueDetected, lastPollingResponse } = cellValues.row;
        let noPolling = false;
        if (!lastPollingResponse) {
          noPolling = true;
        } else {
          const lastPollingResponseDate = new Date(lastPollingResponse);
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);

          if (lastPollingResponseDate.getTime() < yesterday.getTime()) noPolling = true;
        }

        if (isOffline || issueDetected || noPolling) {
          return (
            <>
              {noPolling && !isOffline && (
                <Tooltip title="No Polling in 24 Hours">
                  <FontAwesomeIcon icon={faCalendarExclamation} color="red" style={{ fontSize: "1rem" }} />
                </Tooltip>
              )}
              {isOffline && (
                <Tooltip title="Offline">
                  <FontAwesomeIcon icon={faWifiExclamation} color="red" style={{ fontSize: "1rem" }} />
                </Tooltip>
              )}
              {issueDetected && (
                <Tooltip title="Other Issue Detected">
                  <FontAwesomeIcon icon={faTriangleExclamation} color="red" style={{ fontSize: "1rem" }} />
                </Tooltip>
              )}
            </>
          );
        }

        return (
          <Tooltip title="Online">
            <FontAwesomeIcon icon={faWifi} color="green" style={{ marginRight: "", fontSize: "1rem" }} />
          </Tooltip>
        );
      },
    },
    renderSingleSelectField(
      "deviceAddress",
      "Device Address",
      deviceAddressFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceAddress")[0].width
    ),
    renderSingleSelectField(
      "deviceTypeName",
      "Device Type",
      deviceTypeFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceTypeName")[0].width
    ),
    renderSingleSelectField(
      "roomName",
      "Room",
      roomNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "roomName")[0].width
    ),
    renderSingleSelectField(
      "departmentName",
      "Department",
      departmentNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "departmentName")[0].width
    ),
    renderSingleSelectField(
      "buildingName",
      "Building",
      buildingNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "buildingName")[0].width
    ),
    renderSingleSelectField(
      "locationName",
      "Location",
      locationNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "locationName")[0].width
    ),
    {
      field: "messageLine1",
      type: "string",
      headerName: "Dept. Message Line",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "messageLine1")[0].width,
      filterOperators: stringOperators,
      renderCell: (cellValues) => cellValues.row.messageLine1?.toUpperCase(),
    },
    {
      field: "messageLine2",
      type: "string",
      headerName: "Room Message Line",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "messageLine2")[0].width,
      filterOperators: stringOperators,
      renderCell: (cellValues) => cellValues.row.messageLine2?.toUpperCase(),
    },
    {
      field: "partNumber",
      type: "string",
      headerName: "Part Number",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: datagridState.columnWidths.filter((c) => c.field === "partNumber")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "serialNumber",
      type: "string",
      headerName: "Serial Number",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: datagridState.columnWidths.filter((c) => c.field === "serialNumber")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "installDate",
      type: "date",
      headerName: "Install Date",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "installDate")[0].width,
      valueFormatter: (param) => format(new Date(param.value), "MM/dd/yyyy"),
      filterOperators: dateOperators,
    },
    {
      field: "inactive",
      type: "boolean",
      headerName: "Inactive",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (cellValues) => renderBooleanCell(cellValues.row.inactive),
      filterOperators: booleanOperators,
      resizable: false,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });
  useEffect(() => {
    // not device Address because it does not exist on the table device does so use deviceId
    const allowedDrillFilterFields = ["locationId", "buildingId", "departmentId", "roomId", "deviceAddressId"];
    const optionMap = {
      building: [buildingNameFilterOptions, setBuildingNameFilterOptions],
      department: [departmentNameFilterOptions, setDepartmentNameFilterOptions],
      room: [roomNameFilterOptions, setRoomNameFilterOptions],
      deviceAddress: [deviceAddressFilterOptions, setDeviceAddressFilterOptions],
    };
    // filter field is name instead of deviceAddress of deviceAddressId
    const pageName = "deviceAddress";
    if (allCollectionOptions) {
      applyOptions(
        allowedDrillFilterFields,
        selectedFilters,
        allCollectionOptions,
        defaultOptions,
        optionMap,
        pageName
      );
    }
  }, [selectedFilters]);

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: devices,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currFilterModel = localStorage.getItem("currentFilterModel") || defaultFilterModel;
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[DEVICES];
    setCurrentFilterModel(currFilterModel);
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (defaultOptions) {
      setLocationNameFilterOptions(defaultOptions.location);
      setBuildingNameFilterOptions(defaultOptions.building);
      setDepartmentNameFilterOptions(defaultOptions.department);
      setRoomNameFilterOptions(defaultOptions.roomName);
      setDeviceAddressFilterOptions(defaultOptions.deviceAddress);
      if (deviceTypeFilterOptions.length === 0) loadFilterOptionsFromAPI(deviceTypes, setDeviceTypeFilterOptions);
    }
  }, [defaultOptions]);
  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <DevicesDataGrid
            columnDefs={orderedColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedFilters={setSelectedFilters}
            handleExport={() => {
              fetchExportData(
                getExportURL({ currentFilterModel, currentSortModel, endpoint: DEVICES }),
                devices,
                setSnackbarAlertState
              );
            }}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      <GridToolbarCustomFilterPanel
        validCols={[
          "Status",
          "Location",
          "Building",
          "Department",
          "Room",
          "Device Address",
          "Dept. Message Line",
          "Room Message Line",
          "Install Date",
          "Inactive",
        ]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
