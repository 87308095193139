import React from "react";
import PropTypes from "prop-types";

function Main({ children, id }) {
  return <main id={id}>{children}</main>;
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Main.defaultProps = {
  id: undefined,
};

export default Main;
