import React from "react";
import PropTypes from "prop-types";

import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

function StatBox({ label, dataValue, isLoading, info, pageLink = "#" }) {
  return isLoading ? (
    <Skeleton variant="rounded" width="100%" height="100%" />
  ) : (
    <a href={pageLink}>
      <Box px="2rem" py="1.5rem" backgroundColor="#ffffff">
        <Typography fontWeight="500" fontSize="0.875rem" lineHeight="1.5rem" color="#808080">
          {label}
          {info && (
            <>
              &nbsp;
              <Tooltip title={info} placement="top">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </>
          )}
        </Typography>
        <Typography mt="4px" fontWeight="600" fontSize="2.5rem" lineHeight="2.5rem">
          {dataValue}
        </Typography>
      </Box>
    </a>
  );
}

StatBox.propTypes = {
  label: PropTypes.string.isRequired,
  dataValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  info: PropTypes.string,
  pageLink: PropTypes.string,
};

export default StatBox;
