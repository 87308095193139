import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ClientsDataGrid from "../../components/organisms/datagrid/clientsDataGrid";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { clients } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  stringOperators,
  booleanOperators,
  renderActionMenuCell,
  renderBooleanCell,
  lookupFieldsMap,
  setColumnOrder,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import HttpError from "../httpError";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, CLIENTS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[CLIENTS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const [previousFilterModel, setPreviousFilterModel] = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "actionMenu", index: 0 },
      { field: "name", index: 1 },
      { field: "domainName", index: 2 },
      { field: "street1", index: 3 },
      { field: "street2", index: 4 },
      { field: "city", index: 5 },
      { field: "state", index: 6 },
      { field: "zipcode", index: 7 },
      { field: "country", index: 8 },
      { field: "inactive", index: 9 },
    ],
    columnWidths: [
      { field: "name", width: 300 },
      { field: "domainName", width: 300 },
      { field: "street1", width: 200 },
      { field: "street2", width: 200 },
      { field: "city", width: 200 },
      { field: "state", width: 80 },
      { field: "zipcode", width: 80 },
      { field: "country", width: 80 },
      { field: "inactive", width: 80 },
    ],
    columnVisibility: {
      name: true,
      domainName: true,
      street1: true,
      street2: true,
      city: true,
      state: true,
      zipcode: true,
      country: true,
      inactive: true,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(CLIENTS, userObj?.userRole),
    {
      field: "name",
      type: "string",
      headerName: "Client Name",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "name")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "domainName",
      type: "string",
      headerName: "Domain Name",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "domainName")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "street1",
      type: "string",
      headerName: "Street",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "street1")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "street2",
      type: "string",
      headerName: "Street 2",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "street2")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "city",
      type: "string",
      headerName: "City",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "city")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "state",
      type: "string",
      headerName: "State",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "state")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "zipcode",
      type: "string",
      headerName: "Zip",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "zipcode")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "country",
      type: "string",
      headerName: "Country",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "country")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "inactive",
      type: "boolean",
      headerName: "Inactive",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: 80,
      resizable: false,
      renderCell: (cellValues) => renderBooleanCell(cellValues.row.inactive),
      filterOperators: booleanOperators,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: clients,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currFilterModel = localStorage.getItem("currentFilterModel") || defaultFilterModel;
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[CLIENTS];
    setCurrentFilterModel(currFilterModel);
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <ClientsDataGrid
          columnDefs={orderedColumnDefs}
          rows={itemsToRender}
          rowCount={totalItems}
          paginationModel={currentPaginationModel}
          onPaginationModelChange={(newPaginationModel) => {
            setPreviousPaginationModel(currentPaginationModel);
            setCurrentPaginationModel(newPaginationModel);
          }}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          defaultSelectedFilters={defaultSelectedFilters}
          filterModel={currentFilterModel}
          onFilterModelChange={(newFilterModel) => {
            if (newFilterModel.items?.length > 0) {
              newFilterModel.items[0].field = newFilterModel.items[0].field?.trim();
              newFilterModel.items[0].value = newFilterModel.items[0].value?.trim();
            }
            setPreviousFilterModel(currentFilterModel);
            setCurrentFilterModel(newFilterModel);
          }}
          onSortModelChange={(sortModel) => {
            if (sortModel.length > 0) {
              const newSortModel = { ...currentSortModel };
              if (sortModel[0].field !== currentSortModel.sortColumn) {
                if (!currentSortModel.sortColumn) {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = "asc";
                  setCurrentSortModel(newSortModel);
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  setCurrentSortModel(newSortModel);
                }
              } else {
                newSortModel.sortColumn = sortModel[0].field;
                newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                setCurrentSortModel(newSortModel);
              }
              setResetSortModel(true);
            }
          }}
          currentFilterModel={currentFilterModel}
          handleRefresh={refreshTable}
          handleClearFilters={() =>
            clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
          }
          setSelectedFilters={setSelectedFilters}
          handleExport={null}
          onColumnResize={(colDef) => {
            const newDatagridState = { ...datagridState };
            const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
            newColumnWidth.width = colDef.width;
            setDatagridState(newDatagridState);
          }}
          columnVisibilityModel={datagridState.columnVisibility}
          onColumnVisibilityChange={(newModel) => {
            const newDatagridState = { ...datagridState };
            delete newDatagridState.columnVisibility;
            newDatagridState.columnVisibility = newModel;
            setDatagridState(newDatagridState);
          }}
          onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
        />
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Client Name", "Domain Name", "Street 1", "Street 2", "City", "State", "Inactive"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
