import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

import { useField, useFormikContext } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectCheckmarks({ name, placeholder, options }) {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      name,
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl className="MultiSelectCheckmarks">
        <InputLabel htmlFor="demo-multiple-checkbox">{placeholder}</InputLabel>
        <Select
          id="demo-multiple-checkbox"
          multiple
          value={Array.isArray(field.value) ? field.value : []}
          onChange={handleChange}
          input={<OutlinedInput label={placeholder} />}
          renderValue={(selected) => (selected.length <= 1 ? selected : `${selected[0]} + ${selected.length - 1}`)}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={field.value.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelectCheckmarks.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MultipleSelectCheckmarks.defaultProps = {
  placeholder: undefined,
};

export default MultipleSelectCheckmarks;
