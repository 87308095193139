import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import IncidentReportForm from "../../forms/incidentReportForm";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, incidents } from "../../api/index";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { incidentId } = useParams();
  const [currentIncidentReport, setCurrentIncidentReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    async function loadIncidentFromAPI(currentIncidentReportId) {
      try {
        const response = await getItemFromAPI(incidents, currentIncidentReportId, controller);
        setCurrentIncidentReport(response);
      } catch (error) {
        errorHandler(error);
      } finally {
        controller?.abort();
        setIsLoading(false);
      }
    }
    loadIncidentFromAPI(incidentId);
  }, [incidentId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <IncidentReportForm
              currentAlert={currentIncidentReport.alertObj}
              currentIncidentReport={currentIncidentReport}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentIncidentReport(formValues);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
