import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer, TextField, Autocomplete, Box, Button, IconButton } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS } from "date-fns/locale";
import { Formik, Form } from "formik";
import { faChevronRight, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultFilterModel } from "./datagridDefaults";
import { DateRangeHint, dateOptions, getDate } from "./gridToolBarCustomFilterPanelDates";
import GeneralFilterField from "./CustomFilterFields";
import { lookupFieldsMap } from "../../../api/datagridFunctions";

export function createModel(values) {
  const items = [];
  let count = 0;
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      // Date is not multi select so has to be handled as so a
      // Checks dateRange for both from and to
      if (!Array.isArray(values[key])) {
        const noWhiteSpaceKey = key.replace(/\s/g, "");
        const apiValue = noWhiteSpaceKey.charAt(0).toLowerCase() + noWhiteSpaceKey.slice(1);
        const filterModel = {
          field: apiValue,
          operator: "is",
          id: count,
          lookupField: apiValue,
        };

        let lookupValue = "";
        if (values[key].date.label === "Custom Date + Time Range" && values[key].dateRange[0] !== "") {
          lookupValue = values[key].dateRange.join("~");
        } else if (values[key].date.label !== "" && values[key].date.label !== "Custom Date + Time Range") {
          lookupValue = values[key].date.value;
        }

        if (lookupValue !== "") {
          filterModel.value = lookupValue;
          filterModel.lookupValue = lookupValue;
          items.push(filterModel);
          count += 1;
        }
      } else if (values[key] !== "" && values[key].length > 0) {
        for (let i = 0; i < values[key].length; i += 1) {
          const lookupValue = values[key][i].value;
          const filterModel = {
            operator: "is",
            id: count,
            value: lookupValue,
            lookupValue,
          };
          filterModel.field = key;
          filterModel.lookupField = key;
          items.push(filterModel);
          count += 1;
        }
      }
    }
  }
  if (items.length === 0) {
    return defaultFilterModel.items;
  }
  return items;
}
/**
 * @param {Object} props - React props object.
 * @param {string[]} props.validCols - Array of valid columns headers to filter on.
 */
function GridToolbarCustomFilterPanel({
  isFilterMenuOpen,
  setIsFilterMenuOpen,
  validCols,
  columnDefs,
  setCurrentFilterModel,
  selectedFilters,
  setSelectedFilters,
  defaultSelectedFilters,
}) {
  function AutoSubmitFilter({ values }) {
    useEffect(() => {
      const items = createModel(values);
      if (JSON.stringify(selectedFilters) !== JSON.stringify(values)) {
        setCurrentFilterModel({ items });
      }
      setSelectedFilters(values);
    }, [values]);
  }
  return (
    <Drawer anchor="right" open={isFilterMenuOpen} onClose={() => setIsFilterMenuOpen(false)}>
      <Box role="presentation" sx={{ width: "auto" }}>
        <Formik initialValues={selectedFilters}>
          {({ values, setFieldValue, setValues }) => (
            <Form>
              <div id="form-title-bar">
                <IconButton
                  onClick={() => setIsFilterMenuOpen(false)}
                  size="small"
                  sx={{ width: "40px" }}
                  color="primary"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
                <div id="form-title-text" style={{ fontSize: "16px", lineHeight: "2" }}>
                  <FontAwesomeIcon size="lg" icon={faEdit} style={{ marginRight: "12px" }} />
                  table filters
                </div>
              </div>
              <div className="formik-field-view">
                <div className="formik-field-container">
                  <div className="formik-field-container-left-col">
                    {validCols.map((validColName) => {
                      for (let i = 0; i < columnDefs.length; i += 1) {
                        const colDefField = columnDefs[i].field;
                        const lookupField = lookupFieldsMap.filter(
                          (f) => f.field.toLowerCase() === colDefField.toLowerCase()
                        );
                        const lookupFieldId = lookupField.length > 0 ? lookupField[0].lookupField : null;
                        const validColDefField = lookupFieldId || colDefField;
                        // Compare on the headerName
                        if (validColName === columnDefs[i].headerName && columnDefs[i].type !== "date") {
                          return (
                            <div className="formik-field" key={`generalFilerContainer-${validColName}`}>
                              <GeneralFilterField
                                // Remove duplicate filters
                                filterOptions={columnDefs[i].valueOptions ? columnDefs[i].valueOptions() : []}
                                headerName={columnDefs[i].headerName}
                                field={validColDefField}
                                // Different api calls for specific column Pages for ex Rooms page is name="roomName" compared to roomId="237xxx"
                                setFieldValue={setFieldValue}
                                selectedFilter={selectedFilters[validColDefField]}
                              />
                            </div>
                          );
                        }
                        if (validColName === columnDefs[i].headerName && columnDefs[i].type === "date") {
                          return (
                            <Fragment key="dateTimeField">
                              <div className="formik-field">
                                <Autocomplete
                                  id={validColDefField}
                                  className="date-filter"
                                  filterSelectedOptions
                                  fullWidth
                                  autoHighlight
                                  options={dateOptions}
                                  groupBy={(option) => option.group}
                                  isOptionEqualToValue={(option, value) => option.label === value.label}
                                  getOptionLabel={(option) => option.label}
                                  sx={{ width: 400 }}
                                  value={selectedFilters[validColDefField].date}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.label}
                                    </Box>
                                  )}
                                  renderInput={(params) => <TextField {...params} label={columnDefs[i].headerName} />}
                                  onChange={(e, value) => {
                                    if (value === null) {
                                      const val = { ...selectedFilters[validColDefField] };
                                      val.date = { label: "" };
                                      setFieldValue(validColDefField, val);
                                    } else if (value?.label === "Custom Date + Time Range") {
                                      const val = { ...selectedFilters[validColDefField] };
                                      val.date = { label: value?.label };
                                      setFieldValue(validColDefField, val);
                                    } else {
                                      const val = { ...selectedFilters[validColDefField] };
                                      const endDate = new Date();
                                      endDate.setHours(23, 59, 59, 999);
                                      val.dateRange = ["", endDate.toISOString()];
                                      value.value = getDate(value?.label);
                                      val.date = value;
                                      setFieldValue(validColDefField, val);
                                    }
                                  }}
                                />
                              </div>
                              <Box
                                className="formik-field"
                                sx={{
                                  display:
                                    selectedFilters[colDefField].date.label === "Custom Date + Time Range"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                                  <DateTimePicker
                                    label="Start: "
                                    ampm={false}
                                    timeSteps={{ minutes: 1 }}
                                    sx={{ width: "200px" }}
                                    value={
                                      selectedFilters[validColDefField].dateRange !== ""
                                        ? new Date(selectedFilters[validColDefField].dateRange[0])
                                        : null
                                    }
                                    onAccept={(date) => {
                                      if (date) {
                                        const val = JSON.parse(JSON.stringify(selectedFilters[validColDefField]));
                                        val.dateRange[0] = date.toISOString();
                                        setFieldValue(validColDefField, val);
                                      }
                                    }}
                                  />
                                  <DateTimePicker
                                    label="End: "
                                    ampm={false}
                                    timeSteps={{ minutes: 1 }}
                                    sx={{ width: "200px" }}
                                    value={
                                      selectedFilters[colDefField].dateRange !== ""
                                        ? new Date(selectedFilters[colDefField].dateRange[1])
                                        : null
                                    }
                                    onAccept={(date) => {
                                      if (date) {
                                        const val = JSON.parse(JSON.stringify(selectedFilters[colDefField]));
                                        val.dateRange[1] = date.toISOString();
                                        setFieldValue(colDefField, val);
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <DateRangeHint
                                dateRange={values[colDefField].dateRange ? values[colDefField].dateRange : []}
                              />
                            </Fragment>
                          );
                        }
                      }
                      throw new Error(
                        `Column "${validColName}" in validCols was not found in columnDefs in gridToolBarCustomFilterPanel component. Make sure the casing matches the column header name`
                      );
                    })}

                    <div className="formik-field">
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                          // Only for Users default filters
                          setValues(defaultSelectedFilters("clear"));
                        }}
                      >
                        Clear Filters
                      </Button>
                    </div>
                    <AutoSubmitFilter values={values} />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Drawer>
  );
}

GridToolbarCustomFilterPanel.propTypes = {
  setIsFilterMenuOpen: PropTypes.func.isRequired,
  isFilterMenuOpen: PropTypes.bool.isRequired,
  columnDefs: PropTypes.array.isRequired,
  validCols: PropTypes.array.isRequired,
  setCurrentFilterModel: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  defaultSelectedFilters: PropTypes.func.isRequired,
};

export default GridToolbarCustomFilterPanel;
