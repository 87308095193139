import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, displays } from "../api/index";
import { routes } from "../nav/nav";
import { displayForm } from "./formOptions";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import Spinner from "../components/atoms/spinner/Spinner";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";

function DisplayForm({ currentDisplay, errorHandler, onFormPost }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, DISPLAYS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.globalAdmin;
  const { requiredFieldNames } = displays;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;
  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    selectedTimeZone: currentDisplay?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    isSaving: false,
    errorExists: false,
    showUnsavedChangesModal: false,
    isDirty: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [fieldValues.name, fieldValues.address, fieldValues.type];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
    };
    if (currentDisplay) updatedFieldValues._id = currentDisplay._id;
    return updatedFieldValues;
  }

  const onDisplayFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;

    try {
      // update this to have the room values from the db
      const updatedDisplay = {
        ...currentDisplay,
        ...values,
        // timeZone: formState.selectedTimeZone,
      };
      if (currentDisplay) response = await patchItemToAPI(displays, updatedDisplay, controller);
      else response = await postItemToAPI(displays, updatedDisplay, controller);
      if (!currentDisplay) {
        setNewRecordId(response.id);
        updatedDisplay._id = response.id;
      }
      if (navigateToList) navigate(`${properties[DISPLAYS].path}`);
      else {
        onFormPost(updatedDisplay);
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentDisplay : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Room", currentDisplay?._id ? currentDisplay : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  let titleBarText = "";
  if (!currentDisplay?._id) titleBarText = `add new display`;
  else if (userCanUpdate) titleBarText = `view/edit display`;
  else titleBarText = `view display`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Display", currentDisplay?._id ? currentDisplay : null)
            : getItemSucccessMessage("Display", newRecordId.length === 0 ? currentDisplay : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          type: currentDisplay?.type || "",
          name: currentDisplay?.name || "",
          macAddress: currentDisplay?.macAddress || "",
          description: currentDisplay?.description || "",
          inactive: currentDisplay?.inactive || false,
        }}
        onSubmit={(values, helpers) => {
          onDisplayFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[DISPLAYS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onDisplayFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentDisplay !== null} />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          id="type"
                          name="type"
                          label={`${!currentDisplay ? "Select a " : ""}Display Type`}
                          placeholder="Display Types"
                          options={displayForm.displayTypeOptions}
                          onClick={(e) => {
                            if (e !== null && !dirty) {
                              const newFormState = { ...formState };
                              newFormState.isDirty = true;
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }
                          }}
                          InputProps={{
                            //   readOnly: !userCanUpdate,
                            readOnly: false,
                          }}
                        />
                        {requiredFieldNames.includes("type") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          id="name"
                          name="name"
                          label={`${!currentDisplay ? "Enter " : ""}Display Name`}
                          placeholder="Display Name"
                          InputProps={{
                            //   readOnly: !userCanUpdate,
                            readOnly: false,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="macAddress"
                          id="macAddress"
                          label={`${
                            !currentDisplay || values.macAddress.trim().length === 0 ? "Enter" : ""
                          }  MAC Address`}
                          InputProps={{
                            //   readOnly: !userCanUpdate,
                            readOnly: false,
                          }}
                        />
                        {requiredFieldNames.includes("macAddress") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          id="comment"
                          name="comment"
                          label={
                            !currentDisplay || values.description.trim().length === 0
                              ? "Enter Display Comments"
                              : "Display Comments"
                          }
                          placeholder="Display Comments"
                          InputProps={{
                            //   readOnly: !userCanUpdate,
                            readOnly: false,
                          }}
                        />
                        {requiredFieldNames.includes("description") && <RequiredFieldIndicator />}
                      </div>

                      {currentDisplay && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  if (e !== null && !dirty) {
                                    const newFormState = { ...formState };
                                    newFormState.errorExists = false;
                                    setFormState(newFormState);
                                  }
                                }}
                                InputProps={{
                                  //   readOnly: !userCanUpdate,
                                  readOnly: false,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentDisplay.inactive ? "Yes" : "No"}
                              </div>{" "}
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentDisplay && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentDisplay.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentDisplay.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentDisplay}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.isDirty) ||
                  !userCanUpdate
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.isDirty}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.isDirty || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[DISPLAYS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

DisplayForm.propTypes = {
  currentDisplay: PropTypes.object,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
};

export default DisplayForm;
