import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import { Form, Formik } from "formik";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import FormikFormIncidentAlertBar from "./formikFormIncidentAlertBar";
import { postItemToAPI, patchItemToAPI, getItemFromAPI, incidents } from "../api/index";
import { incidentForm } from "./formOptions";
import { routes } from "../nav/nav";
import { forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function IncidentReportForm({ currentAlert, currentIncidentReport, errorHandler, onFormPost }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const { user } = useSelector((state) => state.global);
  const { alertId } = useParams();
  const navigate = useNavigate();
  const { properties } = routes;
  const currentPath = routes.paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = true;
  const { requiredFieldNames } = incidents;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const lastUpdatedAt = currentIncidentReport?.lastUpdatedByObj
    ? new Date(currentIncidentReport?.lastUpdatedByObj.updatedAt)
    : null;
  const createdAt = currentIncidentReport?.createdByObj
    ? new Date(currentIncidentReport?.createdByObj.createdAt)
    : null;
  const [newRecordId, setNewRecordId] = useState("");
  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });
  const [formState, setFormState] = useState({
    isSaving: false,
    errorExists: false,
    showUnsavedChangesModal: false,
    showInvolvedPartyModal: false,
    // harmScaleModalIsOpen: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const onIncidentFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    let response = null;
    let newIncidentReport = null;
    try {
      // update this to have the incident report values from the db
      const incidentReport =
        currentAlert?.incidentReportObj !== undefined ? currentAlert.incidentReportObj : currentIncidentReport;
      values.employeeSustainInjuryDetail = values.employeeSustainInjury ? values.employeeSustainInjuryDetail : "";
      const updatedIncidentReport = {
        ...incidentReport,
        ...values,
        alertId,
      };
      if (currentIncidentReport)
        response = await patchItemToAPI(incidents, updatedIncidentReport, new AbortController());
      else {
        updatedIncidentReport.completedBy = user.email;
        updatedIncidentReport.completedByName = user.name;
        response = await postItemToAPI(incidents, updatedIncidentReport, new AbortController());
        setNewRecordId(response.id);
        newIncidentReport = await getItemFromAPI(incidents, response.id, new AbortController());
      }
      if (navigateToList) navigate(`${properties[currentPath.index].path}`);
      else {
        onFormPost(newIncidentReport || updatedIncidentReport);
        newFormState.selectedItemChanged = false;
        newFormState.errorExists = false;
        newFormState.showUnsavedChangesModal = false;
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentIncidentReport : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage(
        "Room",
        currentIncidentReport?._id ? currentIncidentReport : null
      );
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
    };
    if (currentIncidentReport) updatedFieldValues._id = currentIncidentReport._id;
    return updatedFieldValues;
  }

  let initialValues = {
    criticalIncident: false,
    typeOfIncident: [],
    incidentInvolvement: [],
    involvedParties: [],
    employeeSustainInjury: false,
    partyMedicalAttention: false,
    otherNotification: [],
    description: "",
    resolutionOfEvent: "",
    employeeSustainInjuryDetail: "",
  };
  if (currentAlert?.incidentReportObj) {
    initialValues = {
      criticalIncident: currentAlert.incidentReportObj.criticalIncident,
      typeOfIncident: currentAlert.incidentReportObj.typeOfIncident || [],
      incidentInvolvement: currentAlert.incidentReportObj.incidentInvolvement || [],
      involvedParties: currentAlert.incidentReportObj.involvedParties || [],
      employeeSustainInjury: currentAlert.incidentReportObj.employeeSustainInjury,
      partyMedicalAttention: currentAlert.incidentReportObj.partyMedicalAttention,
      otherNotification: currentAlert.incidentReportObj.otherNotification || [],
      description: currentAlert.incidentReportObj.description,
      resolutionOfEvent: currentAlert.incidentReportObj.resolutionOfEvent,
      employeeSustainInjuryDetail: currentAlert.incidentReportObj.employeeSustainInjury
        ? currentAlert.incidentReportObj.employeeSustainInjuryDetail
        : "",
    };
  } else if (currentIncidentReport) {
    initialValues = {
      criticalIncident: currentIncidentReport.criticalIncident,
      typeOfIncident: currentIncidentReport.typeOfIncident || [],
      incidentInvolvement: currentIncidentReport.incidentInvolvement || [],
      involvedParties: currentIncidentReport.involvedParties || [],
      employeeSustainInjury: currentIncidentReport.employeeSustainInjury,
      partyMedicalAttention: currentIncidentReport.partyMedicalAttention,
      otherNotification: currentIncidentReport.otherNotification || [],
      description: currentIncidentReport.description,
      resolutionOfEvent: currentIncidentReport.resolutionOfEvent,
      employeeSustainInjuryDetail: currentIncidentReport.employeeSustainInjury
        ? currentIncidentReport.employeeSustainInjuryDetail
        : "",
    };
  }

  let titleBarText = "";
  if (!currentIncidentReport?._id) titleBarText = `add new incident report`;
  else if (userCanUpdate) titleBarText = `view/edit incident report`;
  else titleBarText = `view incident report`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open || formState.errorExists}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Incident Report", currentIncidentReport?._id ? currentIncidentReport : null)
            : getItemSucccessMessage("Incident Report", newRecordId?.length === 0 ? currentIncidentReport : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, helpers) => {
          onIncidentFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[currentPath.index].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onIncidentFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentAlert !== null} />

              <FormikFormIncidentAlertBar currentAlert={currentAlert || currentIncidentReport?.alertObj} />
              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      {userCanUpdate ? (
                        <div className="formik-field">
                          <Field
                            asComponent="multiSelectCheckmarks"
                            label="Incident Involvement"
                            name="incidentInvolvement"
                            id="incidentInvolvement"
                            options={incidentForm.incidentInvolvementOptions}
                            placeholder="This incident occurred between..."
                            onClick={(e) => {
                              const newFormState = { ...formState };
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("incidentInvolvement") && <RequiredFieldIndicator />}
                        </div>
                      ) : (
                        <div className="formik-field">
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">This incident occurred...</div>

                            {currentIncidentReport?.incidentInvolvement?.map((item) => (
                              <div style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "blue" }}
                                />
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {userCanUpdate ? (
                        <div className="formik-field">
                          <Field
                            asComponent="multiSelectCheckmarks"
                            label="Type of incident"
                            name="typeOfIncident"
                            id="typeOfIncident"
                            options={incidentForm.typeOfIncidentOptions}
                            placeholder="The incident type included..."
                            onClick={(e) => {
                              const newFormState = { ...formState };
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("incidentInvolvement") && <RequiredFieldIndicator />}
                        </div>
                      ) : (
                        <div className="formik-field">
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">This incident type included...</div>

                            {currentIncidentReport?.typeOfIncident?.map((item) => (
                              <div style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "blue" }}
                                />
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          label="Incident Details"
                          name="description"
                          id="description"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      {userCanUpdate ? (
                        <div className="formik-field">
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">Did employee(s) sustain an injury?</div>
                            <Field
                              asComponent="checkboxYesNo"
                              name="employeeSustainInjury"
                              id="employeeSustainInjury"
                              onClick={(e) => {
                                const newFormState = { ...formState };
                                newFormState.errorExists = false;
                                setFormState(newFormState);
                              }}
                              InputProps={{
                                readOnly: !userCanUpdate,
                              }}
                            />
                          </div>
                          {requiredFieldNames.includes("employeeSustainInjury") && <RequiredFieldIndicator />}
                        </div>
                      ) : (
                        <div className="formik-checkboxYesNo-field">
                          <div className="formik-checkboxYesNo-label">Did employee(s) sustain an injury?</div>
                          <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                            />
                            {currentIncidentReport?.employeeSustainInjury ? "Yes" : "No"}
                          </div>
                          {requiredFieldNames.includes("employeeSustainInjury") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {values.employeeSustainInjury && (
                        <div className="formik-field">
                          <Field
                            asComponent="textarea"
                            className="form-textarea"
                            label="Description of injuries"
                            name="employeeSustainInjuryDetail"
                            id="employeeSustainInjuryDetail"
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("employeeSustainInjuryDetail") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      <div className="formik-field">
                        {userCanUpdate ? (
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">
                              Did injured parties seek medical attention?
                            </div>
                            <Field
                              asComponent="checkboxYesNo"
                              name="partyMedicalAttention"
                              id="partyMedicalAttention"
                              onClick={(e) => {
                                const newFormState = { ...formState };
                                newFormState.errorExists = false;
                                setFormState(newFormState);
                              }}
                              InputProps={{
                                readOnly: !userCanUpdate,
                              }}
                            />
                            {requiredFieldNames.includes("employeeSustainInjury") && <RequiredFieldIndicator />}
                          </div>
                        ) : (
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">
                              Did injured parties seek medical attention?
                            </div>
                            <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                              <FontAwesomeIcon
                                icon={faCheckSquare}
                                style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                              />
                              {currentIncidentReport?.partyMedicalAttention ? "Yes" : "No"}
                            </div>
                          </div>
                        )}
                        {requiredFieldNames.includes("employeeSustainInjury") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          label="Resolution Details"
                          name="resolutionOfEvent"
                          id="resolutionOfEvent"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("resolutionOfEvent") && <RequiredFieldIndicator />}
                      </div>

                      {userCanUpdate ? (
                        <div className="formik-field">
                          <Field
                            asComponent="multiSelectCheckmarks"
                            label="Were Any Parties Notified?"
                            name="otherNotification"
                            id="otherNotification"
                            options={incidentForm.otherNotificationOptions}
                            placeholder="Notified parties include..."
                            onClick={(e) => {
                              const newFormState = { ...formState };
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("incidentInvolvement") && <RequiredFieldIndicator />}
                        </div>
                      ) : (
                        <div className="formik-field">
                          <div className="formik-checkboxYesNo-field">
                            <div className="formik-checkboxYesNo-label">Notified parties include...</div>
                            {currentIncidentReport?.otherNotification?.map((item) => (
                              <div style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "blue" }}
                                />
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="formik-field-footer">
                    {currentIncidentReport && lastUpdatedAt && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>LAST UPDATED: </span>
                          {`${lastUpdatedAt.toLocaleDateString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                          })} ${lastUpdatedAt.toLocaleTimeString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                            timeZoneName: "short",
                          })}`}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>LAST UPDATED BY: </span>
                          {currentIncidentReport.lastUpdatedByObj?.name}
                        </div>
                      </div>
                    )}

                    {currentIncidentReport && createdAt && (
                      <div style={{ marginTop: "8px" }}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {`${createdAt.toLocaleDateString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                          })} ${createdAt.toLocaleTimeString("en-US", {
                            hourCycle: "h23",
                            timeZone: tzid,
                            timeZoneName: "short",
                          })}`}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED BY: </span>
                          {currentIncidentReport.createdByObj?.name}
                        </div>
                      </div>
                    )}

                    {(currentAlert || currentIncidentReport?.alertObj) && (
                      <div style={{ marginTop: "8px" }}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>ALERT TYPE: </span>
                          {currentAlert?.alertTypeName || currentIncidentReport?.alertObj?.alertTypeName}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentIncidentReport}
                buttonDisabled={formState.isSaving || snackbarAlertState.open || !dirty || !userCanUpdate}
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty}
                formErrorExists={false} // (currently) no db constraints
                onFormClose={(e) => {
                  dirty || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[currentPath.index].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

IncidentReportForm.propTypes = {
  currentIncidentReport: PropTypes.object,
  currentAlert: PropTypes.object,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
};

export default IncidentReportForm;
