import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, simplelistItemsFromAPI, rooms, locations, buildings, departments } from "../../api/index";
import RoomForm from "../../forms/roomForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { roomId } = useParams();
  const [currentRoom, setCurrentRoom] = useState(null);
  const [locationId, setlocationId] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isNewRoom = parseInt(roomId) === 0;

  const initFormState = {
    currentValues: null,
    isSaving: false,
    isTemplate: false,
    isAddNew: parseInt(window.location.pathname.slice(-1)) === 0,
    isSaveAndAddNew: false,
    selectedItemChanged: false,
    errorExists: false,
    showUnsavedChangesModal: false,
  };
  const [currentFormState, setCurrentFormState] = useState(initFormState);

  async function loadLocationOptionsFromAPI(controller) {
    try {
      const response = await simplelistItemsFromAPI(locations, controller, null);
      setLocationOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadBuildingOptionsFromAPI(selectedLocationId, controller) {
    if (locationId.length === 0) setlocationId(selectedLocationId);
    try {
      const response = await simplelistItemsFromAPI(buildings, controller, selectedLocationId);
      setBuildingOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }
  async function loadDepartmentOptionsFromAPI(selectedBuildingId, controller) {
    if (buildingId.length === 0) setBuildingId(selectedBuildingId);
    try {
      const response = await simplelistItemsFromAPI(departments, controller, selectedBuildingId);
      setDepartmentOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadRoomFromAPI(currentRoomId, controller) {
    try {
      const response = await getItemFromAPI(rooms, currentRoomId, controller);
      setCurrentRoom(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      controller?.abort();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (locationOptions.length === 0) loadLocationOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    if (currentRoom) {
      loadBuildingOptionsFromAPI(currentRoom.locationId, new AbortController());
      loadDepartmentOptionsFromAPI(currentRoom.buildingId, new AbortController());
    }
  }, [currentRoom]);

  useEffect(() => {
    if (locationId) loadBuildingOptionsFromAPI(locationId, new AbortController());
  }, [locationId]);

  useEffect(() => {
    if (buildingId) loadDepartmentOptionsFromAPI(buildingId, new AbortController());
  }, [buildingId]);

  useEffect(() => {
    setIsLoading(!isNewRoom);
    isNewRoom ? setCurrentRoom(null) : loadRoomFromAPI(roomId, new AbortController());
  }, [roomId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <RoomForm
              currentRoom={currentRoom}
              locationOptions={locationOptions}
              buildingOptions={buildingOptions}
              departmentOptions={departmentOptions}
              onLocationChange={(selectedLocationId) => {
                setlocationId(selectedLocationId);
              }}
              onBuildingChange={(selectedBuildingId) => {
                setBuildingId(selectedBuildingId);
              }}
              onDepartmentChange={(selectedDepartmentId) => {
                setDepartmentId(selectedDepartmentId);
              }}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentRoom(formValues);
              }}
              onFormSubmit={(selectedLocationId, selectedBuildingId, reloadLists) => {
                if (reloadLists) {
                  const controller = new AbortController();
                  loadBuildingOptionsFromAPI(selectedLocationId, controller);
                  loadDepartmentOptionsFromAPI(selectedBuildingId, controller);
                }
              }}
              currentFormState={currentFormState}
              setCurrentFormState={(newFormState) => {
                setCurrentFormState(newFormState);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
