import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { Skeleton } from "@mui/material";
import { BarItem } from "./RoundedBarItem.tsx";

function BarChart({ data, header, isLoading, alertDatagrams }) {
  const keys = [];
  const parsedData = [];
  if (!isLoading) {
    alertDatagrams.forEach((datagram) => {
      keys.push(`${data[0][datagram].name}s`);
    });
    keys.push("Total Alerts");
    const dataKeys = [...alertDatagrams, "total"];
    data.forEach((item) => {
      const obj = {
        month: item.month,
      };
      dataKeys.forEach((key, indx) => {
        if (key === "total") obj["Total Alerts"] = item[key];
        else obj[keys[indx]] = item[key].value;
      });
      parsedData.push(obj);
    });
  }
  return (
    <>
      {header}
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height="100%" />
      ) : (
        <ResponsiveBar
          data={parsedData}
          keys={keys}
          indexBy="month"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={["#f24949", "#ff8038", "#545454"]}
          borderRadius={4}
          barComponent={BarItem}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          innerPadding={3}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Month",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Alerts",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          // labelTextColor={{
          //   from: "color",
          //   modifiers: [["darker", 2]],
          // }}
          labelTextColor="#ffffff"
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      )}
    </>
  );
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.element,
  isLoading: PropTypes.bool,
  alertDatagrams: PropTypes.arrayOf(PropTypes.string),
};

export default BarChart;
