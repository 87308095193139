import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faArrowRightArrowLeft, faTrash, faEdit, faAdd, faPrint, faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/pro-regular-svg-icons";
import { setTargetClient, setUser } from "../../../redux/slices/globalSlice";
import { fetchCollectionAll } from "../../../redux/slices/filterOptionsSlice";
import { routes } from "../../../nav/nav";
import {
  clients,
  getItemFromAPI,
  getUserFromAPI,
  getAllCollectionsOptions,
  getAllCollectionURL,
} from "../../../api/index";

const { properties, paths, DASHBOARD, ALERTS, INCIDENTS, CLIENTS, BUILDINGS, DEVICES, COLLECTORS, USERS, MAINTENANCE } =
  routes;
const ITEM_HEIGHT = 36;

export default function GridActionMenu({
  rowId,
  itemName,
  pathIndex,
  incidentCreated = false,
  setShowDeleteConfirm = null,
  setImportMsgFileDialogState = null,
  setAttachmentsDialogState = null,
  userRole = null,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClientChange = async () => {
    const currentBasePath = paths.find((p) => window.location.pathname.includes(p.pathname));
    const targetClient = await getItemFromAPI(clients, rowId, new AbortController());
    let newUserProfile = null;
    if (targetClient) {
      dispatch(
        setTargetClient({
          id: targetClient._id,
          domainName: targetClient.domainName,
          name: targetClient.name,
        })
      );
      newUserProfile = await getUserFromAPI(new AbortController());
      dispatch(setUser(newUserProfile));
    }
    const url = getAllCollectionURL({ endpoint: CLIENTS });
    dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
    if (
      (window.location.pathname.indexOf(properties[CLIENTS].path) > -1 ||
        window.location.pathname.indexOf(properties[USERS].path) > -1 ||
        window.location.pathname.indexOf(properties[MAINTENANCE].path) > -1) &&
      !newUserProfile.roleObj.admin
    )
      navigate(properties[DASHBOARD].path);
    else if (window.location.pathname.localeCompare(currentBasePath.pathname) !== 0) navigate(currentBasePath.pathname);
  };

  const userCanUpdateDelete = userRole?.superAdmin || userRole?.admin;

  return pathIndex !== ALERTS ? (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        title="Actions..."
        style={{ color: "#1976d2" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "16ch",
          },
        }}
      >
        {pathIndex === CLIENTS && (
          <MenuItem key={0} onClick={handleClientChange} style={{ color: "#1976d2", fontSize: "0.82rem" }}>
            <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ marginRight: "8px", opacity: "0.9" }} />
            Switch to Client
          </MenuItem>
        )}
        <MenuItem
          key={1}
          selected
          onClick={(e) => navigate(`${properties[pathIndex].path}/${rowId}`)}
          style={{ color: "#1976d2", fontSize: "0.82rem" }}
        >
          <FontAwesomeIcon
            icon={pathIndex !== ALERTS || (pathIndex !== ALERTS && incidentCreated) ? faEdit : faAdd}
            style={{ marginRight: "8px", opacity: "0.9" }}
          />
          {userCanUpdateDelete ? "VIEW/EDIT" : "VIEW"}
        </MenuItem>

        {userCanUpdateDelete && (
          <MenuItem
            key={2}
            onClick={(e) => {
              setAnchorEl(null);
              setShowDeleteConfirm(true);
            }}
            style={{ color: "#1976d2", fontSize: "0.82rem" }}
            disabled={!rowId || [CLIENTS, USERS, DEVICES, COLLECTORS].includes(pathIndex)}
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: "8px", opacity: "0.9" }} />
            DELETE
          </MenuItem>
        )}

        {pathIndex === INCIDENTS && (
          <MenuItem
            key={3}
            onClick={(e) => {
              console.log("PRINT", { rowId, path: properties[pathIndex] });
            }}
            style={{ color: "#1976d2", fontSize: "0.82rem" }}
            disabled
          >
            <FontAwesomeIcon icon={faPrint} style={{ marginRight: "8px", opacity: "0.9" }} />
            PRINT
          </MenuItem>
        )}

        {userCanUpdateDelete && pathIndex === BUILDINGS && (
          <MenuItem
            key={4}
            onClick={(e) => {
              setAnchorEl(null);
              setImportMsgFileDialogState({ open: true, buildingName: itemName || "" });
            }}
            style={{ color: "#1976d2", fontSize: "0.82rem" }}
          >
            <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: "8px", opacity: "0.9" }} />
            IMPORT MSG FILE
          </MenuItem>
        )}
        {userCanUpdateDelete && pathIndex === BUILDINGS && (
          <MenuItem
            key={5}
            onClick={(e) => {
              setAnchorEl(null);
              setAttachmentsDialogState({ open: true, buildingName: itemName || "" });
            }}
            style={{ color: "#1976d2", fontSize: "0.82rem" }}
          >
            <FontAwesomeIcon icon={faFolderPlus} style={{ marginRight: "8px", opacity: "0.9" }} />
            ATTACHMENTS
          </MenuItem>
        )}
      </Menu>
    </div>
  ) : (
    <div
      className={`${incidentCreated ? "edit" : "add-new"}-grid-cell`}
      title={`${incidentCreated ? "View/Edit" : "Create"} Incident Report`}
    >
      <FontAwesomeIcon icon={incidentCreated ? faEdit : faAdd} style={{ paddingTop: "2px", opacity: "0.9" }} />
      &nbsp;&nbsp;{incidentCreated ? "EDIT" : "CREATE"}
    </div>
  );
}

GridActionMenu.propTypes = {
  rowId: PropTypes.string,
  itemName: PropTypes.string,
  pathIndex: PropTypes.number,
  incidentCreated: PropTypes.bool,
  setShowDeleteConfirm: PropTypes.func,
  setImportMsgFileDialogState: PropTypes.func,
  setAttachmentsDialogState: PropTypes.func,
  userRole: PropTypes.object,
};

GridActionMenu.defaultProps = {};
