import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

function TextInputWrapper({ name, width, ...props }) {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...props,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField sx={{ width }} {...configTextField} />;
}

TextInputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};
TextInputWrapper.defaultProps = {
  width: "500px",
};

export default TextInputWrapper;
