import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import globalReducer from "./slices/globalSlice";
import filterOptionsReducer from "./slices/filterOptionsSlice";

const persistConfig = {
  key: "root",
  blacklist: ["filterOptions"],
  storage,
};

const rootReducer = combineReducers({
  global: globalReducer,
  filterOptions: filterOptionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
