import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, simplelistItemsFromAPI, locations, buildings } from "../../api/index";
import BuildingForm from "../../forms/buildingForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { buildingId } = useParams();
  const [currentBuilding, setCurrentBuilding] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isNewBuilding = parseInt(buildingId) === 0;
  const [locationOptions, setLocationOptions] = useState([]);

  async function loadLocationOptionsFromAPI(controller) {
    try {
      const response = await simplelistItemsFromAPI(locations, controller, null);
      setLocationOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadBuildingFromAPI(currentBuildingId, controller) {
    try {
      const response = await getItemFromAPI(buildings, currentBuildingId, controller);
      setCurrentBuilding(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (locationOptions.length === 0) loadLocationOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    setIsLoading(!isNewBuilding);
    isNewBuilding ? setCurrentBuilding(null) : loadBuildingFromAPI(buildingId, new AbortController());
  }, [buildingId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <BuildingForm
              currentBuilding={currentBuilding}
              locationOptions={locationOptions}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentBuilding(formValues);
              }}
              onFormSubmit={(selectedBuildingId, reloadLists) => {
                if (reloadLists) {
                  const controller = new AbortController();
                  loadLocationOptionsFromAPI(selectedBuildingId, controller);
                }
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
