import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Main from "../../components/organisms/main/Main";
import UsersDataGrid from "../../components/organisms/datagrid/usersDataGrid";
import Spinner from "../../components/atoms/spinner/Spinner";
import { users } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  stringOperators,
  booleanOperators,
  dateOperators,
  renderActionMenuCell,
  renderBooleanCell,
  setColumnOrder,
  lookupFieldsMap,
} from "../../api/datagridFunctions";
import { datagridOptions, defaultSortModels } from "../../components/organisms/datagrid/datagridDefaults";
import HttpError from "../httpError";
import GridToolbarCustomFilterPanel, {
  createModel,
} from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, USERS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[USERS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = (clearDefaults = "") => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.field === "inactive") {
        acc[lookupField] = [{ label: "Active", value: "false" }];
      } else if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    if (clearDefaults === "clear") {
      defaultFilters.inactive = [];
    }
    return defaultFilters;
  };

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "actionMenu", index: 0 },
      { field: "createdAt", index: 1 },
      { field: "updatedAt", index: 2 },
      { field: "name", index: 3 },
      { field: "displayName", index: 4 },
      { field: "email", index: 5 },
      { field: "phoneNumber", index: 6 },
      { field: "mobileNumber", index: 7 },
      { field: "inactive", index: 8 },
    ],
    columnWidths: [
      { field: "createdAt", width: 160 },
      { field: "updatedAt", width: 160 },
      { field: "name", width: 250 },
      { field: "displayName", width: 250 },
      { field: "email", width: 250 },
      { field: "phoneNumber", width: 250 },
      { field: "mobileNumber", width: 250 },
      { field: "inactive", width: 100 },
    ],
    columnVisibility: {
      createdAt: false,
      updatedAt: false,
      name: true,
      displayName: true,
      email: true,
      phoneNumber: true,
      mobileNumber: true,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(USERS, userObj?.userRole),
    {
      field: "createdAt",
      type: "date",
      headerName: "Created On",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "createdAt")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", { timeZone: tzid })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },

    {
      field: "updatedAt",
      type: "date",
      headerName: "Last Updated",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "updatedAt")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", { timeZone: tzid })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },

    {
      field: "name",
      type: "string",
      headerName: "User Name",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "name")[0].width,
      filterOperators: stringOperators,
    },

    {
      field: "displayName",
      type: "string",
      headerName: "Display Name",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "displayName")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "email",
      type: "string",
      headerName: "Email",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "email")[0].width,
      filterOperators: stringOperators,
    },

    {
      field: "phoneNumber",
      type: "string",
      headerName: "Primary Phone",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "phoneNumber")[0].width,
      filterOperators: stringOperators,
    },

    {
      field: "mobileNumber",
      type: "string",
      headerName: "Mobile Phone",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "mobileNumber")[0].width,
      filterOperators: stringOperators,
    },

    {
      field: "inactive",
      type: "boolean",
      headerName: "Inactive",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (cellValues) => renderBooleanCell(cellValues.row.inactive),
      filterOperators: booleanOperators,
      resizable: false,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });

  const defaultFilters = { items: createModel(defaultSelectedFilters()) };
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilters);
  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: users,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[USERS];
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <UsersDataGrid
          columnDefs={orderedColumnDefs}
          rows={itemsToRender}
          rowCount={totalItems}
          paginationModel={currentPaginationModel}
          onPaginationModelChange={(newPaginationModel) => {
            setPreviousPaginationModel(currentPaginationModel);
            setCurrentPaginationModel(newPaginationModel);
          }}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          defaultSelectedFilters={defaultSelectedFilters}
          filterModel={currentFilterModel}
          onSortModelChange={(sortModel) => {
            if (sortModel?.length > 0) {
              const newSortModel = { ...currentSortModel };
              if (sortModel[0].field !== currentSortModel.sortColumn) {
                if (!currentSortModel.sortColumn) {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = "asc";
                  setCurrentSortModel(newSortModel);
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  setCurrentSortModel(newSortModel);
                }
              } else {
                newSortModel.sortColumn = sortModel[0].field;
                newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                setCurrentSortModel(newSortModel);
              }
              setResetSortModel(true);
            }
          }}
          currentFilterModel={currentFilterModel}
          handleRefresh={refreshTable}
          handleClearFilters={() =>
            clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
          }
          handleExport={null}
          onColumnResize={(colDef) => {
            const newDatagridState = { ...datagridState };
            const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
            newColumnWidth.width = colDef.width;
            setDatagridState(newDatagridState);
          }}
          columnVisibilityModel={datagridState.columnVisibility}
          onColumnVisibilityChange={(newModel) => {
            const newDatagridState = { ...datagridState };
            delete newDatagridState.columnVisibility;
            newDatagridState.columnVisibility = newModel;
            setDatagridState(newDatagridState);
          }}
          onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      <GridToolbarCustomFilterPanel
        validCols={["User Name", "Display Name", "Email", "Primary Phone", "Mobile Phone", "Inactive"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
