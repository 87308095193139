import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { alerts, devices, alertTypes, fetchExportData } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  loadFilterOptionsFromAPI,
  stringOperators,
  booleanOperators,
  dateOperators,
  renderSingleSelectField,
  getExportURL,
  setColumnOrder,
  lookupFieldsMap,
  applyOptions,
} from "../../api/datagridFunctions";
import AlertsDataGrid from "../../components/organisms/datagrid/alertsDataGrid";
import { datagridOptions, defaultSortModels } from "../../components/organisms/datagrid/datagridDefaults";
import HttpError from "../httpError";
import GridActionMenu from "../../components/organisms/datagrid/gridActionMenu";
import GridToolbarCustomFilterPanel, {
  createModel,
} from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";
import { getDate } from "../../components/organisms/datagrid/gridToolBarCustomFilterPanelDates";

const { sortModel: defaultSortModel, ALERTS } = defaultSortModels;

function List({ errorHandler }) {
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[ALERTS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = (clearDefaults = "") => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const timestamp = searchParams.get("timestamp");
        let date = { label: "" };
        if (clearDefaults !== "clear") {
          switch (timestamp) {
            case "today":
              date = { label: "Today", group: "Days", value: getDate("Today") };
              break;
            case "yesterday":
              date = { label: "Yesterday", group: "Days", value: getDate("Yesterday") };
              break;
            case "pastweek":
              date = { label: "Past Week", group: "Days", value: getDate("Past Week") };
              break;
            case "pastmonth":
              date = { label: "Past Month", group: "Months", value: getDate("Past Month") };
              break;
            default:
              break;
          }
        }
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date,
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };

  const defaultOptions = useSelector((state) => state.filterOptions.defaultOptions);
  const allCollectionOptions = useSelector((state) => state.filterOptions.allCollectionOptions);
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);
  const [buildingNameFilterOptions, setBuildingNameFilterOptions] = useState([]);
  const [departmentNameFilterOptions, setDepartmentNameFilterOptions] = useState([]);
  const [roomNameFilterOptions, setRoomNameFilterOptions] = useState([]);
  const [deviceNameFilterOptions, setDeviceNameFilterOptions] = useState([]);
  const [deviceAddressFilterOptions, setDeviceAddressFilterOptions] = useState([]);
  const [alertTypeFilterOptions, setAlertTypeFilterOptions] = useState([]);
  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "incidentCreated", index: 0 },
      { field: "timestamp", index: 1 },
      { field: "alertTypeName", index: 2 },
      { field: "roomName", index: 3 },
      // Room Message Line
      { field: "messageLine2", index: 4 },
      { field: "departmentName", index: 5 },
      // Department Message Line
      { field: "messageLine1", index: 6 },
      { field: "buildingName", index: 7 },
      { field: "locationName", index: 8 },
      { field: "deviceName", index: 9 },
      { field: "deviceAddress", index: 10 },
    ],
    columnWidths: [
      { field: "timestamp", width: 170 },
      { field: "alertTypeName", width: 200 },
      { field: "roomName", width: 250 },
      { field: "messageLine2", width: 150 },
      { field: "departmentName", width: 200 },
      { field: "messageLine1", width: 150 },
      { field: "buildingName", width: 200 },
      { field: "locationName", width: 200 },
      { field: "deviceName", width: 200 },
      { field: "deviceAddress", width: 140 },
    ],
    columnVisibility: {
      timestamp: true,
      alertTypeName: true,
      roomName: true,
      messageLine2: true,
      departmentName: true,
      messageLine1: true,
      buildingName: true,
      messageCode: true,
      locationName: true,
      deviceName: false,
      deviceAddress: false,
    },
  });

  const gridColumnDefs = [
    {
      field: "incidentCreated",
      type: "boolean",
      headerName: "Incident Report",
      headerClassName: "data-grid-column-header",
      cellClassName: "add-new-grid-cell",
      headerAlign: "center",
      align: "center",
      width: 125,
      renderCell: (cellValues) => (
        <GridActionMenu
          rowId={cellValues.row._id}
          itemName={cellValues.row.name}
          pathIndex={ALERTS}
          incidentCreated={cellValues.row.incidentCreated}
        />
      ),
      filterOperators: booleanOperators,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
      hidable: false,
    },
    {
      field: "timestamp",
      type: "date",
      headerName: "Date/Time",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "timestamp")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
        })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },
    renderSingleSelectField(
      "alertTypeName",
      "Alert Type",
      alertTypeFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "alertTypeName")[0].width
    ),
    renderSingleSelectField(
      "roomName",
      "Room",
      roomNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "roomName")[0].width
    ),
    {
      field: "messageLine2",
      type: "string",
      headerName: "Room Message Line",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "messageLine2")[0].width,
      filterOperators: stringOperators,
      renderCell: (cellValues) => cellValues.row.messageLine2?.toUpperCase(),
    },
    renderSingleSelectField(
      "departmentName",
      "Department",
      departmentNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "departmentName")[0].width
    ),
    {
      field: "messageLine1",
      type: "string",
      headerName: "Dept. Message Line",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "messageLine1")[0].width,
      filterOperators: stringOperators,
      renderCell: (cellValues) => cellValues.row.messageLine1?.toUpperCase(),
    },
    renderSingleSelectField(
      "buildingName",
      "Building",
      buildingNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "buildingName")[0].width
    ),
    renderSingleSelectField(
      "locationName",
      "Location",
      locationNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "locationName")[0].width
    ),
    renderSingleSelectField(
      "deviceName",
      "Device",
      deviceNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceName")[0].width
    ),
    renderSingleSelectField(
      "deviceAddress",
      "Device Address",
      deviceAddressFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "deviceAddress")[0].width
    ),
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });
  useEffect(() => {
    const allowedDrillFilterFields = ["locationId", "buildingId", "departmentId", "roomId", "deviceId"];
    const optionMap = {
      building: [buildingNameFilterOptions, setBuildingNameFilterOptions],
      department: [departmentNameFilterOptions, setDepartmentNameFilterOptions],
      room: [roomNameFilterOptions, setRoomNameFilterOptions],
      device: [deviceNameFilterOptions, setDeviceNameFilterOptions],
    };
    if (allCollectionOptions) {
      applyOptions(allowedDrillFilterFields, selectedFilters, allCollectionOptions, defaultOptions, optionMap);
    }
  }, [selectedFilters]);

  const defaultFilters = { items: createModel(defaultSelectedFilters()) };
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilters);
  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: alerts,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[ALERTS];
    setCurrentSortModel(currSortModel);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (defaultOptions) {
      setLocationNameFilterOptions(defaultOptions.location);
      setBuildingNameFilterOptions(defaultOptions.building);
      setDepartmentNameFilterOptions(defaultOptions.department);
      setRoomNameFilterOptions(defaultOptions.room);
      setDeviceNameFilterOptions(defaultOptions.device);
      if (alertTypeFilterOptions.length === 0) loadFilterOptionsFromAPI(alertTypes, setAlertTypeFilterOptions);
      if (deviceAddressFilterOptions.length === 0)
        loadFilterOptionsFromAPI(devices, setDeviceAddressFilterOptions, "deviceAddress", "deviceAddress");
    }
  }, [defaultOptions]);
  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    ) {
      refreshTable();
    }
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <AlertsDataGrid
            columnDefs={orderedColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedFilters={setSelectedFilters}
            handleExport={() => {
              fetchExportData(
                getExportURL({ currentFilterModel, currentSortModel, endpoint: ALERTS }),
                alerts,
                setSnackbarAlertState
              );
            }}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Location", "Building", "Department", "Room", "Alert Type", "Device", "Date/Time"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
