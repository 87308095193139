import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns-tz";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, collectors } from "../api/index";
import { routes } from "../nav/nav";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function CollectorForm({
  currentCollector,
  locationOptions,
  buildingOptions,
  departmentOptions,
  onLocationChange,
  onBuildingChange,
  onDepartmentChange,
  errorHandler,
  onFormPost,
  onNewCollector,
  onFormSubmit,
}) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, COLLECTORS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = collectors;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;

  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    isSaving: false,
    selectedItemChanged: false,
    errorExists: false,
    showUnsavedChangesModal: false,
    selectedDeployDate:
      currentCollector && !isNaN(Date.parse(currentCollector?.deployDate))
        ? new Date(currentCollector.deployDate)
        : new Date(),
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const [assignedBuilding, setAssignedBuilding] = useState({
    buildingId: currentCollector ? currentCollector.buildingId || "" : "",
    buildingName: currentCollector ? currentCollector.buildingObj?.name || "" : "",
  });

  const [assignedLocation, setAssignedLocation] = useState({
    locationId: currentCollector?.locationId || "",
    locationName: currentCollector ? currentCollector.buildingObj?.locationObj?.name || "" : "",
  });

  const [assignedDepartment, setAssignedDepartment] = useState({
    departmentId: currentCollector ? currentCollector.departmentId || "" : "",
    departmentName: currentCollector ? currentCollector?.departmentName || "" : "",
  });

  const onCollectorFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    let response = null;
    const controller = new AbortController();
    try {
      // update this to have the room values from the db
      const updatedCollector = {
        ...currentCollector,
        ...values,
      };
      if (currentCollector) response = await patchItemToAPI(collectors, updatedCollector, controller);
      else response = await postItemToAPI(collectors, updatedCollector, controller);
      if (!currentCollector) {
        setNewRecordId(response.id);
        onNewCollector(response.id);
        updatedCollector._id = response.id;
      }
      if (navigateToList) navigate(`${properties[COLLECTORS].path}`);
      else {
        onFormPost(updatedCollector);
        onFormSubmit(values.locationId, formState.selectedItemChanged);
        newFormState.selectedItemChanged = false;
        newFormState.errorExists = false;
        newFormState.showUnsavedChangesModal = false;
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentCollector : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Room", currentCollector?._id ? currentCollector : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  const locationNames = ["<Unassign>"];
  if (locationOptions.length > 0) locationOptions?.map((location) => locationNames.push(location.name));

  const buildingNames = ["<Unassign>"];
  if (buildingOptions.length > 0) buildingOptions?.map((building) => buildingNames.push(building.name));

  const departmentNames = ["<Unassign>"];
  if (departmentOptions?.length > 0) departmentOptions?.map((dept) => departmentNames.push(dept.name));

  function getRequiredFieldValues(fieldValues) {
    return [
      fieldValues.name,
      fieldValues.detail,
      fieldValues.spec,
      datetime.getISODateStringFromDate(formState.selectedDeployDate),
      fieldValues.macAddress,
    ];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = assignedBuilding.buildingId
      ? {
          ...fieldValues,
          _v: 0,
          locationId:
            !fieldValues.locationId && currentCollector?.locationId
              ? currentCollector.locationId
              : assignedLocation.locationId,
          locationName: assignedLocation.locationName,
          buildingId:
            !fieldValues.buildingId && currentCollector?.buildingId
              ? currentCollector.buildingId
              : assignedBuilding.buildingId,
          buildingName: assignedBuilding.buildingName,
          departmentId:
            !fieldValues.departmentId && currentCollector?.departmentId
              ? currentCollector.departmentId
              : assignedDepartment.departmentId,
          departmentName: assignedDepartment.departmentName,
          deployDate: datetime.getISODateStringFromDate(formState.selectedDeployDate),
        }
      : {
          ...fieldValues,
          _v: 0,
          buildingObj: null,
          buildingName: null,
          buildingId: null,
          locationName: null,
        };

    if (currentCollector) updatedFieldValues._id = currentCollector._id;
    else delete updatedFieldValues.id;
    return updatedFieldValues;
  }

  let titleBarText = "";
  if (!currentCollector?._id) titleBarText = `add new collector`;
  else if (userCanUpdate) titleBarText = `view/edit collector`;
  else titleBarText = `view collector`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Collector", currentCollector?._id ? currentCollector : null)
            : getItemSucccessMessage("Collector", newRecordId.length === 0 ? currentCollector : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          _id: currentCollector?._id || "",
          name: currentCollector?.name || "",
          spec: currentCollector?.spec || "",
          inactive: currentCollector?.inactive || false,
          comment: currentCollector?.comment || "",
          serialNumber: currentCollector?.serialNumber || "",
          detail: currentCollector?.detail || "",
          macAddress: currentCollector?.macAddress || "",
          make: currentCollector?.make || "",
          model: currentCollector?.model || "",
          locationId: currentCollector?.buildingObj?.locationObj?._id || assignedLocation.locationId,
          locationName: currentCollector?.buildingObj?.locationObj?.name || "",
          buildingId: currentCollector?.buildingId || assignedBuilding.buildingId,
          buildingName: currentCollector?.buildingObj?.name || "",
          departmentId: currentCollector?.departmentId || assignedDepartment.departmentId,
          departmentName: currentCollector?.departmentName || "",
        }}
        onSubmit={(values, helpers) => {
          onCollectorFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => setShowUnsavedChangesModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[COLLECTORS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onCollectorFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentCollector !== null} />
              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      {currentCollector && (
                        <div className="formik-field-readonly">
                          <Field
                            asComponent="text"
                            name="_id"
                            id="_id"
                            label="Collector ID"
                            placeholder="Collector ID"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                      )}
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentCollector ? "Enter " : ""}Collector Name`}
                          placeholder="Collector Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="make"
                          id="make"
                          label={`${
                            !currentCollector || values.make.trim().length === 0 ? "Enter" : ""
                          }  Collector Make`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("make") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="model"
                          id="model"
                          label={`${
                            !currentCollector || values.make.trim().length === 0 ? "Enter" : ""
                          }  Collector Model`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("model") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="serialNumber"
                          id="serialNumber"
                          placeholder="Serial Number"
                          label={`${!currentCollector ? "Enter " : ""}Serial Number`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("serialNumber") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="spec"
                          id="spec"
                          label={`${!currentCollector ? "Enter " : ""}Collector Specs`}
                          placeholder="Collector Specs"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("spec") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="detail"
                          id="detail"
                          label={`${!currentCollector ? "Enter " : ""}Collector Detail`}
                          placeholder="Collector Detail"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("detail") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="locationName"
                          id="locationName"
                          label={`${!currentCollector ? "Select a " : ""}Location`}
                          placeholder="Locations"
                          options={locationNames}
                          value={
                            locationNames.length > 0 && locationNames.find((l) => l === assignedLocation.locationName)
                              ? assignedLocation.locationName
                              : ""
                          }
                          onChange={(e) => {
                            const selectedLocation = locationOptions.find((l) => l.name === e.target?.value);
                            setAssignedLocation({
                              locationId: selectedLocation ? selectedLocation._id : "",
                              locationName: selectedLocation ? selectedLocation.name : "",
                            });
                            setAssignedBuilding({ buildingId: "", buildingName: "" });
                            const newFormState = { ...formState };
                            newFormState.selectedItemChanged = true;
                            newFormState.errorExists = false;
                            setFormState(newFormState);
                            onLocationChange(selectedLocation ? selectedLocation._id : "");
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("locationName") && <RequiredFieldIndicator />}
                      </div>

                      {(currentCollector || assignedLocation.locationId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="buildingName"
                            id="buildingName"
                            label={`${!currentCollector ? "Select a" : ""} Building`}
                            placeholder="Buildings"
                            options={buildingNames}
                            value={
                              buildingNames.length > 0 && buildingNames.find((b) => b === assignedBuilding.buildingName)
                                ? assignedBuilding.buildingName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedBuilding = buildingOptions.find((b) => b.name === e.target?.value);
                              setAssignedBuilding({
                                buildingId: selectedBuilding ? selectedBuilding._id : "",
                                buildingName: selectedBuilding ? selectedBuilding.name : "",
                              });
                              setAssignedDepartment({ departmentId: "", departmentName: "" });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                              onBuildingChange(selectedBuilding ? selectedBuilding._id : "");
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("buildingName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      {(currentCollector || assignedBuilding.buildingId) && (
                        <div className="formik-field">
                          <Field
                            asComponent="select"
                            className="selectField"
                            name="departmentName"
                            id="departmentName"
                            label={`${!currentCollector ? "Select a" : ""} Department`}
                            placeholder="Departments"
                            options={departmentNames}
                            value={
                              departmentNames.length > 0 &&
                              departmentNames.find((b) => b === assignedDepartment?.departmentName)
                                ? assignedDepartment?.departmentName
                                : ""
                            }
                            onChange={(e) => {
                              const selectedDepartment = departmentOptions.find((b) => b.name === e.target?.value);
                              setAssignedDepartment({
                                departmentId: selectedDepartment ? selectedDepartment._id : "",
                                departmentName: selectedDepartment ? selectedDepartment.name : "",
                              });
                              const newFormState = { ...formState };
                              newFormState.selectedItemChanged = true;
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                              onDepartmentChange(selectedDepartment ? selectedDepartment._id : "");
                            }}
                            InputProps={{
                              readOnly: !userCanUpdate,
                            }}
                          />
                          {requiredFieldNames.includes("departmentName") && <RequiredFieldIndicator />}
                        </div>
                      )}

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="macAddress"
                          id="macAddress"
                          label={`${
                            !currentCollector || values.macAddress.trim().length === 0 ? "Enter" : ""
                          }  MAC Address`}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("macAddress") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <Field
                              asComponent="dateField"
                              name="deployDate"
                              id="deployDate"
                              label="Deploy Date"
                              selectedDate={formState.selectedDeployDate}
                              onChange={(selectedDate) => {
                                const newFormState = { ...formState };
                                newFormState.selectedDeployDate = selectedDate;
                                newFormState.selectedItemChanged = true;
                                newFormState.errorExists = false;
                                setFormState(newFormState);
                              }}
                              InputProps={{
                                readOnly: !userCanUpdate,
                              }}
                            />
                          ) : (
                            <Field
                              asComponent="text"
                              name="deployDate"
                              id="deployDate"
                              value={format(formState?.selectedDeployDate, "MMMM d, yyyy")}
                              label="Deploy Date"
                              InputProps={{
                                readOnly: !userCanUpdate,
                              }}
                            />
                          )}
                          {requiredFieldNames.includes("deployDate") && <RequiredFieldIndicator />}
                        </div>
                        {requiredFieldNames.includes("deployDate") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          label={`${
                            !currentCollector || values.comment.trim().length === 0 ? "Enter" : ""
                          } Collector Comments`}
                          name="comment"
                          id="comment"
                          placeholder="Collector Comments"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>

                      {currentCollector && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  const newFormState = { ...formState };
                                  newFormState.errorExists = false;
                                  setFormState(newFormState);
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentCollector.inactive ? "Yes" : "No"}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentCollector && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentCollector.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentCollector.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <FormikFormFooterBar
                currentItem={currentCollector}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.selectedItemChanged)
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.selectedItemChanged}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.selectedItemChanged || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[COLLECTORS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

CollectorForm.propTypes = {
  currentCollector: PropTypes.object,
  locationOptions: PropTypes.array,
  buildingOptions: PropTypes.array,
  departmentOptions: PropTypes.array,
  onLocationChange: PropTypes.func,
  onBuildingChange: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
  onNewCollector: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

export default CollectorForm;
