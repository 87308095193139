export const incidentForm = {
  adverseMedicalEventHarmScaleOptions: [
    "1. Unsafe Condition",
    "2. Near Miss",
    "3. No Harm",
    "4. Mild Harm",
    "5. Moderate Harm",
    "6. Severe Harm",
    "7. Death",
  ],

  incidentInvolvementOptions: [
    "Employees",
    "A Visitor and Employee(s)",
    "Patient and Employee(s)",
    "A Vendor and Employee(s)",
    "Patients",
  ],

  typeOfIncidentOptions: ["Physical Abuse", "Verbal Abuse", "Other"],

  otherNotificationOptions: ["Law Enforcement", "Hospital Administrator On Duty", "Medical Director"],
};

export const locationForm = {
  locationTypeOptions: ["Building", "Campus"],
};

export const buildingForm = {
  systemTypeOptions: ["Standard", "XR"],
};

export const displayForm = {
  displayTypeOptions: ["PC", "Mobile", "Tablet"],
};
