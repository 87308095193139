import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faCaretRight,
  faCaretDown,
  faHospitalUser,
  faHospital,
  faCity,
  faSquare,
  faCheckSquare,
  faMinusSquare,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Spinner from "../components/atoms/spinner/Spinner";
import FormikFormTitleBar from "./formikFormTitleBar";
import { userNotifications } from "../api";
import { errorHandler } from "../app/errorHandlers";

function UserNotificationsForm({
  currentUser,
  userCanUpdate,
  mobileNumberIsValid,
  mobileNumberValidated,
  setModalIsOpen,
  setSnackbarAlertState,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [userNotificationsList, setUserNotificationsList] = useState([]);
  const [touchedUserNotificationList, setTouchedUserNotificationList] = useState([]);
  const [expandAll, setExpandAll] = useState(true);
  const [expandedLocationRows, setExpandedLocationRows] = useState([]);
  const [expandedBuildingRows, setExpandedBuildingRows] = useState([]);
  const [touchedDepartmentRows, setTouchedDepartmentRows] = useState({ Email: [], SMS: [] });
  const [selectedDepartmentRows, setSelectedDepartmentRows] = useState({
    Email: [],
    SMS: [],
  });
  const horizontalBorders = {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  };
  const iconStyle = {
    marginRight: "8px",
    opacity: "0.9",
    borderRadius: "2px",
  };
  const iconCellStyle = { padding: "0px", fontSize: ".80rem" };
  const textCellStyle = { padding: "0px", fontSize: ".85rem" };
  const iconButtonStyleProps = { fontSize: "1.2rem", cursor: "default" };

  async function loadNotificationSubscriptionsFromAPI(userId, controller) {
    try {
      const response = await userNotifications.list(userId, controller.signal);
      const emailDepartments = [];
      const smsDepartments = [];
      if (response?.length > 0)
        response.map((l) =>
          l.buildings.map((b) =>
            b.departments.map((d) => {
              if (d.email) emailDepartments.push(d._id);
              if (d.sms) smsDepartments.push(d._id);
              return true;
            })
          )
        );
      setUserNotificationsList(response);
      setSelectedDepartmentRows({ Email: emailDepartments, SMS: smsDepartments });
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function upsertNotificationSubscriptionsToAPI(data, controller) {
    const newSnackbarState = { open: true };
    try {
      const success = await userNotifications.upsert(currentUser._id, data, controller.signal);
      if (success) {
        newSnackbarState.alertMessage = "User Notification Settings updated successfully.";
        newSnackbarState.alertSeverity = "success";
      }
    } catch (error) {
      newSnackbarState.alertMessage = "Error updating User Notification Settings.";
      newSnackbarState.alertSeverity = "error";
      errorHandler(error);
    } finally {
      setSnackbarAlertState(newSnackbarState);
      setIsLoading(false);
      setModalIsOpen(false);
    }
  }

  const expandAllRows = () => {
    const newExpandedBuildingRows = [];
    const newexpandedLocationRows = [];
    userNotificationsList.map((l) => {
      newexpandedLocationRows.push(l._id);
      l.buildings.map((b) => {
        newExpandedBuildingRows.push(b._id);
        return true;
      });
      return true;
    });
    setExpandedBuildingRows(newExpandedBuildingRows);
    setExpandedLocationRows(newexpandedLocationRows);
  };

  const collapseAllRows = () => {
    setExpandedBuildingRows([]);
    setExpandedLocationRows([]);
  };

  const onLocationCheckboxClick = (location, checkbox) => {
    const alertType = checkbox?.name.substring(0, checkbox?.name.indexOf("-"));
    const newSelectedDepartmentRows = { ...selectedDepartmentRows };
    const newTouchedDepartmentRows = { ...touchedDepartmentRows };
    location.buildings.map((b) => {
      // Keeps track of touched locations
      if (!touchedUserNotificationList.includes(location)) {
        setTouchedUserNotificationList([...touchedUserNotificationList, location]);
      }
      b.departments.map((d) => {
        if (checkbox.checked) {
          if (
            alertType === "email" &&
            (!newTouchedDepartmentRows.Email.includes(d._id) || !newSelectedDepartmentRows.Email.includes(d._id))
          ) {
            newSelectedDepartmentRows.Email.push(d._id);
            newTouchedDepartmentRows.Email.push(d._id);
          } else if (
            alertType === "sms" &&
            (!newTouchedDepartmentRows.SMS.includes(d._id) || !newSelectedDepartmentRows.SMS.includes(d._id))
          ) {
            newSelectedDepartmentRows.SMS.push(d._id);
            newTouchedDepartmentRows.SMS.push(d._id);
          }
        } else if (!checkbox.checked) {
          if (alertType === "email") {
            newSelectedDepartmentRows.Email = newSelectedDepartmentRows.Email.filter((dr) => dr !== d._id);
            newTouchedDepartmentRows.Email = newTouchedDepartmentRows.Email.filter((dr) => dr !== d._id);
          } else if (alertType === "sms") {
            newSelectedDepartmentRows.SMS = newSelectedDepartmentRows.SMS.filter((dr) => dr !== d._id);
            newTouchedDepartmentRows.SMS = newTouchedDepartmentRows.SMS.filter((dr) => dr !== d._id);
          }
        }
        return true;
      });
      return true;
    });

    setSelectedDepartmentRows(newSelectedDepartmentRows);
    setTouchedDepartmentRows(newTouchedDepartmentRows);
  };

  const onBuildingCheckboxClick = (location, building, checkbox) => {
    const alertType = checkbox?.name.substring(0, checkbox?.name.indexOf("-"));
    const newSelectedDepartmentRows = { ...selectedDepartmentRows };

    // Manages which buildings have been touched so we can update them in DB later
    const newTouchedDepartmentRows = { ...touchedDepartmentRows };

    if (!touchedUserNotificationList.includes(location)) {
      setTouchedUserNotificationList([...touchedUserNotificationList, location]);
    }

    building.departments.map((d) => {
      if (checkbox.checked) {
        if (
          alertType === "email" &&
          (!newSelectedDepartmentRows.Email.includes(d._id) || !newTouchedDepartmentRows.Email.includes(d._id))
        ) {
          newSelectedDepartmentRows.Email.push(d._id);
          newTouchedDepartmentRows.Email.push(d._id);
        } else if (
          alertType === "sms" &&
          (!newSelectedDepartmentRows.SMS.includes(d._id) || !newTouchedDepartmentRows.SMS.includes(d._id))
        ) {
          newSelectedDepartmentRows.SMS.push(d._id);
          newTouchedDepartmentRows.SMS.push(d._id);
        }
      } else if (!checkbox.checked) {
        if (alertType === "email") {
          newSelectedDepartmentRows.Email = newSelectedDepartmentRows.Email.filter((dr) => dr !== d._id);
          newTouchedDepartmentRows.Email = newTouchedDepartmentRows.Email.filter((dr) => dr !== d._id);
        } else if (alertType === "sms") {
          newSelectedDepartmentRows.SMS = newSelectedDepartmentRows.SMS.filter((dr) => dr !== d._id);
          newTouchedDepartmentRows.SMS = newTouchedDepartmentRows.SMS.filter((dr) => dr !== d._id);
        }
      }
      return true;
    });

    setSelectedDepartmentRows(newSelectedDepartmentRows);
    setTouchedDepartmentRows(newTouchedDepartmentRows);
  };

  const onDepartmentCheckboxClick = (location, department, checkbox) => {
    const alertType = checkbox?.name.substring(0, checkbox?.name.indexOf("-"));
    const newSelectedDepartmentRows = { ...selectedDepartmentRows };

    // Manages which buildings have been touched so we can update them in DB later
    const newTouchedDepartmentRows = { ...touchedDepartmentRows };
    let alertTypeDepartmentRows = [];

    if (!touchedUserNotificationList.includes(location)) {
      setTouchedUserNotificationList([...touchedUserNotificationList, location]);
    }

    if (alertType === "email") {
      alertTypeDepartmentRows = [...selectedDepartmentRows.Email];
      newSelectedDepartmentRows.Email = [];
      newTouchedDepartmentRows.Email = [];
    } else if (alertType === "sms") {
      alertTypeDepartmentRows = [...selectedDepartmentRows.SMS];
      newSelectedDepartmentRows.SMS = [];
      newTouchedDepartmentRows.SMS = [];
    }
    checkbox.checked
      ? alertTypeDepartmentRows.push(department._id)
      : (alertTypeDepartmentRows = alertTypeDepartmentRows.filter((dr) => dr !== department._id));

    if (alertTypeDepartmentRows.length > 0)
      alertTypeDepartmentRows.map((ar) => {
        if (alertType === "email") {
          newSelectedDepartmentRows.Email.push(ar);
          newTouchedDepartmentRows.Email.push(ar);
        } else if (alertType === "sms") {
          newSelectedDepartmentRows.SMS.push(ar);
          newTouchedDepartmentRows.SMS.push(ar);
        }
        return true;
      });

    setSelectedDepartmentRows(newSelectedDepartmentRows);
    setTouchedDepartmentRows(newTouchedDepartmentRows);
  };

  const allBuildingsAndDeptsSelected = (location, alertType) => {
    const { Email, SMS } = selectedDepartmentRows;
    let result = true;
    if (location.buildings.length > 0)
      location.buildings.map((b) => {
        if (b.departments.length > 0)
          b.departments.map((d) => {
            if (result && alertType === "email") result = Email.includes(d._id);
            else if (result && alertType === "sms") result = SMS.includes(d._id);
            return true;
          });
        return true;
      });
    return result;
  };

  const allBuildingsAndDeptsIndeterminate = (location, alertType) => {
    const { Email, SMS } = selectedDepartmentRows;
    const allBuildingDepartments = [];
    location.buildings.map((b) => b.departments.map((d) => allBuildingDepartments.push(d._id)));
    let selectedDepartments = [];
    if (alertType === "email") selectedDepartments = Email.filter((x) => allBuildingDepartments.includes(x));
    else if (alertType === "sms") selectedDepartments = SMS.filter((x) => allBuildingDepartments.includes(x));
    return selectedDepartments?.length > 0 && selectedDepartments?.length < allBuildingDepartments.length;
  };

  const getAllBuildingsAndDeptsReadOnlyIcon = (l, alertType) => {
    if (allBuildingsAndDeptsSelected(l, alertType)) return faCheckSquare;
    if (allBuildingsAndDeptsIndeterminate(l, alertType)) return faMinusSquare;
    return faSquare;
  };

  const allDeptsSelected = (building, alertType) => {
    const { Email, SMS } = selectedDepartmentRows;
    let result = building.departments.length > 0;
    if (result)
      building.departments.map((d) => {
        if (result && alertType === "email") result = Email.includes(d._id);
        else if (result && alertType === "sms") result = SMS.includes(d._id);
        return true;
      });
    return result;
  };

  const allDepartmentsIndeterminate = (building, alertType) => {
    const { Email, SMS } = selectedDepartmentRows;
    const buildingDepartments = [];
    building.departments.map((d) => buildingDepartments.push(d._id));
    let selectedDepartments = [];
    if (alertType === "email") selectedDepartments = Email.filter((x) => buildingDepartments.includes(x));
    else if (alertType === "sms") selectedDepartments = SMS.filter((x) => buildingDepartments.includes(x));
    return selectedDepartments?.length > 0 && selectedDepartments?.length < building.departments.length;
  };

  const getAllDeptsReadOnlyIcon = (b, alertType) => {
    if (allDeptsSelected(b, alertType)) return faCheckSquare;
    if (allDepartmentsIndeterminate(b, alertType)) return faMinusSquare;
    return faSquare;
  };

  const getNotificationRecord = (departmentId, getEmail, getSMS) => ({
    inactive: false,
    userId: currentUser?._id,
    departmentId,
    email: getEmail,
    sms: getSMS,
    pc: false,
    pushNotification: false,
  });

  const getReadOnlyLocationIconStyle = (location) => {
    let iconColor = "#F2F4F4";
    if (
      allBuildingsAndDeptsSelected(location, "sms") ||
      (allBuildingsAndDeptsIndeterminate(location, "sms") && mobileNumberValidated)
    )
      iconColor = mobileNumberValidated ? "blue" : "red";
    if (
      !(
        allBuildingsAndDeptsSelected(location, "sms") ||
        (allBuildingsAndDeptsIndeterminate(location, "sms") && mobileNumberValidated)
      )
    )
      iconColor = !mobileNumberValidated ? "red" : "#F2F4F4";
    return {
      ...iconStyle,
      color: iconColor,
      border:
        !mobileNumberValidated ||
        allBuildingsAndDeptsSelected(location, "sms") ||
        allBuildingsAndDeptsIndeterminate(location, "sms")
          ? "none"
          : "2px solid black",
      width:
        !mobileNumberValidated ||
        allBuildingsAndDeptsSelected(location, "sms") ||
        allBuildingsAndDeptsIndeterminate(location, "sms")
          ? "20px"
          : "",
      height:
        !mobileNumberValidated ||
        allBuildingsAndDeptsSelected(location, "sms") ||
        allBuildingsAndDeptsIndeterminate(location, "sms")
          ? "20px"
          : "",
    };
  };

  const getReadOnlyDepartmentIconStyle = (department) => {
    let iconColor = "#FFFFFF";
    let borderValue = "none";
    if (!(department.sms || selectedDepartmentRows.SMS.includes(department._id))) iconColor = "#FFFFFF";
    else iconColor = mobileNumberValidated ? "blue" : "red";
    if (!(department.sms || selectedDepartmentRows.SMS.includes(department._id)))
      borderValue = mobileNumberValidated ? "2px solid black" : "2px solid red";
    return {
      ...iconStyle,
      marginLeft: mobileNumberValidated ? "" : "4px",
      backgroundColor: "#FFFFFF",
      color: iconColor,
      border: borderValue,
      width: department.sms || selectedDepartmentRows.SMS.includes(department._id) ? "20px" : "",
      height: department.sms || selectedDepartmentRows.SMS.includes(department._id) ? "20px" : "",
    };
  };

  const handleSubmit = () => {
    const bothMethods = touchedDepartmentRows.Email.filter((n) => touchedDepartmentRows.SMS.includes(n));
    const emailOnly = touchedDepartmentRows.Email.filter((x) => !touchedDepartmentRows.SMS.includes(x));
    const smsOnly = touchedDepartmentRows.SMS.filter((x) => !touchedDepartmentRows.Email.includes(x));
    const notificationData = [];

    setIsLoading(true);

    if (bothMethods.length === 0 && emailOnly.length === 0 && smsOnly.length === 0)
      touchedUserNotificationList.map((l) => {
        l.buildings.map((b) => {
          b.departments.map((d) => {
            if (d.email || d.sms) notificationData.push(getNotificationRecord(d._id, false, false));
            return true;
          });
          return true;
        });
        return true;
      });
    else
      touchedUserNotificationList.map((l) => {
        l.buildings.map((b) => {
          b.departments.map((d) => {
            notificationData.push(
              getNotificationRecord(
                d._id,
                bothMethods.includes(d._id) || emailOnly.includes(d._id),
                bothMethods.includes(d._id) || smsOnly.includes(d._id)
              )
            );
            return true;
          });
          return true;
        });
        return true;
      });

    upsertNotificationSubscriptionsToAPI(notificationData, new AbortController());
  };

  useEffect(() => {
    if (currentUser && userNotificationsList.length === 0)
      loadNotificationSubscriptionsFromAPI(currentUser._id, new AbortController());
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="InvolvedPartyForm">
      <FormikFormTitleBar titleText={`User Notifications [${currentUser.name}]`} editMode={false} icon={faWrench} />

      <div className="formik-field-view">
        <div>
          <TableContainer style={{ maxHeight: "600px" }}>
            {/* stickyHeader attribute affects table styling - unable to reslove
            SEE: https://stackoverflow.com/questions/66031955/how-to-apply-css-on-table-header-of-material-ui-table-stickyheader */}
            <Table aria-label="User Notifications">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#ff5e14",
                    border: "1px solid black",
                    "& th": { color: "#ffffff" },
                  }}
                >
                  <TableCell style={{ paddingLeft: "18px", borderBottom: "1px solid black" }}>
                    <IconButton
                      title={expandAll ? "Expand All" : "Collapse All"}
                      onClick={() => {
                        expandAll ? expandAllRows() : collapseAllRows();
                        setExpandAll(!expandAll);
                      }}
                      style={{
                        paddingLeft: "0px",
                        color: "#ffffff",
                        cursor: userNotificationsList.length > 0 ? "pointer" : "default",
                      }}
                    >
                      <FontAwesomeIcon icon={expandAll ? faCaretRight : faCaretDown} />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ paddingLeft: "0px", borderBottom: "1px solid black" }}>
                    <b>Location</b>
                  </TableCell>

                  <TableCell style={{ paddingLeft: "10px", borderBottom: "1px solid black" }}>
                    <b>Email Alerts</b>
                  </TableCell>

                  {(mobileNumberIsValid || mobileNumberValidated) && (
                    <TableCell style={{ paddingLeft: "10px", borderBottom: "1px solid black" }}>
                      <b>SMS Alerts</b>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {userNotificationsList.map((l) => (
                  <Fragment key={l._id}>
                    {/* LOCATION row */}
                    <TableRow
                      key={l._id}
                      style={{
                        backgroundColor: "#EAECEE",
                        display: l.buildings?.length > 0 ? "" : "none",
                        padding: "0px",
                        ...horizontalBorders,
                      }}
                    >
                      <TableCell style={{ width: "110px", padding: "0px" }}>
                        <IconButton
                          title={l.name}
                          sx={{ ...iconButtonStyleProps }}
                          style={{ marginLeft: "8px", opacity: "0.9" }}
                        >
                          <FontAwesomeIcon icon={faCity} />
                        </IconButton>
                        {l.buildings.length > 0 && (
                          <IconButton
                            title={expandedLocationRows.includes(l._id) ? "Collapse Location" : "Expand Location"}
                            onClick={() => {
                              let newExpandedLocationRows = [...expandedLocationRows];
                              let newExpandedBuildingRows = [...expandedBuildingRows];
                              if (!expandedLocationRows.includes(l._id)) newExpandedLocationRows.push(l._id);
                              else {
                                newExpandedLocationRows = expandedLocationRows.filter((lr) => lr !== l._id);
                                l.buildings.map((b) => {
                                  newExpandedBuildingRows = newExpandedBuildingRows.filter((lr) => lr !== b._id);
                                  return true;
                                });
                              }
                              setExpandedLocationRows(newExpandedLocationRows);
                              setExpandedBuildingRows(newExpandedBuildingRows);
                            }}
                            sx={{ fontSize: "1.2rem" }}
                            style={{
                              paddingLeft: "0px",
                              cursor: l.buildings.length > 0 ? "pointer" : "default",
                            }}
                          >
                            <FontAwesomeIcon icon={expandedLocationRows.includes(l._id) ? faCaretDown : faCaretRight} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell style={{ ...textCellStyle }}>{l.name || ""}</TableCell>
                      <TableCell
                        style={{
                          width: mobileNumberIsValid || mobileNumberValidated ? "400px" : "700px",
                          ...iconCellStyle,
                        }}
                      >
                        {userCanUpdate ? (
                          <Checkbox
                            id={`email-${l._id}`}
                            name={`email-${l._id}`}
                            value={l.id}
                            onClick={(e) => onLocationCheckboxClick(l, e.target)}
                            checked={allBuildingsAndDeptsSelected(l, "email")}
                            indeterminate={allBuildingsAndDeptsIndeterminate(l, "email")}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={getAllBuildingsAndDeptsReadOnlyIcon(l, "email")}
                            style={{
                              ...iconStyle,
                              color:
                                allBuildingsAndDeptsSelected(l, "email") ||
                                allBuildingsAndDeptsIndeterminate(l, "email")
                                  ? "blue"
                                  : "#F2F4F4",
                              border:
                                allBuildingsAndDeptsSelected(l, "email") ||
                                allBuildingsAndDeptsIndeterminate(l, "email")
                                  ? "none"
                                  : "2px solid black",
                              width:
                                allBuildingsAndDeptsSelected(l, "email") ||
                                allBuildingsAndDeptsIndeterminate(l, "email")
                                  ? "20px"
                                  : "",
                              height:
                                allBuildingsAndDeptsSelected(l, "email") ||
                                allBuildingsAndDeptsIndeterminate(l, "email")
                                  ? "20px"
                                  : "",
                            }}
                          />
                        )}
                        All Buildings/Depts
                      </TableCell>
                      {(mobileNumberIsValid || mobileNumberValidated) && (
                        <TableCell style={{ width: "300px", ...iconCellStyle }}>
                          {userCanUpdate && mobileNumberValidated ? (
                            <Checkbox
                              id={`sms-${l._id}`}
                              name={`sms-${l._id}`}
                              value={l.id}
                              onClick={(e) => onLocationCheckboxClick(l, e.target)}
                              checked={allBuildingsAndDeptsSelected(l, "sms")}
                              indeterminate={allBuildingsAndDeptsIndeterminate(l, "sms")}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={!mobileNumberValidated ? faWarning : getAllBuildingsAndDeptsReadOnlyIcon(l, "sms")}
                              style={getReadOnlyLocationIconStyle(l)}
                            />
                          )}
                          <span style={{ color: mobileNumberValidated ? "" : "red" }}>
                            {mobileNumberValidated ? "All Buildings/Departments" : "Mobile number not validated"}
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                    {l.buildings.map((b) => (
                      <Fragment key={b._id}>
                        {/* BUILDING rows */}
                        <TableRow
                          key={b._id}
                          style={{
                            display:
                              expandedLocationRows.includes(b.locationId) && b.departments?.length > 0 ? "" : "none",
                            backgroundColor: "#F2F4F4",
                            ...horizontalBorders,
                          }}
                        >
                          <TableCell style={{ ...textCellStyle }}>
                            <IconButton
                              title={b.name}
                              sx={{ ...iconButtonStyleProps }}
                              style={{ marginLeft: "34px", opacity: "0.9" }}
                            >
                              <FontAwesomeIcon icon={faHospital} />
                            </IconButton>

                            {b.departments.length > 0 && (
                              <IconButton
                                id="remove-party-btn"
                                title={expandedBuildingRows.includes(b._id) ? "Collapse Building" : "Expand Building"}
                                onClick={() => {
                                  let newExpandedBuildingRows = [...expandedBuildingRows];
                                  if (!expandedBuildingRows.includes(b._id)) newExpandedBuildingRows.push(b._id);
                                  else newExpandedBuildingRows = expandedBuildingRows.filter((br) => br !== b._id);
                                  setExpandedBuildingRows(newExpandedBuildingRows);
                                }}
                                sx={{ fontSize: "1.2rem" }}
                                style={{ paddingLeft: "0px" }}
                              >
                                <FontAwesomeIcon
                                  icon={expandedBuildingRows.includes(b._id) ? faCaretDown : faCaretRight}
                                />
                              </IconButton>
                            )}
                          </TableCell>

                          <TableCell style={{ ...textCellStyle }}>{b.name || ""}</TableCell>

                          <TableCell style={{ ...iconCellStyle, paddingLeft: "24px" }}>
                            {userCanUpdate ? (
                              <Checkbox
                                id={`email-${b._id}`}
                                name={`email-${b._id}`}
                                value={b.id}
                                onClick={(e) => onBuildingCheckboxClick(l, b, e.target)}
                                checked={allDeptsSelected(b, "email")}
                                indeterminate={allDepartmentsIndeterminate(b, "email")}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={getAllDeptsReadOnlyIcon(b, "email")}
                                style={{
                                  ...iconStyle,
                                  backgroundColor: "ffffff",
                                  color:
                                    allDeptsSelected(b, "email") || allDepartmentsIndeterminate(b, "email")
                                      ? "blue"
                                      : "#F2F4F4",
                                  border:
                                    allDeptsSelected(b, "email") || allDepartmentsIndeterminate(b, "email")
                                      ? "none"
                                      : "2px solid black",
                                  width:
                                    allDeptsSelected(b, "email") || allDepartmentsIndeterminate(b, "email")
                                      ? "20px"
                                      : "",
                                  height:
                                    allDeptsSelected(b, "email") || allDepartmentsIndeterminate(b, "email")
                                      ? "20px"
                                      : "",
                                }}
                              />
                            )}
                            All Departments
                          </TableCell>
                          {(mobileNumberIsValid || mobileNumberValidated) && (
                            <TableCell
                              style={{
                                ...iconCellStyle,
                                paddingLeft: "24px",
                              }}
                            >
                              {userCanUpdate && mobileNumberValidated ? (
                                <Checkbox
                                  id={`sms-${b._id}`}
                                  name={`sms-${b._id}`}
                                  value={b.id}
                                  onClick={(e) => onBuildingCheckboxClick(l, b, e.target)}
                                  checked={allDeptsSelected(b, "sms")}
                                  indeterminate={allDepartmentsIndeterminate(b, "sms")}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={getAllDeptsReadOnlyIcon(b, "sms")}
                                  style={{
                                    ...iconStyle,
                                    backgroundColor: "ffffff",
                                    color:
                                      allDeptsSelected(b, "sms") || allDepartmentsIndeterminate(b, "sms")
                                        ? "blue"
                                        : "#F2F4F4",
                                    border:
                                      allDeptsSelected(b, "sms") || allDepartmentsIndeterminate(b, "sms")
                                        ? "none"
                                        : "2px solid black",
                                    width:
                                      allDeptsSelected(b, "sms") || allDepartmentsIndeterminate(b, "sms") ? "20px" : "",
                                    height:
                                      allDeptsSelected(b, "sms") || allDepartmentsIndeterminate(b, "sms") ? "20px" : "",
                                    display: mobileNumberValidated ? "" : "none",
                                  }}
                                />
                              )}
                              <span
                                style={{
                                  color: mobileNumberValidated ? "" : "red",
                                  paddingLeft: mobileNumberValidated ? "" : "4px",
                                }}
                              >
                                {mobileNumberValidated ? "All Departments" : "Mobile number not validated"}
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                        {b.departments?.map((d) => (
                          // DEPARTMENT rows
                          <TableRow
                            key={d._id}
                            sx={{ "&:last-child td, &:last-child th": { borderBottom: "1px solid black" } }}
                            style={{
                              backgroundColor: "#ffffff",
                              display:
                                expandedBuildingRows.includes(d.buildingId) && expandedLocationRows.includes(l._id)
                                  ? ""
                                  : "none",
                              ...horizontalBorders,
                            }}
                          >
                            <TableCell style={{ ...iconCellStyle, paddingLeft: "4px" }}>
                              <IconButton
                                title={d.name}
                                sx={{ ...iconButtonStyleProps }}
                                style={{ marginLeft: "62px", opacity: "0.9" }}
                              >
                                <FontAwesomeIcon icon={faHospitalUser} />
                              </IconButton>
                            </TableCell>
                            <TableCell style={{ ...textCellStyle }}>{d.name || ""}</TableCell>
                            <TableCell style={{ ...iconCellStyle, paddingLeft: "24px" }}>
                              {userCanUpdate ? (
                                <Checkbox
                                  id={`email-${d._id}`}
                                  name={`email-${d._id}`}
                                  value={d._id}
                                  checked={selectedDepartmentRows.Email.includes(d._id)}
                                  onClick={(e) => onDepartmentCheckboxClick(l, d, e.target)}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={allDeptsSelected(b, "email") ? faCheckSquare : faSquare}
                                  style={{
                                    ...iconStyle,
                                    backgroundColor: "ffffff",
                                    color: d.email || selectedDepartmentRows.Email.includes(d._id) ? "blue" : "#F2F4F4",
                                    border:
                                      d.email || selectedDepartmentRows.Email.includes(d._id)
                                        ? "none"
                                        : "2px solid black",
                                    width: d.email || selectedDepartmentRows.Email.includes(d._id) ? "20px" : "",
                                    height: d.email || selectedDepartmentRows.Email.includes(d._id) ? "20px" : "",
                                  }}
                                />
                              )}
                            </TableCell>
                            {(mobileNumberIsValid || mobileNumberValidated) && (
                              <TableCell
                                style={{
                                  ...iconCellStyle,
                                  paddingLeft: "24px",
                                }}
                              >
                                {userCanUpdate && mobileNumberValidated ? (
                                  <Checkbox
                                    id={`sms-${d._id}`}
                                    name={`sms-${d._id}`}
                                    value={d._id}
                                    checked={selectedDepartmentRows.SMS.includes(d._id)}
                                    onClick={(e) => onDepartmentCheckboxClick(l, d, e.target)}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={
                                      d.sms || selectedDepartmentRows.SMS.includes(d._id) ? faCheckSquare : faSquare
                                    }
                                    style={getReadOnlyDepartmentIconStyle(d)}
                                  />
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="InvolvedPartyForm-btn">
        <Button
          className="cancel-btn"
          variant="outlined"
          onClick={() => {
            setModalIsOpen(false);
          }}
        >
          Cancel
        </Button>
        {userCanUpdate && (
          <Button className="config-settings-btn" onClick={handleSubmit} type="button" title="Apply">
            Apply
          </Button>
        )}
      </div>
    </div>
  );
}

UserNotificationsForm.propTypes = {
  currentUser: PropTypes.object,
  userCanUpdate: PropTypes.bool,
  mobileNumberIsValid: PropTypes.bool,
  mobileNumberValidated: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  setSnackbarAlertState: PropTypes.func,
};

export default UserNotificationsForm;
