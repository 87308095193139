import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

const inactiveOptions = [
  { label: "Inactive", value: "true" },
  { label: "Active", value: "false" },
];
const deviceStatusOptions = [
  { label: "Online", value: "online" },
  { label: "Offline", value: "offline" },
  { label: "No Polling Response", value: "noPollingResponse" },
];
export default function GeneralFilterField({ filterOptions, headerName, setFieldValue, selectedFilter, field }) {
  if (filterOptions[0]?.group) {
    filterOptions = [...filterOptions].sort((a, b) => {
      const groupA = a.group.toLowerCase();
      const groupB = b.group.toLowerCase();
      if (groupA < groupB) {
        return -1;
      }
      if (groupA > groupB) {
        return 1;
      }
      return 0;
    });
  }
  if (field === "inactive") filterOptions = inactiveOptions;
  if (field === "deviceStatus") filterOptions = deviceStatusOptions;
  if (filterOptions?.length === 0) {
    return (
      <Autocomplete
        id={headerName}
        className={headerName}
        freeSolo
        fullWidth
        autoSelect
        options={[]}
        sx={{ width: 400 }}
        value={selectedFilter[0]?.label || ""}
        renderInput={(params) => <TextField {...params} label={headerName} />}
        onChange={(e, value) => {
          const valModel = [];
          if (value !== null) valModel.push({ value, label: value });
          setFieldValue(field, valModel);
        }}
      />
    );
  }
  return (
    <Autocomplete
      id={headerName}
      className={headerName}
      multiple
      disableCloseOnSelect
      groupBy={(option) => option.group}
      filterSelectedOptions
      fullWidth
      autoHighlight
      options={filterOptions}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      renderGroup={(params) => (
        <Box key={params.key}>
          <Box component="div" sx={{ fontWeight: "light", color: "gray", m: "0.5rem" }}>
            {filterOptions[params.key].defaultGroup && (
              <Typography fontSize="1.05rem">{filterOptions[params.key].defaultGroup}</Typography>
            )}
            <Typography fontSize="1.0rem" sx={{ ml: ".8rem", mt: ".8rem" }}>
              {params.group}
            </Typography>
          </Box>
          <Typography fontSize=".9rem" sx={{ ml: ".8rem" }}>
            {params.children}
          </Typography>
        </Box>
      )}
      sx={{ width: 400 }}
      value={selectedFilter}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label={headerName} />}
      onChange={(e, value) => {
        setFieldValue(field, value);
      }}
    />
  );
}

GeneralFilterField.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  headerName: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  selectedFilter: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};
