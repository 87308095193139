import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, clients } from "../api/index";
import { routes } from "../nav/nav";
import { forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";
import Spinner from "../components/atoms/spinner/Spinner";

function ClientForm({ currentClient, errorHandler, onFormPost }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, CLIENTS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const { requiredFieldNames } = clients;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;

  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    isSaving: false,
    errorExists: false,
    showUnsavedChangesModal: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  const warningFontStyle = {
    fontSize: "0.75em",
    color: "red",
  };

  function domainNameValid(domainName) {
    if (domainName?.trim().length === 0) return true;
    return domainName?.trim().match(/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/gm);
  }

  const onClientFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;

    try {
      const updatedClient = {
        ...currentClient,
        ...values,
      };
      if (currentClient) response = await patchItemToAPI(clients, updatedClient, controller);
      else response = await postItemToAPI(clients, updatedClient, controller);
      if (!currentClient) {
        setNewRecordId(response.id);
        updatedClient._id = response.id;
      }
      if (navigateToList) navigate(`${properties[CLIENTS].path}`);
      else {
        onFormPost(updatedClient);
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Client",
          newRecordId.length === 0 ? currentClient : null
        );
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Client", currentClient?._id ? currentClient : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [
      fieldValues.name,
      fieldValues.street1,
      fieldValues.city,
      fieldValues.state,
      fieldValues.zipcode,
      fieldValues.country,
    ];
  }

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Client", currentClient?._id ? currentClient : null)
            : getItemSucccessMessage("Client", newRecordId.length === 0 ? currentClient : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />

      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentClient?.name || "",
          domainName: currentClient?.domainName || "",
          street1: currentClient?.street1 || "",
          street2: currentClient?.street2 || "",
          city: currentClient?.city || "",
          state: currentClient?.state || "",
          zipcode: currentClient?.zipcode || "",
          country: currentClient?.country || "",
        }}
        onSubmit={(values, helpers) => {
          onClientFormSubmit(values, false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[CLIENTS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                <Button
                  onClick={() => {
                    const newValues = {
                      ...values,
                      _v: 0,
                    };
                    if (currentClient) newValues._id = currentClient._id;
                    setShowUnsavedChangesModal(false);
                    onClientFormSubmit(newValues, true);
                  }}
                  disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                  autoFocus
                >
                  Save & Continue
                </Button>
              </DialogActions>
            </Dialog>

            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar
                titleText={`${!currentClient ? "add new" : "view/edit"} client`}
                editMode={currentClient !== null}
              />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="name"
                          id="name"
                          label={`${!currentClient ? "Enter " : ""}Client Name`}
                          placeholder="Client Name"
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div
                        className="formik-field"
                        style={{ paddingBottom: !domainNameValid(values.domainName) ? "0px" : "" }}
                      >
                        <Field
                          asComponent="text"
                          name="domainName"
                          id="domainName"
                          label={`${!currentClient ? "Enter " : ""}Domain Name`}
                          placeholder="Domain Name"
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>
                      {!domainNameValid(values.domainName) && (
                        <div
                          style={{
                            ...warningFontStyle,
                            paddingLeft: "4px",
                          }}
                        >
                          <b>*</b> Must be a valid domain name
                        </div>
                      )}
                    </div>
                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="street1"
                          id="street1"
                          label={`${!currentClient ? "Enter " : ""}Street`}
                          placeholder="Street"
                        />
                        {requiredFieldNames.includes("street1") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="street2"
                          id="street2"
                          label={`${!currentClient ? "Enter " : ""}Street 2`}
                          placeholder="Street 2"
                        />
                        {requiredFieldNames.includes("street2") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="city"
                          id="city"
                          label={`${!currentClient ? "Enter " : ""}City`}
                          placeholder="City"
                        />
                        {requiredFieldNames.includes("city") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="state"
                          id="state"
                          label={`${!currentClient ? "Enter " : ""}State`}
                          placeholder="State"
                        />
                        {requiredFieldNames.includes("state") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="zipcode"
                          id="zipcode"
                          label={`${!currentClient ? "Enter " : ""}Zip Code`}
                          placeholder="Zip Code"
                        />
                        {requiredFieldNames.includes("zipcode") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          name="country"
                          id="country"
                          label={`${!currentClient ? "Enter " : ""}Country`}
                          placeholder="Country"
                        />
                        {requiredFieldNames.includes("country") && <RequiredFieldIndicator />}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentClient}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  !dirty ||
                  !domainNameValid(values.domainName)
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[CLIENTS].path}`);
                }}
                userRole={userObj.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

ClientForm.propTypes = {
  currentClient: PropTypes.object,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
};

export default ClientForm;
