import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ListItemIcon } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

export default function FormikSplitSubmitButton({
  formValues,
  onSave = null,
  onSaveAndAddNew = null,
  onSaveAndClose = null,
  formHasChanges,
  disableButtonMenu,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button
          onClick={(e) => {
            if (!formValues || !formHasChanges) handleToggle();
            else onSave(formValues);
          }}
          className="split-submit-btn"
          disabled={disableButtonMenu}
        >
          Save
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="save options"
          aria-haspopup="menu"
          onClick={handleToggle}
          className="split-submit-btn"
          disabled={disableButtonMenu}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={(e) => {
                      setOpen((prevOpen) => !prevOpen);
                      onSaveAndAddNew(formValues);
                    }}
                    disableRipple
                  >
                    <ListItemIcon>
                      <LibraryAddIcon />
                    </ListItemIcon>
                    Save & Add New
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setOpen((prevOpen) => !prevOpen);
                      onSaveAndClose(formHasChanges, formValues);
                    }}
                    disableRipple
                  >
                    <ListItemIcon>
                      <DisabledByDefaultIcon />
                    </ListItemIcon>
                    Save & Close
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

FormikSplitSubmitButton.propTypes = {
  formValues: PropTypes.object,
  onSave: PropTypes.func,
  onSaveAndAddNew: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  formHasChanges: PropTypes.bool,
  disableButtonMenu: PropTypes.bool,
};

FormikSplitSubmitButton.defaultProps = {};
