import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Main from "../../components/organisms/main/Main";
import IncidentReportForm from "../../forms/incidentReportForm";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, alerts, incidents } from "../../api/index";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";

function View({ errorHandler }) {
  const { alertId } = useParams();
  const [currentAlert, setCurrentAlert] = useState(null);
  const [currentIncidentReport, setCurrentIncidentReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function loadAlertFromAPI(currentAlertId) {
      try {
        let response = await getItemFromAPI(alerts, currentAlertId, new AbortController());
        if (!response.incidentReportObj) {
          setCurrentAlert(response);
          setCurrentIncidentReport(null);
        } else {
          setCurrentAlert(null);
          response = await getItemFromAPI(incidents, response.incidientReportId, new AbortController());
          setCurrentIncidentReport(response);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    }
    loadAlertFromAPI(alertId);
  }, [alertId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <IncidentReportForm
              currentAlert={currentAlert}
              currentIncidentReport={currentIncidentReport}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentIncidentReport(formValues);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
