import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons";
import Field from "../formikUI/field/Field";
import FormikFormTitleBar from "./formikFormTitleBar";
import FormikFormFooterBar from "./formikFormFooterBar";
import { postItemToAPI, patchItemToAPI, locations } from "../api/index";
import { routes } from "../nav/nav";
import { locationForm } from "./formOptions";
import { datetime, forms } from "../utils/utils";
import RequiredFieldIndicator from "./requiredFieldIndicator";
import Spinner from "../components/atoms/spinner/Spinner";
import SnackbarAlert from "../materialUI/snackbar/snackbarAlert";

function LocationForm({ currentLocation, errorHandler, onFormPost }) {
  const userObj = useSelector((state) => state.global);
  const formRef = useRef();
  const navigate = useNavigate();
  const { properties, paths, LOCATIONS } = routes;
  const currentPath = paths.find((p) => window.location.pathname.includes(p.pathname));
  const userCanUpdate = userObj.userRole?.superAdmin;
  const { requiredFieldNames } = locations;
  const { getItemErrorMessage, getItemSucccessMessage, requiredFieldsPopulated, getDialogSubMessage } = forms;
  const [newRecordId, setNewRecordId] = useState("");

  const [formState, setFormState] = useState({
    selectedTimeZone: currentLocation?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    isSaving: false,
    errorExists: false,
    showUnsavedChangesModal: false,
    isDirty: false,
  });

  function setIsSaving(isSaving) {
    const newFormState = { ...formState };
    newFormState.isSaving = isSaving;
    setFormState(newFormState);
  }

  function setShowUnsavedChangesModal(open) {
    const newFormState = { ...formState };
    newFormState.showUnsavedChangesModal = open;
    setFormState(newFormState);
  }

  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });

  function getRequiredFieldValues(fieldValues) {
    //  This function needs to be specific for each form
    return [fieldValues.name, fieldValues.address, fieldValues.type];
  }

  function getUpdatedFieldValues(fieldValues) {
    const updatedFieldValues = {
      ...fieldValues,
      _v: 0,
    };
    if (currentLocation) updatedFieldValues._id = currentLocation._id;
    return updatedFieldValues;
  }

  const onLocationFormSubmit = async (values, navigateToList) => {
    const newFormState = { ...formState };
    const newSnackbarAlerState = { ...snackbarAlertState };
    const controller = new AbortController();
    let response = null;

    try {
      // update this to have the room values from the db
      const updatedLocation = {
        ...currentLocation,
        ...values,
        // timeZone: formState.selectedTimeZone,
      };
      if (currentLocation) response = await patchItemToAPI(locations, updatedLocation, controller);
      else response = await postItemToAPI(locations, updatedLocation, controller);
      if (!currentLocation) {
        setNewRecordId(response.id);
        updatedLocation._id = response.id;
      }
      if (navigateToList) navigate(`${properties[LOCATIONS].path}`);
      else {
        onFormPost(updatedLocation);
        newFormState.showUnsavedChangesModal = false;
        newFormState.errorExists = false;
        newSnackbarAlerState.alertMessage = getItemSucccessMessage(
          "Room",
          newRecordId.length === 0 ? currentLocation : null
        );
        newSnackbarAlerState.alertSeverity = "success";
      }
    } catch (error) {
      newFormState.errorExists = true;
      newSnackbarAlerState.alertMessage = getItemErrorMessage("Room", currentLocation?._id ? currentLocation : null);
      newSnackbarAlerState.alertSeverity = "error";
      setNewRecordId("");
      errorHandler(error);
    } finally {
      newFormState.isSaving = false;
      setFormState(newFormState);
      newSnackbarAlerState.open = true;
      setSnackbarAlertState(newSnackbarAlerState);
    }
  };

  let titleBarText = "";
  if (!currentLocation?._id) titleBarText = `add new location`;
  else if (userCanUpdate) titleBarText = `view/edit location`;
  else titleBarText = `view location`;

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarAlertState.open}
        alertSeverity={formState.errorExists ? "error" : "success"}
        alertMessage={
          formState.errorExists
            ? getItemErrorMessage("Location", currentLocation?._id ? currentLocation : null)
            : getItemSucccessMessage("Location", newRecordId.length === 0 ? currentLocation : null)
        }
        onSnackbarClose={() => {
          setSnackbarAlertState({
            alertMessage: "",
            alertSeverity: "success",
            open: false,
          });
          setIsSaving(false);
          setNewRecordId("");
        }}
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          name: currentLocation?.name || "",
          address: currentLocation?.address || "",
          description: currentLocation?.description || "",
          inactive: currentLocation?.inactive || false,
          type: currentLocation?.type || "",
          comment: currentLocation?.comment || "",
        }}
        onSubmit={(values, helpers) => {
          onLocationFormSubmit(getUpdatedFieldValues(values), false);
          if (!formState.errorExists)
            helpers.resetForm({
              values,
            });
        }}
      >
        {(
          { values, dirty } // errors, touched, isValid
        ) => (
          <>
            <Dialog
              open={formState.showUnsavedChangesModal}
              onClose={() => {
                setShowUnsavedChangesModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">There are unsaved changes.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Would you like to save your changes before leaving this page?
                  <br />
                  {(formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))) && (
                    <span style={{ fontSize: "smaller", fontStyle: "italic", color: "red" }}>
                      {getDialogSubMessage(
                        requiredFieldsPopulated(getRequiredFieldValues(values)),
                        formState.errorExists
                      )}
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate(`${properties[LOCATIONS].path}`);
                  }}
                  autoFocus
                >
                  Continue
                </Button>
                {userCanUpdate && (
                  <Button
                    onClick={() => {
                      setShowUnsavedChangesModal(false);
                      onLocationFormSubmit(getUpdatedFieldValues(values), true);
                    }}
                    disabled={formState.errorExists || !requiredFieldsPopulated(getRequiredFieldValues(values))}
                    autoFocus
                  >
                    Save & Continue
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Form
              onChange={(e) => {
                const newFormState = { ...formState };
                newFormState.errorExists = false;
                setFormState(newFormState);
              }}
            >
              <FormikFormTitleBar titleText={titleBarText} editMode={currentLocation !== null} />

              {formState.isSaving ? (
                <Spinner />
              ) : (
                <div className="formik-field-view">
                  <div className="formik-field-container">
                    <div className="formik-field-container-left-col">
                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          id="name"
                          name="name"
                          label={`${!currentLocation ? "Enter " : ""}Location Name`}
                          placeholder="Location Name"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("name") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="text"
                          id="description"
                          name="description"
                          label={`${!currentLocation ? "Enter " : ""}Location Description`}
                          placeholder="Location Description"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("description") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          id="comment"
                          name="comment"
                          label={
                            !currentLocation || values.comment.trim().length === 0
                              ? "Enter Location Comments"
                              : "Location Comments"
                          }
                          placeholder="Location Comments"
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("comment") && <RequiredFieldIndicator />}
                      </div>
                    </div>

                    <div className="formik-field-container-right-col">
                      <div className="formik-field">
                        <Field
                          asComponent="textarea"
                          className="form-textarea"
                          id="address"
                          name="address"
                          placeholder="Location Address"
                          label={
                            !currentLocation || values.address.trim().length === 0
                              ? "Enter Location Address"
                              : "Location Address"
                          }
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("address") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          id="type"
                          name="type"
                          label={`${!currentLocation ? "Select a " : ""}Location Type`}
                          placeholder="Location Types"
                          options={locationForm.locationTypeOptions}
                          onClick={(e) => {
                            if (e !== null && !dirty) {
                              const newFormState = { ...formState };
                              newFormState.isDirty = true;
                              newFormState.errorExists = false;
                              setFormState(newFormState);
                            }
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("type") && <RequiredFieldIndicator />}
                      </div>

                      <div className="formik-field">
                        <Field
                          asComponent="select"
                          className="selectField"
                          name="timeZone"
                          value={formState.selectedTimeZone}
                          label={`${!currentLocation ? "Select a " : ""}Time Zone`}
                          placeholder="Time Zone"
                          options={Intl.supportedValuesOf("timeZone")}
                          onChange={(e) => {
                            if (e !== null && !dirty) {
                              const newFormState = { ...formState };
                              newFormState.selectedTimeZone = e.target.value;
                              // newFormState.isDirty = true;
                              // newFormState.errorExists = false;
                              setFormState(newFormState);
                            }
                          }}
                          InputProps={{
                            readOnly: !userCanUpdate,
                          }}
                        />
                        {requiredFieldNames.includes("timeZone") && <RequiredFieldIndicator />}
                      </div>

                      {currentLocation && (
                        <div className="formik-field">
                          {userCanUpdate ? (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <Field
                                asComponent="checkboxYesNo"
                                name="inactive"
                                id="inactive"
                                onClick={(e) => {
                                  if (e !== null && !dirty) {
                                    const newFormState = { ...formState };
                                    newFormState.errorExists = false;
                                    setFormState(newFormState);
                                  }
                                }}
                                InputProps={{
                                  readOnly: !userCanUpdate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="formik-checkboxYesNo-field">
                              <div className="formik-checkboxYesNo-label">Inactive</div>
                              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                <FontAwesomeIcon
                                  icon={faCheckSquare}
                                  style={{ marginRight: "8px", opacity: "0.9", color: "#ff5e14" }}
                                />
                                {currentLocation.inactive ? "Yes" : "No"}
                              </div>{" "}
                            </div>
                          )}
                          {requiredFieldNames.includes("inactive") && <RequiredFieldIndicator />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formik-field-footer">
                    {currentLocation && (
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>CREATED: </span>
                          {datetime.formatTimestamp(currentLocation.createdAt)}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>UPDATED: </span>
                          {datetime.formatTimestamp(currentLocation.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <FormikFormFooterBar
                currentItem={currentLocation}
                buttonDisabled={
                  !requiredFieldsPopulated(getRequiredFieldValues(values)) ||
                  formState.isSaving ||
                  snackbarAlertState.open ||
                  (!dirty && !formState.isDirty) ||
                  !userCanUpdate
                }
                formValues={values}
                onSave={() => {
                  setIsSaving(true);
                  if (formRef.current) formRef.current.handleSubmit();
                }}
                pathIndex={currentPath.index}
                formDidChange={dirty || formState.isDirty}
                formErrorExists={formState.errorExists}
                onFormClose={(e) => {
                  dirty || formState.isDirty || formState.errorExists
                    ? setShowUnsavedChangesModal(true)
                    : navigate(`${properties[LOCATIONS].path}`);
                }}
                userRole={userObj?.userRole}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

LocationForm.propTypes = {
  currentLocation: PropTypes.object,
  errorHandler: PropTypes.func,
  onFormPost: PropTypes.func,
};

export default LocationForm;
