import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import {
  getGridStringOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid-pro";
import {
  locations,
  buildings,
  departments,
  rooms,
  devices,
  alertTypes,
  deviceTypes,
  collectors,
  listItemsFromAPI,
  deleteItemFromAPI,
  simplelistItemsFromAPI,
  createURL,
  getAllCollectionsOptions,
  getAllCollectionURL,
} from "./index";
import { routes } from "../nav/nav";
import { defaultFilterModel } from "../components/organisms/datagrid/datagridDefaults";
import { strings } from "../utils/utils";
import { errorHandler as appErrorHandler } from "../app/errorHandlers";
import GridActionMenu from "../components/organisms/datagrid/gridActionMenu";
import { store } from "../redux/store";
import { fetchCollectionAll } from "../redux/slices/filterOptionsSlice";
import GridAttachmentsMenu from "../components/organisms/datagrid/gridAttachmentsMenu";

const { CLIENTS } = routes;

export const stringOperators = getGridStringOperators().filter(({ value }) => ["equals"].includes(value));
export const booleanOperators = getGridBooleanOperators().filter(({ value }) => ["is"].includes(value));
export const dateOperators = getGridDateOperators().filter(({ value }) => ["is"].includes(value));

export async function loadItemsFromAPI({
  currentPaginationModel,
  currentFilterModel,
  currentSortModel,
  endpoint,
  errorHandler,
  setItemsToRender,
  setTotalItems,
  setIsLoading,
  setHttpError,
  setResetSortModel,
}) {
  const offset = currentPaginationModel.page * currentPaginationModel.pageSize;
  try {
    const response = await listItemsFromAPI(
      endpoint,
      currentFilterModel,
      currentSortModel,
      currentPaginationModel.pageSize,
      offset,
      new AbortController()
    );
    setHttpError(null);
    setItemsToRender(response?.items ? response.items : []);
    setTotalItems(response?.total ? response.total : 0);
  } catch (error) {
    if (error.statusCode) {
      setHttpError(error);
    } else {
      errorHandler(error, setItemsToRender, []);
    }
  } finally {
    setIsLoading(false);
    setResetSortModel(false);
  }
}

loadItemsFromAPI.propTypes = {
  currentPaginationModel: PropTypes.object.isRequired,
  currentFilterModel: PropTypes.object.isRequired,
  currentSortModel: PropTypes.object.isRequired,
  endpoint: PropTypes.object.isRequired,
  errorHandler: PropTypes.func.isRequired,
  setItemsToRender: PropTypes.func.isRequired,
  setTotalItems: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setHttpError: PropTypes.func.isRequired,
  setResetSortModel: PropTypes.func.isRequired,
};

export function clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading }) {
  const currentFilter = currentFilterModel.items[0];
  if (currentFilter.field || currentFilter.value || itemsToRender.length === 0) {
    setIsLoading(true);
    if (currentFilter.field && currentFilter.value) {
      setCurrentFilterModel(defaultFilterModel);
    } else if (itemsToRender.length === 0) {
      refreshTable();
    }
  }
}

clearFilters.propTypes = {
  currentFilterModel: PropTypes.object.isRequired,
  itemsToRender: PropTypes.array.isRequired,
  refreshTable: PropTypes.func.isRequired,
  setCurrentFilterModel: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export async function deleteRow(item, rowId, setIsLoading, refreshTable, setSnackbarAlertState) {
  let newAlertState = {
    open: true,
    alertSeverity: "success",
    alertMessage: strings.getDeleteSuccessMessage(item),
  };
  try {
    const response = await deleteItemFromAPI(item, rowId, new AbortController());
    if (!response) refreshTable();
  } catch (error) {
    setIsLoading(false);
    newAlertState = {
      open: true,
      alertSeverity: "error",
      alertMessage: error.errorMesssage ? error.errorMesssage : strings.getDeleteErrorMessage(item),
    };
  } finally {
    setSnackbarAlertState(newAlertState);
    const url = getAllCollectionURL({ endpoint: CLIENTS });
    store.dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
  }
}

deleteRow.propTypes = {
  item: PropTypes.object.isRequired,
  rowId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
  setSnackbarAlertState: PropTypes.func.isRequired,
};

export const lookupFieldsMap = [
  {
    field: "locationName",
    lookupField: "locationId",
    lookupListFunc: locations.simplelist,
  },
  {
    field: "buildingName",
    lookupField: "buildingId",
    lookupListFunc: buildings.simplelist,
  },
  {
    field: "departmentName",
    lookupField: "departmentId",
    lookupListFunc: departments.simplelist,
  },
  {
    field: "roomName",
    lookupField: "roomId",
    lookupListFunc: rooms.simplelist,
  },
  {
    field: "deviceName",
    lookupField: "deviceId",
    lookupListFunc: devices.simplelist,
  },
  // {
  //   field: "deviceAddress",
  //   lookupField: "deviceId",
  //   lookupListFunc: devices.simplelist,
  // },
  {
    field: "alertTypeName",
    lookupField: "alertTypeId",
    lookupListFunc: alertTypes.list,
  },
  {
    field: "deviceTypeName",
    lookupField: "deviceTypeId",
    lookupListFunc: deviceTypes.list,
  },
  {
    field: "collectorName",
    lookupField: "collectorId",
    lookupListFunc: collectors.simplelist,
  },
];
export function getLookupFilterModel(filterModel, lookupFieldList, setCurrentFilterModel, errorHandler) {
  const newFilterModel = { ...filterModel };
  try {
    if (lookupFieldList.includes(newFilterModel.items[0].field)) {
      newFilterModel.items[0].lookupField = null;
      newFilterModel.items[0].lookupValue = null;
      const lookupField = lookupFieldsMap.filter(
        (f) => f.field.toLowerCase() === newFilterModel.items[0].field?.toLowerCase()
      );
      if (lookupField) {
        newFilterModel.items[0].lookupField = lookupField[0].lookupField;
        newFilterModel.items[0].lookupValue = newFilterModel.items[0].value;
      }
      if (newFilterModel.items[0].lookupValue === null)
        throw new Error(
          `${newFilterModel.items[0].field} lookup value not found for '${newFilterModel.items[0].value}'`
        );
    }
    setCurrentFilterModel(newFilterModel);
  } catch (error) {
    errorHandler();
  }
}

getLookupFilterModel.propTypes = {
  filterModel: PropTypes.object.isRequired,
  lookupFieldList: PropTypes.array.isRequired,
  setCurrentFilterModel: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
};

export async function loadFilterOptionsFromAPI(endpoint, setFilterOptions, optionValue = "_id", labelValue = null) {
  try {
    const response = await simplelistItemsFromAPI(
      endpoint,
      new AbortController(),
      [locations, devices].includes(endpoint) ? null : ""
    );
    if (response.length > 0) {
      const newFilterOptions = [];
      response.map((i) => {
        let returnOptionValue = null;
        if (optionValue.localeCompare("_id") === 0) returnOptionValue = i._id;
        else if (optionValue.localeCompare("name") === 0) returnOptionValue = i.name;
        else if (optionValue.localeCompare("deviceAddress") === 0) returnOptionValue = i.deviceAddress;
        newFilterOptions.push({
          label: !labelValue ? i.name : i.deviceAddress,
          value: returnOptionValue,
        });
        return true;
      });
      setFilterOptions(newFilterOptions);
    }
  } catch (error) {
    appErrorHandler(error);
  }
}

loadFilterOptionsFromAPI.propTypes = {
  item: PropTypes.object.isRequired,
  setFilterOptions: PropTypes.func.isRequired,
};

export function renderAttachmentsMenuCell(attachments, setAttachmentsDialogState = null) {
  return {
    field: "attachmentsMenu",
    type: "actions",
    hideable: false,
    cellClassName: "add-new-grid-cell",
    width: 115,
    disableReorder: true,
    resizable: false,
    headerName: "Attachments",
    renderCell: (cellValues) => (
      <GridAttachmentsMenu
        rowId={cellValues.row._id}
        itemName={cellValues.row.name}
        attachments={attachments}
        setAttachmentsDialogState={setAttachmentsDialogState}
      />
    ),
  };
}
export function renderActionMenuCell(
  pathIndex,
  userRole,
  setShowDeleteConfirm = null,
  setImportMsgFileDialogState = null,
  setAttachmentsDialogState = null
) {
  return {
    field: "actionMenu",
    type: "actions",
    hideable: false,
    cellClassName: "add-new-grid-cell",
    width: 74,
    disableReorder: true,
    resizable: false,
    headerName: "Actions",
    renderCell: (cellValues) => (
      <GridActionMenu
        rowId={cellValues.row._id}
        itemName={cellValues.row.name}
        pathIndex={pathIndex}
        setShowDeleteConfirm={(show) => setShowDeleteConfirm(show)}
        setImportMsgFileDialogState={(state) => setImportMsgFileDialogState(state)}
        setAttachmentsDialogState={setAttachmentsDialogState}
        userRole={userRole}
      />
    ),
  };
}

renderActionMenuCell.propTypes = {
  pathIndex: PropTypes.number.isRequired,
  setShowDeleteConfirm: PropTypes.func.isRequired,
};

export function renderSingleSelectField(fieldName, headerText, filterValueOptions, cellWidth) {
  return {
    field: fieldName,
    type: "singleSelect",
    headerName: headerText,
    headerClassName: "data-grid-column-header",
    cellClassName: "data-grid-cell",
    valueOptions: (cellValues) => filterValueOptions,
    width: cellWidth,
    renderCell: (cellValues) => {
      let returnValue = "";
      if (fieldName.localeCompare("name") === 0) returnValue = cellValues.row?.name;
      else if (fieldName.localeCompare("departmentName") === 0)
        returnValue = cellValues.row?.departmentObj?.name || cellValues.row?.departmentName;
      else if (fieldName.localeCompare("buildingName") === 0)
        returnValue = cellValues.row?.buildingObj?.name || cellValues.row?.buildingName;
      else if (fieldName.localeCompare("locationName") === 0)
        returnValue = cellValues.row?.locationObj?.name || cellValues.row?.locationName;
      else if (fieldName.localeCompare("roomName") === 0) returnValue = cellValues.row?.roomName;
      else if (fieldName.localeCompare("collectorName") === 0)
        returnValue = cellValues.row?.collectorObj?.name || cellValues.row?.collectorName;
      else if (fieldName.localeCompare("deviceName") === 0)
        returnValue = cellValues.row?.deviceObj?.name || cellValues.row?.deviceName;
      else if (fieldName.localeCompare("deviceAddress") === 0) returnValue = cellValues.row?.deviceAddress;
      else if (fieldName.localeCompare("deviceTypeName") === 0) returnValue = cellValues.row?.deviceTypeName;
      else if (fieldName.localeCompare("alertTypeName") === 0) returnValue = cellValues.row?.alertTypeName;
      return returnValue;
    },
    filterOperators: getGridSingleSelectOperators().filter(({ value }) => ["is"].includes(value)),
  };
}

renderSingleSelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  filterValueOptions: PropTypes.array.isRequired,
  cellWidth: PropTypes.number.isRequired,
};

export function renderBooleanCell(value) {
  return (
    <div className={`${value ? "pos" : "neg"}-grid-cell`}>
      <FontAwesomeIcon icon={value ? faSquareCheck : faSquare} />
    </div>
  );
}

renderBooleanCell.propTypes = {
  value: PropTypes.bool.isRequired,
};

export function applyOptions(
  allowedDrillFilterFields,
  selectedFilters,
  allCollectionOptions,
  defaultOptions,
  optionMap,
  pageName = null
) {
  const { idLookup } = store.getState().filterOptions;

  allowedDrillFilterFields.forEach((filter) => {
    if (selectedFilters[filter]?.length > 0) {
      allowedDrillFilterFields.forEach((level, index) => {
        if (level !== filter) {
          let nextLevel = [];
          let isDeviceAddress = false;
          selectedFilters[filter].forEach((f) => {
            const id = f.value;

            if (level === "deviceAddressId") {
              isDeviceAddress = true;
              level = "deviceId";
            }
            const nextLevelOption = allCollectionOptions[level.slice(0, -2)]
              .filter((ele) => ele[filter] === id)
              .map((ele) => {
                const ret = { label: ele.name, value: ele._id };
                const lookupValue = idLookup[ele[allowedDrillFilterFields[index - 1]]];
                if (index !== 0 && f.label !== lookupValue) {
                  ret.defaultGroup = f.label;
                  ret.group = idLookup[ele[allowedDrillFilterFields[index - 1]]];
                } else {
                  ret.group = f.label;
                }

                if (isDeviceAddress) {
                  ret.label = ele.deviceAddress;
                  ret.value = ele.deviceAddress;
                }
                if (pageName === level.slice(0, -2)) ret.value = ele.name;

                return ret;
              });
            nextLevel = nextLevel.concat(nextLevelOption);
          });
          if (nextLevel.length !== 0) {
            const setFilterOption = isDeviceAddress ? optionMap.deviceAddress[1] : optionMap[level.slice(0, -2)][1];
            setFilterOption(nextLevel);
          }
        }
      });
    }
  });
  // If no filter is set at the top level, set the default options for the next level down
  let firstAppliedFilter = false;
  // allowedDrillFilterFields.length - 1 to exclude the last filter because of the map down
  for (let i = 0; i < allowedDrillFilterFields.length - 1; i += 1) {
    if (selectedFilters[allowedDrillFilterFields[i]]?.length === 0 && !firstAppliedFilter) {
      const filterParsed = allowedDrillFilterFields[i].slice(0, -2);
      const levelDownMap = {
        location: "building",
        building: "department",
        department: "room",
        room: "device",
      };
      const levelDown = levelDownMap[filterParsed];
      if (levelDown) {
        if (pageName === levelDown) {
          const setFilterOption = optionMap[levelDown][1];
          setFilterOption(defaultOptions[`${levelDown}Name`]);
        } else if (filterParsed === "room" && pageName === "deviceAddress") {
          optionMap.deviceAddress[1](defaultOptions.deviceAddress);
        } else if (filterParsed === "room" && pageName === "deviceAddress&Name") {
          optionMap.deviceAddress[1](defaultOptions.deviceAddress);
          optionMap.device[1](defaultOptions.device);
        } else {
          const setFilterOption = optionMap[levelDown][1];
          setFilterOption(defaultOptions[levelDown]);
        }
      }
    } else {
      firstAppliedFilter = true;
    }
  }
}
applyOptions.propTypes = {
  allowedDrillFilterFields: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  allCollectionOptions: PropTypes.object.isRequired,
  defaultOptions: PropTypes.object.isRequired,
  optionMap: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};
export function getExportURL({ currentFilterModel, currentSortModel, endpoint }) {
  const urlParms =
    currentFilterModel.items[0].field && currentFilterModel.items[0].value
      ? {
          [currentFilterModel.items[0].field]: currentFilterModel.items[0].value,
        }
      : {};
  urlParms.sortBy = currentSortModel.sortColumn;
  urlParms.sortDir = currentSortModel.sortDirection;
  urlParms.skip = 0;
  urlParms.limit = 3000;
  const url = createURL({
    property: endpoint,
    endpoint: "limitedexport",
    queryParams: urlParms,
  });
  return url;
}

getExportURL.propTypes = {
  currentFilterModel: PropTypes.object.isRequired,
  currentSortModel: PropTypes.object.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export function getImportURL({ endpoint }) {
  const url = createURL({
    property: endpoint,
    endpoint: "queue",
    queryParams: {},
  });
  return url;
}
getImportURL.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

export function uploadAttachmentURL({ endpoint }, buildingId) {
  const url = createURL({
    property: endpoint,
    endpoint: `${buildingId}/file/upload`,
    queryParams: {},
  });
  return url;
}
uploadAttachmentURL.propTypes = {
  endpoint: PropTypes.string.isRequired,
  buildingId: PropTypes.string.isRequired,
};
export function getAttachmentsURL({ endpoint }, buildingId) {
  const url = createURL({
    property: endpoint,
    endpoint: `${buildingId}/file/list`,
    queryParams: {},
  });
  return url;
}
getAttachmentsURL.propTypes = {
  endpoint: PropTypes.string.isRequired,
  buildingId: PropTypes.string.isRequired,
};

export function filterMenu(pathIndex, setShowDeleteConfirm = null) {
  return {
    field: "actionMenu",
    type: "actions",
    hideable: false,
    cellClassName: "add-new-grid-cell",
    width: 74,
    disableReorder: true,
    resizable: false,
    headerName: "Actions",
    renderCell: (cellValues) => (
      <GridActionMenu
        rowId={cellValues.row._id}
        itemName={cellValues.row.name}
        pathIndex={pathIndex}
        setShowDeleteConfirm={(show) => setShowDeleteConfirm(show)}
      />
    ),
  };
}

export function setColumnOrder(column, datagridState, setDatagridState) {
  const newDatagridState = { ...datagridState };
  const newColumnOrder = [...datagridState.columnOrder];
  const targetColumn = { ...newColumnOrder.filter((col) => col.field === column.column.field) };
  let colSet1 = null;
  let colSet2 = null;
  let colSet3 = null;

  delete newDatagridState.columnOrder;
  if (column.targetIndex < column.oldIndex) {
    colSet1 = newColumnOrder.filter((c) => c.index < column.targetIndex);
    colSet2 = newColumnOrder.filter(
      (c) => c.index >= column.targetIndex && c.index !== column.oldIndex && c.index < column.oldIndex
    );
    colSet2 = colSet2.map((c) => {
      const newCol = { ...c };
      newCol.index += 1;
      return newCol;
    });
    colSet3 = newColumnOrder.filter((c) => c.index > targetColumn[0].index);
  } else {
    colSet1 = newColumnOrder.filter((c) => c.index < column.oldIndex);
    colSet2 = newColumnOrder.filter((c) => c.index > column.oldIndex && c.index <= column.targetIndex);
    colSet2 = colSet2.map((c) => {
      const newCol = { ...c };
      newCol.index -= 1;
      return newCol;
    });
    colSet3 = newColumnOrder.filter((c) => c.index > column.targetIndex);
  }
  targetColumn[0].index = column.targetIndex;
  newDatagridState.columnOrder = [...colSet1, ...colSet2, targetColumn[0], ...colSet3];

  setDatagridState(newDatagridState);
}

setColumnOrder.propTypes = {
  column: PropTypes.object.isRequired,
  setDatagridState: PropTypes.func.isRequired,
};
