import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, displays } from "../../api/index";
import DisplayForm from "../../forms/displayForm";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";

function View({ errorHandler }) {
  const { displayId } = useParams();
  const [currentDisplay, setCurrentDisplay] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isNewDisplay = parseInt(displayId) === 0;

  useEffect(() => {
    const controller = new AbortController();
    if (!isNewDisplay) setIsLoading(true);
    async function loadDisplayFromAPI(currentDisplayId) {
      try {
        const response = await getItemFromAPI(displays, currentDisplayId, controller);
        setCurrentDisplay(response);
      } catch (error) {
        errorHandler(error, setCurrentDisplay, currentDisplay || {});
      } finally {
        setIsLoading(false);
      }
    }
    isNewDisplay ? setCurrentDisplay(null) : loadDisplayFromAPI(displayId);
  }, [displayId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <DisplayForm
              currentDisplay={currentDisplay}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentDisplay(formValues);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
