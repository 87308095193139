import React from "react";
import PropTypes from "prop-types";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { Button, Box, Badge } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faFilterCircleXmark, faArrowDownToSquare } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes as appRoutes } from "../../../nav/nav";

function gridToolbarDef({
  handleRefresh,
  handleClearFilters,
  isFilterMenuOpen,
  setIsFilterMenuOpen,
  handleExport,
  currentFilterModel,
  setSelectedFilters,
  defaultSelectedFilters,
}) {
  const { DISPLAYS } = appRoutes;
  const currentPath = appRoutes.paths.find((p) => window.location.pathname.includes(p.pathname));

  function GridToolbarRefresh() {
    return (
      <Button
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon={faRotateRight} />}
        onClick={() => handleRefresh()}
        title="Refresh Datagrid"
      >
        Refresh
      </Button>
    );
  }
  function GridToolbarClearFilters() {
    return (
      <Button
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
        onClick={() => {
          handleClearFilters();
          if (setSelectedFilters) {
            // only clear selected filters if the page has a custom filter defaults ex: Users
            setSelectedFilters(defaultSelectedFilters("clear"));
          }
        }}
        title="Clear Filters"
      >
        Clear Filters
      </Button>
    );
  }

  function GridToolbarExportData() {
    return handleExport ? (
      <Button
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon={faArrowDownToSquare} />}
        onClick={() => {
          handleExport();
        }}
        title="Export Data"
        disabled={currentPath?.index === DISPLAYS}
      >
        Export
      </Button>
    ) : null;
  }

  function GridToolbarFilterPanel() {
    return (
      <Button
        color="primary"
        size="medium"
        onClick={() => {
          setIsFilterMenuOpen(!isFilterMenuOpen);
        }}
        sx={{ paddingLeft: "0px", paddingRight: "6px" }}
        disabled={currentPath?.index === DISPLAYS}
      >
        <Badge
          badgeContent={
            currentFilterModel && currentFilterModel.items.length !== 0 && currentFilterModel.items[0].field !== ""
              ? currentFilterModel.items.length
              : 0
          }
          color="primary"
        >
          <FilterListIcon />
        </Badge>
        <Box sx={{ pl: "7px" }}>Filters</Box>
      </Button>
    );
  }

  function AddNewRecord() {
    const navigate = useNavigate();
    const { userRole } = useSelector((state) => state.global);
    const userCanCreate = userRole?.superAdmin;
    return userCanCreate && currentPath?.showAddNew ? (
      <Button
        id="addNewRecord"
        color="primary"
        size="medium"
        onClick={() => {
          if (window.location.pathname.length === currentPath.pathname.length) navigate(`${currentPath.pathname}/0`);
        }}
        sx={{ mt: "1px" }}
      >
        <AddCircleOutlineIcon />
        <Box sx={{ ml: "7px", pt: "2px" }}>Add New</Box>
      </Button>
    ) : null;
  }

  return (
    <GridToolbarContainer>
      <div className="toolbarLeft">
        {/* Conditional */}
        <AddNewRecord />
        <GridToolbarColumnsButton size="large" sx={{ paddingX: "8px", paddingY: "6px" }} />
        {/* Determines if custom filter menu is setup of for that page */}
        {setIsFilterMenuOpen ? <GridToolbarFilterPanel /> : <GridToolbarFilterButton />}

        <GridToolbarExportData />
      </div>
      <div className="toolbarRight">
        <GridToolbarClearFilters />
        <GridToolbarRefresh />
      </div>
    </GridToolbarContainer>
  );
}

gridToolbarDef.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  handleExport: PropTypes.func,
  currentFilterModel: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  defaultSelectedFilters: PropTypes.func,
};

export default gridToolbarDef;
