import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function ImportMsgFileDialog({ buildingId, dialogState, setImportMsgFileDialogState, onSelectImportFile }) {
  const inputFileRef = useRef(null);
  const [importForm, setImportForm] = useState(null);
  const linkStyle = { marginTop: "12px", fontSize: ".8em", color: "#1976d2", textDecorationLine: "underline" };

  return (
    <Dialog
      open={dialogState.open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: "8px", opacity: "0.8", color: "#ff5e14" }} />
        Upload Message File for {dialogState.buildingName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Select a system message file to upload.</DialogContentText>
        <form id="importForm" name="importForm" encType="multipart/form-data">
          <input id="buildingId" name="buildingId" type="hidden" readOnly value={buildingId} />
          <input
            type="file"
            id="importFile"
            name="importFile"
            ref={inputFileRef}
            accept="text/csv"
            onChange={() => {
              const importFormElement = document.getElementById("importForm");
              if (importFormElement.importFile?.files?.item(0)) setImportForm(importFormElement);
              else setImportForm(null);
            }}
          />
        </form>
        <div style={linkStyle}>
          <Link to="https://pinpointcloud-backend.azurewebsites.net/example.csv">Download Message File Template</Link>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setImportForm(null);
            onSelectImportFile(null);
            setImportMsgFileDialogState({ open: false, buildingName: "" });
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (importForm) onSelectImportFile(importForm);
            setImportMsgFileDialogState({ open: false, buildingName: "" });
          }}
          autoFocus
          disabled={importForm === null}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ImportMsgFileDialog.propTypes = {
  buildingId: PropTypes.string,
  dialogState: PropTypes.object,
  setImportMsgFileDialogState: PropTypes.func,
  onSelectImportFile: PropTypes.func,
};

export default ImportMsgFileDialog;
