// https://stackoverflow.com/questions/74682997/format-filter-date-in-react-and-material-ui-data-grid
// https://mui.com/x/api/data-grid/grid-col-def/
// https://mui.com/x/react-data-grid/column-definition/#column-types

export const defaultSortModels = {
  DASHBOARD: 0,
  ALERTS: 1,
  INCIDENTS: 2,
  CLIENTS: 3,
  LOCATIONS: 4,
  BUILDINGS: 5,
  DEPARTMENTS: 6,
  ROOMS: 7,
  DEVICES: 8,
  COLLECTORS: 9,
  USERS: 10,
  MAINTENANCE: 11,
  DISPLAYS: 12,

  sortModel: {
    0: { sortColumn: null, sortDirection: null },
    1: { sortColumn: "timestamp", sortDirection: "desc" },
    2: { sortColumn: "createdAt", sortDirection: "desc" },
    3: { sortColumn: "name", sortDirection: "asc" },
    4: { sortColumn: "createdAt", sortDirection: "desc" },
    5: { sortColumn: "createdAt", sortDirection: "desc" },
    6: { sortColumn: "createdAt", sortDirection: "desc" },
    7: { sortColumn: "createdAt", sortDirection: "desc" },
    8: { sortColumn: "createdAt", sortDirection: "desc" },
    9: { sortColumn: "createdAt", sortDirection: "desc" },
    10: { sortColumn: "name", sortDirection: "asc" },
    11: { sortColumn: "timestamp", sortDirection: "desc" },
    12: { sortColumn: "createdAt", sortDirection: "desc" },
  },
};

export const defaultFilterModel = {
  items: [
    {
      field: "",
      operator: "equals",
      value: "",
      isDefault: true,
    },
  ],
};

export const datagridOptions = {
  rowHeight: 27,
  pageSizeOptions: [25, 50, 100],
  defaultPaginationModel: {
    pageSize: 25,
    page: 0,
  },
};
