import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { incidents } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  deleteRow,
  stringOperators,
  booleanOperators,
  dateOperators,
  renderActionMenuCell,
  renderBooleanCell,
  setColumnOrder,
  lookupFieldsMap,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import IncindentsDataGrid from "../../components/organisms/datagrid/incidentsDataGrid";
import HttpError from "../httpError";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, INCIDENTS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[INCIDENTS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const [previousFilterModel, setPreviousFilterModel] = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };
  const [snackbarAlertState, setSnackbarAlertState] = useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "createdAt", index: 0 },
      { field: "createdBy", index: 1 },
      { field: "updatedAt", index: 2 },
      { field: "lastUpdatedBy", index: 3 },
      { field: "typeOfIncident", index: 4 },
      { field: "employeeSustainInjury", index: 5 },
      { field: "incidentInvolvement", index: 6 },
    ],
    columnWidths: [
      { field: "createdAt", width: 160 },
      { field: "createdBy", width: 160 },
      { field: "updatedAt", width: 160 },
      { field: "lastUpdatedBy", width: 160 },
      { field: "typeOfIncident", width: 300 },
      { field: "incidentInvolvement", width: 540 },
    ],
    columnVisibility: {
      createdAt: true,
      createdBy: true,
      updatedAt: true,
      lastUpdatedBy: true,
      typeOfIncident: true,
      employeeSustainInjury: true,
      incidentInvolvement: true,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(INCIDENTS, userObj?.userRole, setShowDeleteConfirm),
    {
      field: "createdAt",
      type: "date",
      headerName: "Created On",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "createdAt")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", { timeZone: tzid })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },
    {
      field: "createdBy",
      type: "string",
      headerName: "Created By",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "createdBy")[0].width,
      renderCell: (cellValues) => cellValues.row.createdByObj?.name,
      filterOperators: stringOperators,
    },
    {
      field: "updatedAt",
      type: "date",
      headerName: "Last Updated",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "updatedAt")[0].width,
      valueFormatter: (param) => {
        const result = new Date(param?.value || "");
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${result.toLocaleDateString("en-US", { timeZone: tzid })} ${result.toLocaleTimeString("en-US", {
          hourCycle: "h23",
          timeZone: tzid,
          timeZoneName: "short",
        })}`;
      },
      filterOperators: dateOperators,
    },
    {
      field: "lastUpdatedBy",
      type: "string",
      headerName: "Last Updated By",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "lastUpdatedBy")[0].width,
      renderCell: (cellValues) => cellValues.row.lastUpdatedByObj?.name,
      filterOperators: stringOperators,
    },
    {
      field: "typeOfIncident",
      type: "string",
      headerName: "Type of Incident",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "typeOfIncident")[0].width,
      filterOperators: stringOperators,
    },
    {
      field: "employeeSustainInjury",
      type: "boolean",
      headerName: "Injury Sustained",
      headerAlign: "center",
      align: "center",
      cellClassName: "data-grid-cell",
      headerClassName: "data-grid-column-header",
      width: 130,
      renderCell: (cellValues) => renderBooleanCell(cellValues.row.employeeSustainInjury),
      filterOperators: booleanOperators,
      resizable: false,
    },
    {
      field: "incidentInvolvement",
      type: "string",
      headerName: "Incident Involvement",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "incidentInvolvement")[0].width,
      filterOperators: stringOperators,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: incidents,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[INCIDENTS];
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dialog
            open={showDeleteConfirm}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <FontAwesomeIcon
                icon={faClipboardList}
                style={{ marginRight: "12px", opacity: "0.8", color: "#ff5e14" }}
              />
              <span style={{ fontSize: "15px", color: "#909090" }}>
                Are you sure you you want to permanently delete this Incident Report?
              </span>
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  console.log("onClick()", { selectedRow });
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  setIsLoading(true);
                  deleteRow(incidents, selectedRow._id, setIsLoading, refreshTable, setSnackbarAlertState);
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <IncindentsDataGrid
            columnDefs={gridColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onFilterModelChange={(newFilterModel) => {
              if (newFilterModel.items?.length > 0) {
                newFilterModel.items[0].field = newFilterModel.items[0].field?.trim();
                newFilterModel.items[0].value = newFilterModel.items[0].value?.trim();
              }
              setPreviousFilterModel(currentFilterModel);
              setCurrentFilterModel(newFilterModel);
            }}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedRow={(row) => setSelectedRow(row)}
            handleExport={null}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Created On"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
