import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../components/atoms/spinner/Spinner";
import { getItemFromAPI, users, roles } from "../../api/index";
import { formContainerStyles } from "../../components/organisms/datagrid/themeStyles";
import Main from "../../components/organisms/main/Main";
import UserForm from "../../forms/userForm";

function View({ errorHandler }) {
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isNewUser = parseInt(userId) === 0;
  const [roleOptions, setRoleOptions] = useState([]);

  async function loadRoleOptionsFromAPI(controller) {
    try {
      const response = await roles.list(controller.signal);
      setRoleOptions(response);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function loadUserFromAPI(currentUserId, controller) {
    try {
      const response = await getItemFromAPI(users, currentUserId, controller);
      setCurrentUser(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (roleOptions.length === 0) loadRoleOptionsFromAPI(new AbortController());
  }, []);

  useEffect(() => {
    setIsLoading(!isNewUser);
    isNewUser ? setCurrentUser(null) : loadUserFromAPI(userId, new AbortController());
  }, [userId]);

  return (
    <Main>
      <Box sx={{ display: "flex" }}>
        <div id="formik-form-view" style={formContainerStyles}>
          {isLoading ? (
            <Spinner />
          ) : (
            <UserForm
              currentUser={currentUser}
              roleOptions={roleOptions}
              errorHandler={errorHandler}
              onFormPost={(formValues) => {
                setCurrentUser(formValues);
              }}
            />
          )}
        </div>
      </Box>
    </Main>
  );
}

View.propTypes = {
  errorHandler: PropTypes.func,
};

export default View;
