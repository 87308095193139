import React from "react";
import { Box, Typography } from "@mui/material";
import Main from "../components/organisms/main/Main";

function NotFound() {
  return (
    <Main>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" style={{ color: "red" }}>
          404
        </Typography>
        <Typography variant="h2">Page Not Found</Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">It seems that the page you are trying to get to can&apos;t be found.</Typography>
          <Typography variant="body1">Please use the links to the left to go to a different page.</Typography>
        </Box>
      </Box>
    </Main>
  );
}

export default NotFound;
