import * as React from "react";
import PropTypes from "prop-types";
import { DataGridPro, GridFooter } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import gridPaginationDef from "./gridPaginationDef";
import gridToolbarDef from "./gridToolbarDef";
import { datagridOptions } from "./datagridDefaults";
import { themeStyles, gridContainerStyles } from "./themeStyles";

// https://mui.com/x/api/data-grid/data-grid/

// Column resizing is not available in the MIT version.
// Upgrade to DataGridPro or DataGridPremium component to unlock this feature.
// SEE: https://mui.com/x/react-data-grid/

function DisplaysDataGrid(props) {
  const {
    columnDefs,
    rows,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    filterModel,
    handleRefresh,
    handleClearFilters,
    setSelectedRow,
    handleExport,
    onColumnResize,
    isFilterMenuOpen,
    setIsFilterMenuOpen,
    currentFilterModel,
    setSelectedFilters,
    defaultSelectedFilters,
    columnVisibilityModel,
    onColumnVisibilityChange,
    onColumnReorder,
  } = props;
  const { rowHeight, pageSizeOptions } = datagridOptions;

  return (
    <Box sx={themeStyles.sx}>
      <div style={gridContainerStyles}>
        <DataGridPro
          columns={columnDefs}
          rows={rows}
          headerHeight={rowHeight + 4}
          rowHeight={rowHeight}
          rowCount={rowCount}
          autoHeight={false}
          slots={{
            pagination: gridPaginationDef,
            toolbar: gridToolbarDef,
            footer: GridFooter,
          }}
          slotProps={{
            toolbar: {
              handleRefresh,
              handleClearFilters,
              handleExport,
              isFilterMenuOpen,
              setIsFilterMenuOpen,
              currentFilterModel,
              setSelectedFilters,
              defaultSelectedFilters,
            },
          }}
          showCellVerticalBorder
          showColumnVerticalBorder
          pagination
          paginationMode="server"
          pageSizeOptions={pageSizeOptions}
          pageSize={paginationModel.pageSize}
          paginationModel={paginationModel}
          onPaginationModelChange={(newPaginationModel) => {
            onPaginationModelChange(newPaginationModel);
          }}
          filterMode="server"
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            onSortModelChange(sortModel);
          }}
          getRowId={(row) => row._id}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
          getRowHeight={() => "auto"}
          initialState={{
            filter: { filterModel },
            pagination: { paginationModel },
            pinnedColumns: {
              left: ["actionMenu"],
            },
            columns: { columnVisibilityModel },
          }}
          onCellClick={(cell) => {
            if (cell.field === "actionMenu") setSelectedRow(cell.row);
          }}
          onColumnWidthChange={(e) => onColumnResize(e.colDef)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            onColumnVisibilityChange(newModel);
          }}
          onColumnOrderChange={(params, event, details) => {
            onColumnReorder(params);
          }}
        />
      </div>
    </Box>
  );
}

DisplaysDataGrid.propTypes = {
  columnDefs: PropTypes.array,
  rows: PropTypes.array,
  rowCount: PropTypes.number,
  paginationModel: PropTypes.object,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  filterModel: PropTypes.object,
  handleRefresh: PropTypes.func,
  handleClearFilters: PropTypes.func,
  setSelectedRow: PropTypes.func,
  handleExport: PropTypes.func,
  onColumnResize: PropTypes.func,
  isFilterMenuOpen: PropTypes.bool,
  setIsFilterMenuOpen: PropTypes.func,
  currentFilterModel: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  defaultSelectedFilters: PropTypes.func.isRequired,
  columnVisibilityModel: PropTypes.object,
  onColumnVisibilityChange: PropTypes.func,
  onColumnReorder: PropTypes.func,
};

DisplaysDataGrid.defaultProps = {};

export default DisplaysDataGrid;
