import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import { Button, Divider, ThemeProvider, Typography, createTheme } from "@mui/material";
import { fetchCollectionAll } from "./redux/slices/filterOptionsSlice";
import { routes as appRoutes, buttons } from "./nav/nav";
import Footer from "./components/organisms/footer/Footer";
import Header from "./components/organisms/header/Header";
import UserMenu from "./components/organisms/userMenu/UserMenu";
import Image from "./components/atoms/image/Image";
import NavigationLink from "./components/atoms/navigationLink/NavigationLink";
import Sidebar from "./components/organisms/sidebar/Sidebar";
import pinpointLogo from "./images/Pinpoint-white.svg";
import { routes } from "./app/routes";
import { errorHandler } from "./app/errorHandlers";
import { getAllCollectionsOptions, getAllCollectionURL } from "./api";
import { themeSettings } from "./theme";

LicenseInfo.setLicenseKey(
  "cd92be305e85dcb1177121642a6dd99bTz04MjEwMyxFPTE3MzY2OTE1NzAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// https://surajsharma.net/blog/current-url-in-react

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);

  const { user, userRole, targetClient } = useSelector((state) => state.global);
  const isAuthenticated = useIsAuthenticated();
  const unAuthenticatedHomePage = routes.find((route) => route.name?.localeCompare("home") === 0);
  const authenticatedRoutes = routes.filter((route) => route.name?.localeCompare("home") !== 0);
  const unAuthenticatedRoutes = [];
  const navigate = useNavigate();
  const { properties, DASHBOARD, CLIENTS, USERS, MAINTENANCE } = appRoutes;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const url = getAllCollectionURL({ endpoint: CLIENTS });
      dispatch(fetchCollectionAll({ getAllCollectionsOptions, url }));
    }
  }, [isAuthenticated]);

  unAuthenticatedRoutes.push(unAuthenticatedHomePage);
  unAuthenticatedRoutes.push({
    name: "redirect",
    path: "*",
    element: <Navigate replace to="/" />,
  });

  authenticatedRoutes.push({
    name: "redirect",
    path: "/",
    element: <Navigate replace to={properties[DASHBOARD].path} />,
  });

  const handleLogout = async (currentInstance) => {
    try {
      await currentInstance.logoutRedirect();
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App" key={targetClient.id}>
        <Header>
          <div id="header-left">
            <Button onClick={() => navigate("/")}>
              <Image alt="Pinpoint Logo" src={pinpointLogo} />
            </Button>
          </div>
          <div id="header-center">
            <Typography variant="h6" component="div">
              {targetClient.name}
            </Typography>
          </div>
          <div id="header-right">{isAuthenticated && <UserMenu handleLogout={handleLogout} />}</div>
        </Header>

        <UnauthenticatedTemplate>
          <Routes>
            {unAuthenticatedRoutes.map(({ element, name, path }) => (
              <Route key={name} path={path} element={element} />
            ))}
          </Routes>
        </UnauthenticatedTemplate>

        <AuthenticatedTemplate>
          <Sidebar>
            <nav>
              <ul>
                {buttons.map((link) => {
                  if (link.divider) {
                    return <Divider key={link.index} />;
                  }
                  return (
                    <li
                      key={link.index}
                      style={{
                        display:
                          ((!link.globalAdminOnly || (link.globalAdminOnly && userRole.superAdmin)) &&
                            (!link.companyAdminOnly || (link.companyAdminOnly && userRole.admin))) ||
                          (link.index === MAINTENANCE && user.userDomain === "childrensmn.org") // custom code for childrensmn
                            ? ""
                            : "none",
                      }}
                    >
                      <NavigationLink path={link.path}>
                        <span>
                          <FontAwesomeIcon icon={link.icon} title={link.label} />
                        </span>
                        {link.label}
                      </NavigationLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Sidebar>

          <Routes>
            {authenticatedRoutes.map(({ element, name, path, globalAdminOnly, companyAdminOnly }) => {
              if (
                ((!globalAdminOnly || (globalAdminOnly && userRole.superAdmin)) &&
                  (!companyAdminOnly || (companyAdminOnly && userRole.admin))) ||
                path.indexOf(properties[USERS].path) > -1
              ) {
                return <Route key={name} path={path} element={element} />;
              }
              return null;
            })}
          </Routes>
        </AuthenticatedTemplate>

        <Footer>
          <p>Copyright &copy; {new Date().getFullYear()} Pinpoint Employee Duress Systems</p>
        </Footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
