import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity } from "@fortawesome/pro-solid-svg-icons";
import Main from "../../components/organisms/main/Main";
import Spinner from "../../components/atoms/spinner/Spinner";
import { strings } from "../../utils/utils";
import { locations, fetchExportData } from "../../api/index";
import {
  clearFilters,
  loadItemsFromAPI,
  deleteRow,
  loadFilterOptionsFromAPI,
  stringOperators,
  booleanOperators,
  renderActionMenuCell,
  renderBooleanCell,
  renderSingleSelectField,
  getExportURL,
  setColumnOrder,
  lookupFieldsMap,
} from "../../api/datagridFunctions";
import {
  datagridOptions,
  defaultSortModels,
  defaultFilterModel,
} from "../../components/organisms/datagrid/datagridDefaults";
import LocationsDataGrid from "../../components/organisms/datagrid/locationsDataGrid";
import HttpError from "../httpError";
import SnackbarAlert from "../../materialUI/snackbar/snackbarAlert";
import GridToolbarCustomFilterPanel from "../../components/organisms/datagrid/gridToolBarCustomFilterPanel";

const { sortModel: defaultSortModel, LOCATIONS } = defaultSortModels;

function List({ errorHandler }) {
  const userObj = useSelector((state) => state.global);
  const { defaultPaginationModel } = datagridOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPaginationModel, setCurrentPaginationModel] = useState(defaultPaginationModel);
  const [previousPaginationModel, setPreviousPaginationModel] = useState(defaultPaginationModel);
  const [currentSortModel, setCurrentSortModel] = useState(defaultSortModel[LOCATIONS]);
  const [resetSortModel, setResetSortModel] = useState(false);
  const [currentFilterModel, setCurrentFilterModel] = useState(defaultFilterModel);
  const previousFilterModel = useState(null);
  const [httpError, setHttpError] = useState(null);
  const [snackbarAlertState, setSnackbarAlertState] = React.useState({
    open: false,
    alertSeverity: "success",
    alertMessage: "",
  });
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const defaultSelectedFilters = () => {
    const defaultFilters = gridColumnDefs.reduce((acc, curr) => {
      let lookupField = lookupFieldsMap.filter((f) => f.field.toLowerCase() === curr.field.toLowerCase());
      if (lookupField.length !== 0) {
        lookupField = lookupField[0].lookupField;
      } else lookupField = curr.field;

      if (curr.type === "date") {
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        acc[lookupField] = {
          dateRange: ["", endDate.toISOString()],
          date: { label: "" },
        };
      } else acc[lookupField] = [];

      return acc;
    }, {});
    return defaultFilters;
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [locationNameFilterOptions, setLocationNameFilterOptions] = useState([]);

  const [datagridState, setDatagridState] = useState({
    columnOrder: [
      { field: "actionMenu", index: 0 },
      { field: "type", index: 1 },
      { field: "name", index: 2 },
      { field: "timeZone", index: 3 },
      { field: "description", index: 4 },
      { field: "comment", index: 5 },
      { field: "inactive", index: 6 },
    ],
    columnWidths: [
      { field: "type", width: 140 },
      { field: "name", width: 200 },
      { field: "timeZone", width: 170 },
      { field: "description", width: 350 },
      { field: "comment", width: 600 },
    ],
    columnVisibility: {
      type: true,
      name: true,
      timeZone: true,
      description: true,
      comment: false,
    },
  });

  const gridColumnDefs = [
    renderActionMenuCell(LOCATIONS, userObj.userRole, setShowDeleteConfirm),
    {
      field: "type",
      type: "string",
      headerName: "Location Type",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "type")[0].width,
      filterOperators: stringOperators,
    },
    renderSingleSelectField(
      "name",
      "Location",
      locationNameFilterOptions,
      datagridState.columnWidths.filter((c) => c.field === "name")[0].width
    ),
    {
      field: "timeZone",
      type: "string",
      headerName: "Time Zone",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "timeZone")[0].width,
      renderCell: (cellValues) => cellValues.row.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      field: "description",
      type: "string",
      headerName: "Description",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      width: datagridState.columnWidths.filter((c) => c.field === "description")[0].width,
      filterable: false,
    },
    {
      field: "comment",
      type: "string",
      headerName: "Comments",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-plaintext-cell",
      filterable: false,
      width: datagridState.columnWidths.filter((c) => c.field === "comment")[0].width,
    },
    {
      field: "inactive",
      type: "boolean",
      headerName: "Inactive",
      headerClassName: "data-grid-column-header",
      cellClassName: "data-grid-cell",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (cellValues) => renderBooleanCell(cellValues.row.inactive),
      filterOperators: booleanOperators,
      resizable: false,
    },
  ];

  const orderedColumnDefs = [];
  datagridState.columnOrder.map((c, i) => {
    const fieldMap = datagridState.columnOrder.find((f) => f.index === i);
    const nextField = gridColumnDefs.find((def) => def?.field === fieldMap?.field);
    if (nextField) orderedColumnDefs.push(nextField);
    return true;
  });

  const refreshTable = () => {
    setIsLoading(true);
    loadItemsFromAPI({
      currentPaginationModel,
      currentFilterModel,
      currentSortModel,
      endpoint: locations,
      errorHandler,
      setItemsToRender,
      setTotalItems,
      setIsLoading,
      setHttpError,
      setResetSortModel,
    });
  };

  useEffect(() => {
    const currSortModel = localStorage.getItem("currentSortModel") || defaultSortModel[LOCATIONS];
    setCurrentSortModel(currSortModel);
    setResetSortModel(true);
    if (locationNameFilterOptions.length === 0)
      loadFilterOptionsFromAPI(locations, setLocationNameFilterOptions, "name");
    setSelectedFilters(defaultSelectedFilters());
  }, []);

  useEffect(() => {
    if (
      currentPaginationModel.page !== previousPaginationModel.page ||
      currentPaginationModel.pageSize !== previousPaginationModel.pageSize
    )
      refreshTable();
  }, [currentPaginationModel]);

  useEffect(() => {
    if (
      !previousFilterModel ||
      (currentFilterModel.items?.length > 0 &&
        currentFilterModel.items[0].field &&
        currentFilterModel.items[0].value) ||
      currentFilterModel.items[0].isDefault
    ) {
      refreshTable();
    }
  }, [currentFilterModel]);

  useEffect(() => {
    if (resetSortModel) {
      refreshTable();
    }
  }, [resetSortModel]);

  if (httpError) return <HttpError httpError={httpError} />;

  return (
    <Main>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dialog
            open={showDeleteConfirm}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <FontAwesomeIcon icon={faCity} style={{ marginRight: "8px", opacity: "0.8", color: "#ff5e14" }} />
              {selectedRow?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {strings.getDeleteConfirmMessage("Location")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  console.log("onClick()", { selectedRow });
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  setIsLoading(true);
                  deleteRow(locations, selectedRow._id, setIsLoading, refreshTable, setSnackbarAlertState);
                  setShowDeleteConfirm(false);
                  setSelectedRow(null);
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          <SnackbarAlert
            snackbarOpen={snackbarAlertState.open}
            alertSeverity={snackbarAlertState.alertSeverity}
            alertMessage={snackbarAlertState.alertMessage}
            onSnackbarClose={() => {
              const newAlertState = { ...snackbarAlertState };
              newAlertState.open = false;
              setSnackbarAlertState(newAlertState);
            }}
            verticalPos="top"
          />
          <LocationsDataGrid
            columnDefs={orderedColumnDefs}
            rows={itemsToRender}
            rowCount={totalItems}
            paginationModel={currentPaginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPreviousPaginationModel(currentPaginationModel);
              setCurrentPaginationModel(newPaginationModel);
            }}
            isFilterMenuOpen={isFilterMenuOpen}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            defaultSelectedFilters={defaultSelectedFilters}
            filterModel={currentFilterModel}
            onSortModelChange={(sortModel) => {
              if (sortModel.length > 0) {
                const newSortModel = { ...currentSortModel };
                if (sortModel[0].field !== currentSortModel.sortColumn) {
                  if (!currentSortModel.sortColumn) {
                    newSortModel.sortColumn = sortModel[0].field;
                    newSortModel.sortDirection = "asc";
                    setCurrentSortModel(newSortModel);
                  } else {
                    newSortModel.sortColumn = sortModel[0].field;
                    setCurrentSortModel(newSortModel);
                  }
                } else {
                  newSortModel.sortColumn = sortModel[0].field;
                  newSortModel.sortDirection = currentSortModel.sortDirection === "asc" ? "desc" : "asc";
                  setCurrentSortModel(newSortModel);
                }
                setResetSortModel(true);
              }
            }}
            currentFilterModel={currentFilterModel}
            handleRefresh={refreshTable}
            handleClearFilters={() =>
              clearFilters({ currentFilterModel, itemsToRender, refreshTable, setCurrentFilterModel, setIsLoading })
            }
            setSelectedRow={(row) => setSelectedRow(row)}
            handleExport={() => {
              fetchExportData(
                getExportURL({ currentFilterModel, currentSortModel, endpoint: LOCATIONS }),
                locations,
                setSnackbarAlertState
              );
            }}
            onColumnResize={(colDef) => {
              const newDatagridState = { ...datagridState };
              const newColumnWidth = newDatagridState.columnWidths.filter((c) => c.field === colDef.field)[0];
              newColumnWidth.width = colDef.width;
              setDatagridState(newDatagridState);
            }}
            columnVisibilityModel={datagridState.columnVisibility}
            onColumnVisibilityChange={(newModel) => {
              const newDatagridState = { ...datagridState };
              delete newDatagridState.columnVisibility;
              newDatagridState.columnVisibility = newModel;
              setDatagridState(newDatagridState);
            }}
            onColumnReorder={(column) => setColumnOrder(column, datagridState, setDatagridState)}
          />
        </>
      )}
      <GridToolbarCustomFilterPanel
        validCols={["Location", "Location Type", "Inactive"]}
        columnDefs={gridColumnDefs}
        isFilterMenuOpen={isFilterMenuOpen}
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        defaultSelectedFilters={defaultSelectedFilters}
        setCurrentFilterModel={setCurrentFilterModel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Main>
  );
}

List.propTypes = {
  errorHandler: PropTypes.func,
};

export default List;
